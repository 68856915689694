import React, { Component } from 'react';
import { buildStyles, CircularProgressbar as CircularProgressbar2 } from 'react-circular-progressbar';
import colors from '../../assets/colors';
import 'react-circular-progressbar/dist/styles.css';

export default class CircularProgressbar extends Component {
    render() {
        let { value } = this.props;
        value = Math.round(value);

        return (
            <CircularProgressbar2 value={value} text={`${value}%`} styles={buildStyles({
                pathColor: colors.principal,
                textColor: colors.principal,
            })} />
        )
    }
}