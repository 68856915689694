import React, { Component } from 'react';
import api from '../../services/api';

import '../../assets/scss/venda.scss';
import Loader from '../../components/loader';
import Venda from '../../components/venda/venda';
import vendaStatusEnum from '../../util/enum/vendaStatusEnum';
import { CSSTransition } from 'react-transition-group';
import produtoGrupoEnum from '../../util/enum/produtoGrupoEnum';

export default class VendaPreparada extends Component {
    state = {
        vendas: [],
        produtos: null,
        nexts: null,
        searching: false,
    }

    loadNextInfo = () => {
        api.get(`/venda?info=next`).then((result) => {
            this.setState({ nexts: result.data });
        });
    }

    loadVendas = async() => {
        try {
            this.setState({ vendas: [], searching: true })

            const vendas = (await api.get(`/venda?status=${vendaStatusEnum.PREPARADA}&itens=all&order_by=2`)).data;
    
            this.loadNextInfo();
            this.setState({ vendas, searching: false })
        } catch (error) {
            console.log(error);
        }
    }

    async componentDidMount() {
        this.loadVendas();

        api.get(`/produto?itens=all&grupo=${produtoGrupoEnum.PRODUTO_FINAL}`).then((result) => {
            const produtos = result.data.map(produto => ({ value: produto.codigo, label: produto.nome }));
            this.setState({ produtos });
        });
    }

    renderVendas = () => {
        const { vendas, produtos, nexts, searching } = this.state;

        if (vendas.length > 0) {
            return vendas.map(venda => {
                const { numero } = venda;
                return (
                    <CSSTransition
                        key={`vendamain-${numero}`}
                        classNames="vendamain-vendas-transition"
                        timeout={500}>
                        <div className="vendamain-venda">
                            <div className="vendamain-venda-container">
                                <Venda
                                    venda={venda}
                                    produtos={produtos}
                                    nexts={nexts}
                                    loadVendas={this.loadVendas} />
                            </div>
                        </div>
                    </CSSTransition>
                )
            });
        }
        if (searching) {
            return (
                <div className="vendamain-loader">
                    <Loader />
                </div>
            )
        }
    }

    render() {
        const { vendas, searching } = this.state;
        const length = vendas.length;

        return (<>
            <div className="vendamain-title">
                {length > 0
                    ? `${length} ${length > 1 ? 'vendas preparadas' : 'venda preparada'}`
                    : !searching && `Nenhuma venda preparada`}
            </div>
            <div className="vendamain-vendas">
                {this.renderVendas()}
            </div>
        </>);
    }
}