import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import api from '../../services/api';

import '../../assets/scss/venda.scss';
import Loader from '../../components/loader';
import IconButton from '../../components/util/IconButton';
import { faCheck, faDolly, faFileInvoiceDollar, faPlus } from '@fortawesome/free-solid-svg-icons';
import vendaStatusEnum from '../../util/enum/vendaStatusEnum';
import VendaItem from '../../components/venda/venda';
import VendaModal from '../../components/venda/venda_modal';
import produtoGrupoEnum from '../../util/enum/produtoGrupoEnum';

export default class Venda extends Component {
    state = {
        vendas: [],
        produtos: null,
        nexts: null,
        preparados: null,
        searching: false,
        adicionar: false,
    }

    loadNextInfo = () => {
        api.get(`/venda?info=next`).then((result) => {
            this.setState({ nexts: result.data });
        });
    }

    loadStatusInfo = () => {
        api.get(`/venda?status=${vendaStatusEnum.PREPARADA}&itens=all&count=true`).then((result) => {
            const { count: preparados } = result.data;
            this.setState({ preparados });
        });
    }

    loadVendas = async(venda = null) => {
        try {
            let nextInfo = true;
            
            if (!venda) {
                this.setState({ vendas: [], searching: true })
                
                const vendas = (await api.get(`/venda?status=${vendaStatusEnum.CONFIRMADA}&itens=all`)).data;
                // console.log(vendas);
                
                this.setState({ vendas, searching: false })
            } else {
                const { vendas } = this.state;
                const excluir = typeof venda !== 'object';

                let alterou = vendas.findIndex(item => {
                    if (item) {
                        const numero = excluir ? venda : venda.numero;
                        return numero == item.numero;
                    }
                });
                if (alterou >= 0) {
                    nextInfo = Boolean(excluir);

                    delete vendas[alterou];
                    setTimeout(() => {
                        if (excluir) {
                            vendas.splice(alterou, 1)
                        } else {
                            vendas[alterou] = venda;
                        }
                        this.setState({ vendas });
                    }, 450)
                } else {
                    vendas.push(venda);
                }
                console.log(venda);
                this.setState({ vendas });
            }
            
            if (nextInfo) {
                this.loadNextInfo();
            }
            this.loadStatusInfo();
        } catch (error) {
            console.log(error);
        }
    }

    async componentDidMount() {
        this.loadVendas();
        this.loadStatusInfo();

        api.get(`/produto?itens=all&grupo=${produtoGrupoEnum.PRODUTO_FINAL}`).then((result) => {
            const produtos = result.data.map(produto => ({ value: produto.codigo, label: produto.nome }));
            this.setState({ produtos });
        });
    }

    renderVendas = () => {
        const { vendas, produtos, nexts, searching } = this.state;

        if (vendas.length > 0) {
            return (
                <TransitionGroup className="vendamain-vendas">
                    {vendas.map((venda, index) => {
                        return (
                            <CSSTransition
                                key={index}
                                classNames="vendamain-vendas-transition"
                                timeout={500}>
                                <div className="vendamain-venda">
                                    <div className="vendamain-venda-container">
                                        <VendaItem
                                            venda={venda}
                                            produtos={produtos}
                                            nexts={nexts}
                                            loadVendas={this.loadVendas} />
                                    </div>
                                </div>
                            </CSSTransition>
                        )
                    })}
                </TransitionGroup>
            );
        }
        if (searching) {
            return (
                <div className="vendamain-loader">
                    <Loader />
                </div>
            )
        }
        return (
            <div className="vendamain-title mt-2">
                Sem vendas pendentes
            </div>
        )
    }

    render() {
        const {
            preparados,
            produtos,
            adicionar,
            nexts,
            searching
        } = this.state;

        let preparadosText = '';
        if (preparados > 0) {
            preparadosText = `${preparados} ${preparados > 1 ? 'vendas preparadas' : 'venda preparada'}`;
        } else {
            preparadosText = 'Nenhuma venda preparada';
        }

        return (<>
            <div className="vendamain-header">
                <IconButton className="vendamain-header-btn" icon={faPlus} text="Adicionar" onClick={() => this.setState({ adicionar: true })} />
                {!searching && <IconButton className="producao-header-btn" icon={faCheck} text={preparadosText} link="/venda/preparados" disabled={preparados < 1} />}
                <IconButton className="producao-header-btn" icon={faCheck} text="Vendas finalizadas" link="/venda/arquivo" />
            </div>
            {this.renderVendas()}
            {adicionar !== false && (
                <VendaModal
                    show={adicionar === true}
                    produtos={produtos}
                    onSuccess={this.loadVendas}
                    onHide={() => {
                        this.setState({ adicionar: null });
                        setTimeout(() => this.setState({ adicionar: false }), 500);
                    }}
                    nexts={nexts} />
            )}
        </>);
    }
}