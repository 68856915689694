import { hot, setConfig } from "react-hot-loader";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import cookie from 'react-cookies';
import Routes from "./Routes";

import favicon from './assets/images/logo.png';
import logoBase64 from './assets/images/logo_base64';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/scss/extra/bootstrap.scss";
import "./assets/scss/app.scss";
import api from "./services/api";

setConfig({
	showReactDomPatchNotification: false,
});

class App extends Component {
	lerNotificacoes = async() => {
        const block = cookie.load('CENTROPLAST_INTRANET_BLOCK') === '1';
		const login = cookie.load('CENTROPLAST_INTRANET_USUARIO');

		if (login) {
			const result = (await api.get(`/notification/${login.id}`)).data;
			
			if (result !== undefined) {
				if (result.block && !block) {
					cookie.save('CENTROPLAST_INTRANET_BLOCK', '1', { path: '/' });
					window.location.reload();
				} else if (Array.isArray(result)) {
					result.forEach(notificacao => {
						const notification = new Notification(notificacao.titulo, { body: notificacao.texto, icon: logoBase64 });
			
						notification.onclick = function(e) {
							e.preventDefault();
							e.stopPropagation();
							
							window.focus();
							notification.close();
						};
					});
					
					if (block) {
						cookie.remove('CENTROPLAST_INTRANET_BLOCK', { path: '/' });
						window.location.reload();
					}
				} else {
					console.log(result);
				}
			} else {
				console.log(result);
			}
		}
	}

	agendarLerNotificacoes = () => {
		this.lerNotificacoes();
		setInterval(this.lerNotificacoes, 30000);
	}

	async componentDidMount() {
		const permission = await Notification.requestPermission();
		if (permission === 'granted') {
			this.agendarLerNotificacoes();
		}
	}

	render() {
		return (
            <div className="app">
				<Helmet>
					<title>Intranet Centroplast</title>
					<link rel="icon" type="imagem/png" href={favicon} />
				</Helmet>
				<Router>
					<Switch>
						<Routes />
					</Switch>
				</Router>
			</div>
		);
	}
}

export default hot(module)(App);