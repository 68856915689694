import React, { Component } from 'react';

import '../../assets/scss/components/pedido.scss';
import { faCheck, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import Modal from 'react-bootstrap/Modal';

export default class CidadeChooseModal extends Component {
    hideSuccess = async cidade => {
        const { onHide, onSuccess } = this.props;
        await onHide();
        await onSuccess(cidade);
    }

    render() {
        const { cidades, show, onHide } = this.props;

        return (<>
            <Modal
                show={show}
                dialogClassName="modal-lg"
                onHide={() => onHide(false)} >
                <Modal.Header>
                    <Modal.Title>Escolher cidade</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md">
                            {cidades && cidades.length > 0 && cidades.map((item, index) => {
                                return (
                                    <div key={index} className="row align-items-center my-2">
                                        <div className="col-md-auto d-flex flex-column align-items-center pl-3">
                                            <IconButton type="transparent" icon={faCheck} onClick={() => this.hideSuccess(item)} />
                                        </div>
                                        <div className="col-md">
                                            {item.nome}
                                        </div>
                                        <div className="col-md-4">
                                            {item.uf}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton text="Voltar" type="dark" icon={faChevronLeft} onClick={() => onHide(false)} />
                </Modal.Footer>
            </Modal>
        </>)
    }
}