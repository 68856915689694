import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import colors from '../../assets/colors';
import IconButton from '../util/IconButton';

const SubtarefaCard = ({ item: { nome: text, done }, index, change }) => {
    return (
        <div className={`tarefas-subtarefas-card${done ? ' done' : ''}`}>
            <Row className="align-items-center">
                <Col>{!done ? `${index + 1}ª)` : ''} {text}</Col>
                {!done && (
                    <Col md="auto" className="d-flex align-items-center">
                        <IconButton icon={faTrash} smallIcon={true} type="danger-transparent" onClick={() => change(index)} />
                    </Col>
                )}
                {done && (
                    <Col md="auto" className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faCheck} color={colors.success} />
                    </Col>
                )}
            </Row>
        </div>
    );
};

export default SubtarefaCard;