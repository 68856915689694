import React, { Component } from 'react';

import '../../assets/scss/components/pedido.scss';
import CustomInput from '../util/CustomInput';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import IconButton from '../util/IconButton';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import pedidoStatusEnum from '../../util/enum/pedidoStatusEnum';

export default class PedidoItem extends Component {
    state = {
        produtoTitle: '',
    }

    renderCustomInput = (params, baseValues = null) => {
        const { onChange, produtos } = this.props;
        params.onChange = (event) => {
            const { target: { name, value } } = event;
            if (name.endsWith('produto')) {
                const produtoTitle = produtos.find(produto => produto.value == value).label;
                this.setState({ produtoTitle });
            }
            onChange(event);
        };

        if (!baseValues) {
            let { pedido } = this.props;
            if (!pedido) {
                ({ pedido } = this.state);
            }
            baseValues = pedido;
        }
        let { id, name, type } = params;
        if (!name && id) {
            name = id;
        }

        if (!type) {
            type = 'number';
            params.type = type;
        }
        if (type === 'number') {
            if (!params.min) {
                params.min = 0;
            }
        }

        const defaultValueName = name.split('-')[2];
        if (params.value === undefined && defaultValueName && baseValues[defaultValueName]) {
            let value = baseValues[defaultValueName];
            if (typeof value === 'object') {
                value = value.codigo || value.id || value;
            }
            
            if (type === 'number') {
                const numeric = (params.step < 1 ? parseFloat : parseInt)(value);
                value = numeric > 0 ? numeric : '';
            }
            params.defaultValue = value;
        }

        return (
            <CustomInput {...params} />
        )
    }

    render() {
        const { item = {}, status, produtos = null, readOnly, onRemove } = this.props;
        const { produtoTitle } = this.state;

        const baseId = `item-${item.id}`;
        const { lotes = [], produto = {} } = item;

        const readOnlyLotes = readOnly || status == pedidoStatusEnum.ENVIADO;

        return (
            <Container key={baseId} className="pedido-item-container mb-3">
                <Row className="mb-3">
                    <Col>
                        <div className="pedido-item-title-bold">
                            {!readOnly && (
                                <IconButton icon={faTimes} type="transparent" onClick={onRemove} />
                            )}
                            <span className="ml-2">Produto {typeof produto === 'object' ? produto.codigo : produto}</span>
                        </div>
                        {readOnly && (<>
                            <div className="pedido-item-title">{produto.nome}</div>
                        </>)}
                        {!readOnly && (
                            <div title={produtoTitle || produto.nome}>
                                {this.renderCustomInput({
                                    name: `${baseId}-produto`,
                                    type: "select",
                                    options: produtos,
                                    item,
                                }, item)}
                            </div>
                        )}
                    </Col>
                </Row>
                <Row className="justify-content-around align-items-end mb-3">
                    <Col md={5}>
                        <div className="pedido-quantidade-title">Quantidade</div>
                        {readOnly && (<>
                            <div className="pedido-quantidade-value">{item.quantidade}</div>
                        </>)}
                        {!readOnly && this.renderCustomInput({
                            className: "text-center",
                            name: `${baseId}-quantidade`,
                            item,
                            readOnly,
                        }, item)}
                    </Col>
                    <Col md={5}>
                        <div className="pedido-quantidade-title">Volumes</div>
                        {readOnly && (<>
                            <div className="pedido-quantidade-value">{item.volumes}</div>
                        </>)}
                        {!readOnly && this.renderCustomInput({
                            className: "text-center",
                            name: `${baseId}-volumes`,
                            item,
                            readOnly,
                        }, item)}
                    </Col>
                </Row>
                <div className="text-center">
                    {lotes.length > 0 && (
                        <Row className="justify-content-around mb-1">
                            <Col md={5}>
                                Quantidade
                            </Col>
                            <Col md={5}>
                                Lote
                            </Col>
                        </Row>
                    )}
                    {lotes.length === 0 && status == pedidoStatusEnum.EMBARQUE && "Sem lotes"}
                    {lotes.map((lote, index) => {
                        return (
                            <Row key={index} className="justify-content-around mb-1">
                                <Col md={5}>
                                    {readOnlyLotes && lote.quantidade}
                                    {!readOnlyLotes && (
                                        <CustomInput
                                            value={lote.quantidade}
                                            type="number" />
                                    )}
                                </Col>
                                <Col md={5}>
                                    {readOnlyLotes && lote.lote}
                                    {!readOnlyLotes && (
                                        <CustomInput
                                            value={lote.lote}
                                            type="number" />
                                    )}
                                </Col>
                            </Row>
                        )
                    })}
                </div>
            </Container>
        )
    }
}