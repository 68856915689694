import React, { Component } from 'react';

import '../../assets/scss/components/of.scss';
import { faCheck, faChevronLeft, faClipboardCheck, faRedoAlt, faSave, faSearch, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import Modal from 'react-bootstrap/Modal';
import CustomInput from '../util/CustomInput';
import Alert from '../util/Alert';
import api from '../../services/api';
import OfItemModal from './of_item_modal';
import OfPesoModal from './of_peso_modal';
import ofStatusEnum from '../../util/enum/ofStatusEnum';
import OfQualidade from './of_qualidade';
import OfBocalModal from './of_bocal_modal';
import CircularProgressbar from '../util/CircularProgressbar';
import Loader from '../loader';
import utilNumber from '../../util/utilNumber';

export default class OfModal extends Component {
    constructor(props) {
        super(props);
        [
            'hideSuccess',
            'encerrar',
            'voltarProducao',
            'baixar',
            'delete',
            'save',
            'saveState',
            'saveStateQualidade',
            'saveStateControlarPesoOptions',
        ].forEach(funcao => {
            this[funcao] = this[funcao].bind(this);
        });
    }

    state = {
        searching: false,
        error: null,
        show: true,
        showItens: false,
        showPeso: false,
        showBocal: false,
        confirmarExcluir: false,
        confirmarVoltar: false,
        confirmarEncerrar: false,
        info: {},
        baixar: 0,
        of: {
            numero: null,
            lote: null,
            produto: null,
            quantidade: null,
            maquina: null,
            controlarPeso: null,
            qualidadeOverride: true,
            controlarPesoOptions: {
                pesoMinimo: null,
                pesoMaximo: null,
                intervalo: null,
                quantidade: null,
            },
            embalagemBocal: null,
            embalagemBocalOptions: {
                intervalo: null,
            },
        },
    }

    static renderCircularProgressbar(quantidade, quantidadeProduzida) {
        let percentage = Math.floor(quantidadeProduzida / quantidade * 100);
        if (percentage > 100) {
            percentage = 100;
        }

        return <CircularProgressbar value={percentage} />;
    }

    static renderPrevisaoFinalizar({ media, quantidade, quantidadeProduzida, rowClasses = '' }) {
        if (media) {
            let previsao = media * (quantidade - quantidadeProduzida);
            let texto = '';
            if (previsao > 60) {
                const horasTotal = previsao / 60;
                const horas = Math.floor(horasTotal);
                previsao = Math.ceil((horasTotal - horas) * 60);
                texto = `${horas} h `;
            }

            if (texto || previsao > 0) {
                return (
                    <div className={`row ${rowClasses}`}>
                        <div className="col-md">
                            <div className="of-quantidade-title">Previsão de finalizar em</div>
                            <div className="of-quantidade-value">{texto}{utilNumber.formatNumber(previsao, 0)} min</div>
                        </div>
                    </div>
                );
            }
        }
        return null;
    }

    static renderModal({ show, text, onHide, onSuccess }) {
        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Body className="of-modal-title">
                    {text}
                </Modal.Body>
                <Modal.Footer className="of-modal-confirm-footer">
                    <IconButton text="Sim" icon={faCheck} onClick={onSuccess} />
                    <IconButton text="Não" icon={faTimes} onClick={onHide} />
                </Modal.Footer>
            </Modal>
        );
    }

    async hideSuccess(of) {
        const { onHide, onSuccess } = this.props;
        await onHide();
        await onSuccess(of);
    }

    async encerrar() {
        let { confirmarEncerrar } = this.state;
        const { of: { numero } } = this.props;
        
        if (confirmarEncerrar) {
            try {
                const result = (await api.put(`/of`, {
                    numero,
                    status: ofStatusEnum.ENCERRADA,
                })).data;
                
                if (result.error === 0) {
                    this.setState({ confirmarEncerrar: false });
                    this.hideSuccess(numero);
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            this.setState({ confirmarEncerrar: true })
        }
    }

    async voltarProducao() {
        let { confirmarVoltar } = this.state;
        const { of: { numero } } = this.props;
        
        if (confirmarVoltar) {
            try {
                const result = (await api.put(`/of`, {
                    numero,
                    status: ofStatusEnum.INICIADA,
                })).data;
                
                if (result.error === 0) {
                    this.setState({ confirmarVoltar: false });
                    this.hideSuccess(this.props.of);
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            this.setState({ confirmarVoltar: true })
        }
    }

    async baixar() {
        const { baixar: quantidade, of } = this.state;
        const { of: { numero, quantidadeProduzida } } = this.props;
        let { quantidadeBaixada } = of;
        if (quantidade) {
            try {
                const result = (await api.put(`/of`, { numero, quantidade, baixar: true, react: true })).data;
    
                switch (result.error) {
                    case 0:
                        if (!quantidadeBaixada) {
                            quantidadeBaixada = 0;
                        }
                        quantidadeBaixada = parseInt(quantidadeBaixada) + parseInt(quantidade);
                        of.quantidadeBaixada = quantidadeBaixada;
        
                        const baixar = quantidadeProduzida - quantidadeBaixada;
                        this.setState({ baixar, of })
                        break;

                    case 6:
                        this.setState({ error: "Você não pode baixar mais do que já foi produzido" })
                        break;
                            
                    default:
                        this.setState({ error: "Aconteceu um erro" })
                        break;
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            this.setState({ error: "Não há nada para baixar" })
        }
    }

    async delete() {
        const { confirmarExcluir } = this.state;
        if (!confirmarExcluir) {
            this.setState({ confirmarExcluir: true });
            return;
        }
        this.setState({ confirmarExcluir: false, show: false });
        
        const { numero } = this.props.of;

        try {
            const result = (await api.delete(`/of/${numero}`)).data;
            console.log(result);

            if (result.error === 0) {
                this.hideSuccess(numero);
            }
        } catch (error) {
            console.log(error);
        }
    }

    async save() {
        const { add = true, nextOf, nextLote } = this.props;
        const { of } = this.state;
        if (!of.numero) {
            of.numero = nextOf;
        }
        if (!of.lote) {
            of.lote = nextLote;
        }

        try {
            of.react = true;
            const result = (await (add ? api.post : api.put)('/of', of)).data;
            const { error } = result;
            console.log(result);

            if (error === 0) {
                this.hideSuccess((await api.get(`/of/${of.numero}`)).data);
            } else {
                if (add) {
                    switch (error) {
                        case 4:
                            this.setState({ error: "Este número já existe em outra OF" })
                            break;

                        case 6:
                            this.setState({ error: "Este lote já existe em outra OF" })
                            break;

                        case 7:
                        case 8:
                        case 9:
                            const text = { 7: 'a quantidade', 8: 'o produto', 9: 'a máquina' }
                            this.setState({ error: `Informe ${text[error]}` })
                            break;

                        default:
                            this.setState({ error: "Aconteceu um erro" })
                            break;
                    }
                } else {
                    switch (error) {
                        default:
                            this.setState({ error: "Aconteceu um erro" })
                            break;
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    saveState(event) {
        const { id, name, value, checked } = event.target;
        const checkbox = !name && id && value == 'on' && checked !== undefined;

        const { of } = this.state;
        of[checkbox ? id : name] = checkbox ? (checked ? '1' : '0') : value;
        this.setState({ of });
    }

    saveStateQualidade(event, tipo) {
        const { id, name, value, checked } = event.target;
        const checkbox = !name && id && value == 'on' && checked !== undefined;

        const { of } = this.state;
        of[`${tipo}Options`][checkbox ? id : name] = checkbox ? (checked ? '1' : '0') : value;
        this.setState({ of });
    }

    saveStateControlarPesoOptions(event) {
        const { id, name, value, checked } = event.target;
        const checkbox = !name && id && value == 'on' && checked !== undefined;

        const { of } = this.state;
        of.controlarPesoOptions[checkbox ? id : name] = checkbox ? (checked ? '1' : '0') : value;
        this.setState({ of });
    }

    componentDidMount() {
        const { of: prop } = this.props;
        if (prop) {
            let {
                numero,
                lote,
                produto: { id: produto },
                maquina: { id: maquina },
                quantidade,
                quantidadeProduzida,
                quantidadeBaixada,
                controlarPeso,
                controlarPesoOptions = {},
                embalagemBocal,
                embalagemBocalOptions = {},
            } = prop;

            if (Array.isArray(controlarPesoOptions)) {
                controlarPesoOptions = {};
            }
            if (Array.isArray(embalagemBocalOptions)) {
                embalagemBocalOptions = {};
            }
            
            const of = {
                numero,
                lote,
                produto,
                maquina,
                quantidade,
                quantidadeBaixada,
                qualidadeOverride: true,
                controlarPeso,
                controlarPesoOptions,
                embalagemBocal,
                embalagemBocalOptions,
            };
            this.setState({
                of,
                baixar: quantidadeProduzida - quantidadeBaixada,
            });
        }
    }

    renderCustomInput(params, baseValues = null) {
        if (!baseValues) {
            ({ of: baseValues = {} } = this.props);
        }
        let { id, name, type } = params;
        if (!name && id) {
            name = id;
        }
        
        if (!type) {
            type = 'number';
            params.type = type;
        }
        if (type === 'number') {
            if (!params.min) {
                params.min = 0;
            }
        }
        if (!params.onChange) {
            params.onChange = this.saveState;
        }

        if (name && baseValues[name]) {
            let value = baseValues[name];
            if (type === 'number') {
                value = (params.step < 1 ? parseFloat : parseInt)(value);
            }
            if (typeof value === 'object') {
                value = value.id || value;
            }
            params.defaultValue = value;
        }

        return (
            <CustomInput {...params} />
        )
    }

    renderQualidadeCustomInput(params, tipo) {
        const { of = {} } = this.state;
        const options = of[`${tipo}Options`];

        let value = options[params.name];
        params.value = value ? parseFloat(value) : '';
        params.onChange = (event) => this.saveStateQualidade(event, tipo);
        return this.renderCustomInput(params, options);
    }

    renderControlarPesoCustomInput(params) {
        const { of = {} } = this.state;
        const { controlarPesoOptions = {} } = of;

        let value = controlarPesoOptions[params.name];
        params.value = value ? parseFloat(value) : '';
        params.onChange = this.saveStateControlarPesoOptions;
        return this.renderCustomInput(params, controlarPesoOptions);
    }

    renderQualidade({ header, tipo, bodyInputs, btnText, btnShow }) {
        const { add, of = {} } = this.props;
        const { status } = of;
        const readOnly = status == ofStatusEnum.ENCERRADA || status == ofStatusEnum.BAIXADA;
        const renderInput = (params) => this.renderQualidadeCustomInput(params, tipo);

        bodyInputs = bodyInputs.map(input => {
            if (input.readOnly === false) {
                delete input.readOnly;
            } else {
                input.readOnly = readOnly;
            }
            return input;
        })
        
        return OfQualidade.render({
            of, add, renderInput, bodyInputs, header, btnText,
            value: this.state.of[tipo],
            btnOnClick: () => this.setState({ [btnShow]: true, show: false }),
            onSelect: value => {
                const { of } = this.state;
                of[tipo] = value;
                this.setState({ of });
            },
        })
    }

    renderControlarPeso() {
        return this.renderQualidade({
            header: 'Controle de peso',
            tipo: 'controlarPeso',
            bodyInputs: [{
                label: "Peso mínimo",
                name: "pesoMinimo",
                step: 0.1,
            }, {
                label: "Peso máximo",
                name: "pesoMaximo",
                step: 0.1,
            }, {
                label: "Intervalo de pesagem (min.)",
                name: "intervalo",
                help: `
                    Se não informar um valor, a verificação só ocorrerá uma vez.<br />
                    Para que a verificação ocorra sempre que seja adicionado um novo item, informe zero
                `,
            }, {
                label: "Quantidade de pesagens",
                name: "quantidade",
            }],
            btnText: 'Visualizar controle de peso',
            btnShow: 'showPeso',
        })
    }

    renderEmbalagemBocal() {
        return this.renderQualidade({
            header: 'Verificação do bocal da embalagem',
            tipo: 'embalagemBocal',
            bodyInputs: [{
                label: "Intervalo de verificação (min.)",
                name: "intervalo",
                help: `
                    Se não informar um valor, a verificação só ocorrerá uma vez.<br />
                    Para que a verificação ocorra sempre que seja adicionado um novo item, informe zero
                `,
            }],
            btnText: 'Visualizar verificações',
            btnShow: 'showBocal',
        })
    }

    renderSelectReadonly({ label, name, value, options, onChange }, condition = false) {
        const { add = true, of = {} } = this.props;
        const { quantidadeProduzida } = of;

        if ((add || quantidadeProduzida < 1) || condition) {
            return this.renderCustomInput({
                label,
                name,
                type: "select",
                options,
                onChange,
            });
        }
        return this.renderCustomInput({
            label,
            name,
            value,
            type: "text",
            readOnly: true,
        });
    }

    render() {
        const {
            add = true,
            of = {},
            maquinas = null,
            produtos = null,
            nextOf,
            nextLote,
            show,
            onSuccess,
            loadOf,
            onHide: onHideProps
        } = this.props;

        const {
            searching,
            confirmarExcluir,
            confirmarVoltar,
            confirmarEncerrar,
            error,
            show: stateShow,
            showItens,
            showPeso,
            showBocal,
            info: { media },
            of: { numero, quantidadeBaixada, qualidadeOverride },
            baixar
        } = this.state;

        const { status, quantidade, quantidadeProduzida, produto = {}, maquina = {} } = of;
        const { nome: produtoNome } = produto;
        const { nome: maquinaNome } = maquina;
        const readOnly = !add && quantidadeProduzida > 0;
        const readOnlyEncerrada = status == ofStatusEnum.ENCERRADA || status == ofStatusEnum.BAIXADA;
        
        const cols = [];
        if (add) {
            cols.push("col-md-6 pr-md-4");
            cols.push("col-md-6 pl-md-4");
        } else {
            cols.push("col-md-4 pr-md-4");
            cols.push("col-md-4 px-md-4");
            cols.push("col-md-4 pl-md-4");
        }

        const onHide = async() => {
            onHideProps();
            if (this.state.of.quantidadeBaixada != (this.props.of || {}).quantidadeBaixada) {
                onSuccess((await api.get(`/of/${numero}`)).data);
            }
        }
        return (<>
            <Modal
                show={show && stateShow && !confirmarExcluir && !confirmarVoltar && !confirmarEncerrar}
                dialogClassName={add ? "modal-lg" : "modal-xl"}
                onShow={async() => {
                    if (!media) {
                        const info = (await api.get(`/of_item?of=${of.numero}&info=only`)).data;
                        if (info.media) {
                            this.setState({ info });
                        }
                    }
                }}
                onHide={onHide} >
                <Modal.Header>
                    <Modal.Title>
                        {add && 'Adicionar OF'}
                        {!add && (
                            <div className="of-modal-maintitle">
                                <span>{`OF ${of.numero}`}</span>
                                <IconButton type="transparent" icon={faRedoAlt} onClick={async() => {
                                    const height = this.bodyRef.clientHeight;
                                    this.setState({ searching: height });
                                    
                                    const newOf = (await api.get(`/of/${of.numero}`)).data;
                                    await loadOf(newOf);
                                    
                                    setTimeout(() => this.setState({ searching: false }), 450);
                                }} />
                            </div>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={searching !== false ? 'of-modal-searching' : ''} style={{ height: searching || 'auto' }} ref={ref => this.bodyRef = ref}>
                    {searching === false && (
                        <div className="row">
                            <div className={cols[0]}>
                                {add && (
                                    <div className="row">
                                        <div className="col-md">
                                            {this.renderCustomInput({
                                                label: "Número",
                                                name: "numero",
                                                defaultValue: nextOf,
                                            })}
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-md">
                                        {this.renderCustomInput({
                                            label: "Lote",
                                            name: "lote",
                                            defaultValue: nextLote,
                                            readOnly
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md">
                                        {this.renderSelectReadonly({
                                            label: "Produto",
                                            name: "produto",
                                            type: "select",
                                            options: produtos,
                                            value: produtoNome,
                                            onChange: async(event) => {
                                                const { value } = event.target;
                                                const result = (await api.get(`/produto/${value}?qualidade=true`)).data;
                                                const { error, qualidade } = result;

                                                if (error === 0 && qualidade !== undefined) {
                                                    const { of } = this.state;
                                                    Object.keys(qualidade).forEach(key => {
                                                        let value = qualidade[key];
                                                        if (key.endsWith('Options') && Array.isArray(value)) {
                                                            value = {};
                                                        }
                                                        of[key] = value;
                                                    })
                                                    this.setState({ of });
                                                }

                                                this.saveState(event);
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md">
                                        {this.renderCustomInput({
                                            label: "Quantidade",
                                            name: "quantidade",
                                            readOnly: readOnlyEncerrada
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md">
                                        {this.renderSelectReadonly({
                                            label: "Máquina",
                                            name: "maquina",
                                            type: "select",
                                            options: maquinas,
                                            value: maquinaNome,
                                        }, !readOnlyEncerrada)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md">
                                        {this.renderCustomInput({
                                            label: "Observações",
                                            name: "observacoes",
                                            type: "textarea",
                                            readOnly: readOnlyEncerrada,
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className={cols[1]}>
                                {this.renderControlarPeso()}
                                {this.renderEmbalagemBocal()}
                                <div className="row mb-2">
                                    <div className="col-md">
                                        {this.renderCustomInput({
                                            formGroup: false,
                                            label: "Alterar o padrão deste produto?",
                                            id: "qualidadeOverride",
                                            type: "checkbox",
                                            value: qualidadeOverride,
                                        })}
                                    </div>
                                </div>
                            </div>
                            {!add && (
                                <div className={cols[2]}>
                                    <div className="row align-items-center justify-content-center mt-2 mb-4">
                                        <div className="col-md-5">
                                            <div className="of-quantidade-title">Já produzido</div>
                                            <div className="of-quantidade-value">{parseInt(quantidadeProduzida || 0)}</div>
                                        </div>
                                        <div className="col-md-5">
                                            {OfModal.renderCircularProgressbar(quantidade, quantidadeProduzida)}
                                        </div>
                                    </div>
                                    {OfModal.renderPrevisaoFinalizar({ media, quantidade, quantidadeProduzida, rowClasses: 'align-items-center justify-content-center mb-4' })}
                                    {quantidadeProduzida > 0 && (<>
                                        <div className="row mt-2">
                                            <div className="col-md">
                                                <IconButton className="w-100" text="Visualizar registros de produção" icon={faSearch} onClick={() => this.setState({ showItens: true, show: false })} />
                                            </div>
                                        </div>
                                        <hr className="mt-4" />
                                        <div className="row align-items-center justify-content-center mt-3 mb-4">
                                            <div className="col-md-5">
                                                <div className="of-quantidade-title">Já baixado</div>
                                                <div className="of-quantidade-value">{parseInt(quantidadeBaixada || 0)}</div>
                                            </div>
                                            <div className="col-md-5">
                                                <CustomInput
                                                    formGroup={false}
                                                    value={baixar}
                                                    label="Baixar"
                                                    type="number"
                                                    min={0}
                                                    max={quantidadeProduzida - parseInt(quantidadeBaixada || 0)}
                                                    onChange={({ target: { value: baixar } }) => this.setState({ baixar })} />
                                            </div>
                                        </div>
                                        {quantidadeProduzida - quantidadeBaixada > 0 && (
                                            <div className="row mt-2">
                                                <div className="col-md">
                                                    <IconButton className="w-100" text="Baixar" icon={faClipboardCheck} onClick={this.baixar} />
                                                </div>
                                            </div>
                                        )}
                                    </>)}
                                </div>
                            )}
                        </div>
                    )}
                    {searching !== false && <Loader />}
                </Modal.Body>
                <Modal.Footer>
                    {status == ofStatusEnum.ENCERRADA && <IconButton text="Voltar à produção" icon={faChevronLeft} onClick={this.voltarProducao} />}
                    {status != ofStatusEnum.ENCERRADA && status != ofStatusEnum.BAIXADA && <IconButton text="Salvar" icon={faSave} onClick={this.save} />}
                    {status == ofStatusEnum.INICIADA && <IconButton type="danger" text="Encerrar" icon={faTimes} onClick={this.encerrar} />}
                    {!add && <IconButton type="danger" text="Excluir" icon={faTrash} onClick={this.delete} />}
                    <IconButton text="Fechar" type="dark" icon={faTimes} onClick={onHide} />
                </Modal.Footer>
            </Modal>

            <OfItemModal of={of} show={showItens} onHide={() => this.setState({ show: true, showItens: false })} />
            <OfPesoModal of={of} show={showPeso} onHide={() => this.setState({ show: true, showPeso: false })} />
            <OfBocalModal of={of} show={showBocal} onHide={() => this.setState({ show: true, showBocal: false })} />

            {OfModal.renderModal({
                text: `Deseja realmente excluir a OF ${of.numero || numero}?`,
                show: confirmarExcluir !== false,
                onSuccess: this.delete,
                onHide: () => this.setState({ confirmarExcluir: false }),
            })}

            {OfModal.renderModal({
                text: `Deseja realmente encerrar a OF ${of.numero || numero}?`,
                show: confirmarEncerrar !== false,
                onSuccess: this.encerrar,
                onHide: () => this.setState({ confirmarEncerrar: false }),
            })}

            {OfModal.renderModal({
                text: `Deseja realmente voltar a OF ${of.numero || numero} à produção?`,
                show: confirmarVoltar !== false,
                onSuccess: this.voltarProducao,
                onHide: () => this.setState({ confirmarVoltar: false }),
            })}

            {error && (
                <Alert message={error} onDismiss={() => this.setState({ error: null })} />
            )}
        </>)
    }
}