import React, { Component } from 'react';
import moment from 'moment';
import tarefaRepetirEnum from '../../util/enum/tarefaRepetirEnum';
import IconButton from '../util/IconButton';
import { faChevronDown, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Accordion, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TarefaModal from './tarefa_modal';

export default class Tarefa extends Component {
    state = {
        visualizar: false,
    }

    render() {
        const isManha = date => parseInt(moment(date).format('H')) < 13;
        const { visualizar } = this.state;
        const { onSuccess, item, open, setOpenSubtarefa, tarefas, usuarios } = this.props;
        const { dtPrevisao, dtConclusao, atrasado, repetir, tarefa: { nome: tarefa }, subtarefas } = item;

        let expect = null;
        if (dtConclusao) {
            expect = `Concluído${atrasado ? ' atrasado' : ''} em ${moment(dtConclusao).format("L")}`;
        } else {
            if (atrasado) {
                expect = `Atrasado`
            } else {
                expect = `Previsto para ${isManha(dtPrevisao) ? 'manhã' : 'tarde'}`;
            }
        }
        return (
            <div className="tarefas-tarefa">
                <Row style={{ marginBottom: "0.25rem" }}>
                    <Col className="tarefas-tarefa-repetir">{tarefaRepetirEnum.getTextByValue(repetir)}</Col>
                    <Col md="auto" className={`tarefas-tarefa-expect text-right text-${atrasado ? 'danger' : 'success'}`}>{expect}</Col>
                </Row>
                <div className="tarefas-tarefa-title">{tarefa}</div>
                <Accordion activeKey={open ? '1' : '0'} onSelect={expand => setOpenSubtarefa(expand === '1')}>
                    <Card>
                        <Accordion.Toggle className="tarefas-subtarefas-header" as={Card.Header} eventKey="1">
                            <Row>
                                <Col>
                                    <FontAwesomeIcon style={{ marginRight: "0.25rem" }} icon={faChevronDown} />
                                    Atividades
                                </Col>
                            </Row>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <ol className="tarefas-subtarefas">
                                    {subtarefas.filter(({ dtConclusao }) => !Boolean(dtConclusao)).map(({ tarefa: { nome: subtarefa }, dtConclusao }, index) => (
                                        <li key={index.toString()} className={dtConclusao ? 'tarefas-subtarefas-done' : ''}>
                                            {subtarefa}
                                        </li>
                                    ))}
                                </ol>
                                <ol className="tarefas-subtarefas-done">
                                    {subtarefas.filter(({ dtConclusao }) => Boolean(dtConclusao)).map(({ tarefa: { nome: subtarefa }, dtConclusao }, index) => (
                                        <li key={index.toString()} className={dtConclusao ? 'tarefas-subtarefas-doneitem' : ''}>
                                            {subtarefa}
                                        </li>
                                    ))}
                                </ol>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Row className="mt-3">
                    <Col>
                        <IconButton className="w-100" icon={faSearch} text="Visualizar" onClick={() => this.setState({ visualizar: true })} />
                    </Col>
                </Row>
                {visualizar !== false && (
                    <TarefaModal
                        add={false}
                        show={visualizar === true}
                        item={item}
                        tarefas={tarefas}
                        usuarios={usuarios}
                        onSuccess={onSuccess}
                        onHide={() => {
                            this.setState({ visualizar: null });
                            setTimeout(() => this.setState({ visualizar: false }), 500);
                        }} />
                )}
            </div>
        )
    }
}