import React, { Component } from 'react';

import '../../assets/scss/components/of.scss';
import { faCheck, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import Modal from 'react-bootstrap/Modal';
import CustomInput from '../util/CustomInput';
import Alert from '../util/Alert';
import api from '../../services/api';
import produtoUnidadeEnum from '../../util/enum/produtoUnidadeEnum';
import produtoGrupoEnum from '../../util/enum/produtoGrupoEnum';
import util from '../../util/util';

export default class ProdutoModal extends Component {
    constructor(props) {
        super(props);
        util.bindFunctions(this, [
            'hideSuccess',
            'delete',
            'reativar',
            'save',
            'saveState',
            'renderCustomInput',
            'renderSelectReadonly',
        ])
    }

    state = {
        error: null,
        show: true,
        confirmarExcluir: false,
        produto: {},
    }

    static renderModal({ show, text, onHide, onSuccess }) {
        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Body className="produto-modal-title">
                    {text}
                </Modal.Body>
                <Modal.Footer className="produto-modal-confirm-footer">
                    <IconButton text="Sim" icon={faCheck} onClick={onSuccess} />
                    <IconButton text="Não" icon={faTimes} onClick={onHide} />
                </Modal.Footer>
            </Modal>
        );
    }

    async hideSuccess() {
        const { onHide, onSuccess } = this.props;
        await onHide();
        await onSuccess();
    }

    async delete() {
        const { confirmarExcluir } = this.state;
        if (!confirmarExcluir) {
            this.setState({ confirmarExcluir: true });
            return;
        }
        this.setState({ confirmarExcluir: false, show: false });
        
        const { codigo } = this.props.produto;

        try {
            const result = (await api.delete(`/produto/${codigo}`)).data;
            console.log(result);

            if (result.error === 0) {
                this.hideSuccess();
            } else {
                this.setState({ error: "Aconteceu um erro" })
            }
        } catch (error) {
            console.log(error);
        }
    }

    async reativar() {
        const { produto } = this.state;
        produto.ativo = 1;
        produto.react = true;

        try {
            const result = (await api.put('/produto', produto)).data;

            if (result.error === 0) {
                this.hideSuccess();
            } else {
                switch (result.error) {
                    default:
                        this.setState({ error: "Aconteceu um erro" })
                        break;
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    async save() {
        const { add = true, nextCodigo } = this.props;
        const { produto: { codigoTipo, ...produto } } = this.state;
        if (!produto.codigo) {
            produto.codigo = nextCodigo;
        }
        if (!isNaN(parseInt(produto.codigo))) {
            produto.codigo = `${codigoTipo}${produto.codigo.toString().padStart(5, '0')}`;
        }

        try {
            produto.react = true;
            const result = (await (add ? api.post : api.put)('/produto', produto)).data;
            console.log(result);

            if (result.error === 0) {
                this.hideSuccess();
            } else {
                if (add) {
                    switch (result.error) {
                        default:
                            this.setState({ error: "Aconteceu um erro" })
                            break;
                    }
                } else {
                    switch (result.error) {
                        default:
                            this.setState({ error: "Aconteceu um erro" })
                            break;
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    saveState(event) {
        const { id, name, value, checked } = event.target;
        const checkbox = !name && id && value == 'on' && checked !== undefined;

        const { produto } = this.state;
        produto[checkbox ? id : name] = checkbox ? (checked ? '1' : '0') : value;
        this.setState({ produto });
    }

    componentDidMount() {
        let produto = {};
        const { produto: prop, nextCodigo } = this.props;

        if (prop) {
            let {
                codigo,
                nome,
                unidade,
                grupo,
            } = prop;
            
            produto = {
                codigo,
                nome,
                unidade,
                grupo,
            };
        }
            
        if (!produto.codigo && nextCodigo) {
            produto.codigoTipo = 'CP';
            produto.codigo = nextCodigo[produto.codigoTipo];
        }
        this.setState({ produto });
    }

    renderCustomInput(params, baseValues = null) {
        if (!baseValues) {
            ({ produto: baseValues = {} } = this.props);
        }
        let { id, name, type } = params;
        if (!name && id) {
            name = id;
        }
        
        if (type === 'number') {
            if (!params.min) {
                params.min = 0;
            }
        }
        if (!params.onChange) {
            params.onChange = this.saveState;
        }

        if (name && baseValues[name] && params.value === undefined) {
            let value = baseValues[name];
            if (type === 'number') {
                value = (params.step < 1 ? parseFloat : parseInt)(value);
            }
            if (typeof value === 'object') {
                value = value.id || value;
            }
            params.defaultValue = value;
        }

        return (
            <CustomInput {...params} />
        )
    }

    renderSelectReadonly({ label, name, value, options, onChange }, condition = false) {
        const { add = true } = this.props;

        if (add || condition) {
            return this.renderCustomInput({
                label,
                name,
                type: "select",
                options,
                onChange,
            });
        }
        return this.renderCustomInput({
            label,
            name,
            value,
            type: "text",
            readOnly: true,
        });
    }

    render() {
        const {
            add = true,
            produto = {},
            nextCodigo,
            show,
            onHide,
        } = this.props;

        const {
            confirmarExcluir,
            error,
            show: stateShow,
            produto: { codigo }
        } = this.state;

        return (<>
            <Modal
                show={show && stateShow && !confirmarExcluir}
                dialogClassName="modal-md"
                onHide={onHide}
            >
                <Modal.Header>
                    <Modal.Title>{add ? "Adicionar produto" : `Produto ${produto.codigo}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {add && (
                        <div className="row align-items-end">
                            <div className="col-md-3 pr-0">
                                {this.renderCustomInput({
                                    label: "Código",
                                    onChange: (e) => {
                                        const { value } = e.target;
                                        const { produto } = this.state;
                                        produto.codigoTipo = value;
                                        produto.codigo = nextCodigo[value];
                                        this.setState({ produto });
                                    },
                                    type: "select",
                                    selectStyle: {
                                        control: {
                                            borderBottomRightRadius: "0",
                                        }
                                    },
                                    options: [{
                                        label: "CP", value: "CP",
                                    }, {
                                        label: "MP", value: "MP",
                                    }, {
                                        label: "IN", value: "IN",
                                    }],
                                    defaultValue: "CP",
                                })}
                            </div>
                            <div className="col-md pl-0">
                                {this.renderCustomInput({
                                    name: "codigo",
                                    type: 'number',
                                    className: "produto-codigo-numero",
                                    value: codigo || "",
                                })}
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-md">
                            {this.renderCustomInput({
                                label: "Nome",
                                name: "nome",
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            {this.renderCustomInput({
                                label: "Unidade",
                                name: "unidade",
                                type: "select",
                                options: produtoUnidadeEnum.getSelectOptions(),
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            {this.renderCustomInput({
                                label: "Grupo",
                                name: "grupo",
                                type: "select",
                                options: produtoGrupoEnum.getSelectOptions(),
                            })}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton text="Salvar" icon={faSave} onClick={this.save} />
                    {Boolean(!add && !produto.ativo) && <IconButton type="success" text="Reativar" icon={faCheck} onClick={this.reativar} />}
                    {Boolean(!add && produto.ativo) && <IconButton type="danger" text="Excluir" icon={faTrash} onClick={this.delete} />}
                    <IconButton text="Fechar" type="dark" icon={faTimes} onClick={onHide} />
                </Modal.Footer>
            </Modal>

            {ProdutoModal.renderModal({
                text: `Deseja realmente excluir o produto ${produto.codigo}?`,
                show: confirmarExcluir !== false,
                onSuccess: this.delete,
                onHide: () => this.setState({ confirmarExcluir: false }),
            })}

            {error && (
                <Alert message={error} onDismiss={() => this.setState({ error: null })} />
            )}
        </>)
    }
}