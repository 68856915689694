import 'intl';
import 'intl/locale-data/jsonp/pt-BR';

export default {
    formatNumber(number, decimalPlaces) {
        if (typeof decimalPlaces !== 'object') {
            decimalPlaces = { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces };
        }
        return new Intl.NumberFormat('pt-br', decimalPlaces).format(number);
    }
};