import React, { Component } from 'react';
import utilNumber from '../../util/utilNumber';

import '../../assets/scss/components/of.scss';
import ofStatusEnum from '../../util/enum/ofStatusEnum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClipboard, faClipboardCheck, faSave, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import IconButton from '../util/IconButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import api from '../../services/api';
import OfModal from './of_modal';

export default class Of extends Component {
    state = {
        visualizar: false,
        copied: false,
        confirmBaixar: false,
        quantidadeFlip: true,
    };

    baixarOf = async numero => {
        let { confirmBaixar } = this.state;
        confirmBaixar = confirmBaixar !== numero ? numero : false
        this.setState({ confirmBaixar });

        if (!confirmBaixar) {
            try {
                const result = (await api.put(`/of`, { numero, react: true, baixar: true })).data;
                console.log(result);
                
                if (result.error === 0) {
                    this.props.loadOf();
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    copy = content => {
        navigator.clipboard.writeText(content).then(() => {
            this.setState({ copied: true })
            ReactTooltip.rebuild();
            
            setTimeout(() => {
                this.setState({ copied: false })
                ReactTooltip.rebuild();
            }, 2000)
        })
    }

    flipQuantidades = () => {
        const { quantidadeFlip } = this.state;

        setTimeout(() => {
            const { quantidadeFlip } = this.state;
            this.setState({ quantidadeFlip: quantidadeFlip ? '1' : '2' });
    
            setTimeout(() => {
                this.setState({ quantidadeFlip: !quantidadeFlip });
                this.flipQuantidades();
            }, 400)
        }, quantidadeFlip ? 5400 : 2400);
    }

    componentDidMount() {
        const { quantidadesFlip = false } = this.props;
        if (quantidadesFlip) {
            this.flipQuantidades();
        }
    }

    renderText = (copy = true) => {
        const { of } = this.props;
        const { copied } = this.state;

        let { lote } = of;
        const {
            numero,
            produto: {
                codigo: produtoCodigo,
                nome: produtoNome
            },
        } = of;

        if (lote !== undefined) {
            lote = lote.toString();
            lote = `${'0'.repeat(6 - lote.length)}${lote}`;
        }

        return (<>
            {copy && <ReactTooltip className="react-tooltip" effect="solid" />}
            <div className="of-title">OF {numero}</div>
            <div className="of-title">
                <span>Lote {lote}</span>
                {copy && (
                    <button
                        key={copied ? 'lote-copied' : 'lote-copy'}
                        data-tip={copied ? 'Copiado' : 'Copiar'}
                        className="of-copy"
                        onClick={() => this.copy(lote)} >
                        <FontAwesomeIcon icon={faClipboard} />
                    </button>
                )}
            </div>
            <div className="of-text">
                <span>Produto {produtoCodigo}</span>
                {copy && (
                    <button
                        key={copied ? 'lote-copied' : 'lote-copy'}
                        data-tip={copied ? 'Copiado' : 'Copiar'}
                        className="of-copy"
                        onClick={() => this.copy(produtoCodigo)} >
                        <FontAwesomeIcon icon={faClipboard} />
                    </button>
                )}
            </div>
            <div className="of-text">{produtoNome}</div>
        </>);
    }

    renderQuantidades = (quantidades) => {
        let content = null;
        if (quantidades && quantidades.length > 0) {
            content = quantidades.map((quantidade, item) => (
                <div className="of-quantidade-col" key={item}>
                    <div className="of-quantidade-title">{quantidade.title}</div>
                    <div className="of-quantidade-value">{utilNumber.formatNumber(quantidade.value, 0)}</div>
                </div>
            ));
        }

        return content ? <div className="of-quantidade-row">{content}</div> : null;
    }

    renderBottom = (of) => {
        const { maquinas, produtos, loadOf } = this.props;
        const { visualizar, confirmBaixar } = this.state;

        return (<>
            <IconButton text="Visualizar" icon={faSearch} className="of-btn" onClick={() => this.setState({ visualizar: true })} />
            {visualizar !== false && (
                <OfModal
                    add={false}
                    show={visualizar === true}
                    of={of}
                    maquinas={maquinas}
                    produtos={produtos}
                    loadOf={loadOf}
                    onSuccess={loadOf}
                    onHide={() => {
                        this.setState({ visualizar: null });
                        setTimeout(() => this.setState({ visualizar: false }), 500);
                    }} />
            )}
            {OfModal.renderModal({
                text: `Deseja realmente baixar completamente a OF ${confirmBaixar}?`,
                show: confirmBaixar !== false,
                onSuccess: () => this.baixarOf(confirmBaixar),
                onHide: () => this.setState({ confirmBaixar: false }),
            })}
        </>);
    }

    renderEncerrada = () => {
        const { of } = this.props;
        const { numero, quantidadeProduzida } = of;

        return (<>
            {this.renderText()}
            {this.renderQuantidades([{
                title: "Produzido",
                value: quantidadeProduzida,
            }])}
            {this.renderBottom(of)}
            <IconButton
                text="Baixar"
                icon={faClipboardCheck}
                onClick={() => this.baixarOf(numero)}
                className="producao-of-btn" />
        </>)
    }

    render() {
        const { of, quantidadesFlip = false } = this.props;
        const { quantidade, quantidadeProduzida, status } = of;
        const { quantidadeFlip } = this.state;
        
        let firstCols = [{
            title: "Quantidade",
            value: quantidade,
        }];
        let secondCols = [];
        
        switch (parseInt(status)) {
            case ofStatusEnum.ENCERRADA:
                return this.renderEncerrada();
            case ofStatusEnum.BAIXADA:
                firstCols = [];
                firstCols.push({
                    title: "Produzido",
                    value: quantidadeProduzida,
                });
                break;
            case ofStatusEnum.PREPARANDO:
                break;
                default:
                firstCols.push({
                    title: "Já produzido",
                    value: quantidadeProduzida,
                });

                const diff = quantidade - quantidadeProduzida;
                secondCols.push({
                    title: diff >= 0 ? 'Falta' : 'A mais',
                    value: Math.abs(diff),
                });
        }

        let quantidadeFlipClass = null;
        let progressFlipClass = null;

        switch (quantidadeFlip) {
            case true:
                quantidadeFlipClass = 'true';
                progressFlipClass = 'false';
                break;

            case '1':
                quantidadeFlipClass = 'flip';
                progressFlipClass = 'false';
                break;

            case '2':
                quantidadeFlipClass = 'false';
                progressFlipClass = 'flip';
                break;

            case false:
                quantidadeFlipClass = 'false';
                progressFlipClass = 'true';
                break;
        }

        const quantidades = (<>
            {this.renderQuantidades(firstCols)}
            {this.renderQuantidades(secondCols)}
        </>);

        return (
            <div>
                {this.renderText(false)}
                {quantidadesFlip && (
                    <div className="of-flip-container">
                        <Row className={`of-flip of-flip-${quantidadeFlipClass}`}>
                            <Col>
                                {quantidades}
                            </Col>
                        </Row>
                        <Row className={`justify-content-center of-flip of-flip-${progressFlipClass}`}>
                            <Col md={6}>
                                {OfModal.renderCircularProgressbar(quantidade, quantidadeProduzida)}
                            </Col>
                        </Row>
                    </div>
                )}
                {!quantidadesFlip && quantidades}
                {this.renderBottom(of)}
            </div>
        )
    }
}