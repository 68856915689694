const values = {
    PESO: 1,
    BOCAL: 2,
};

const properties = {
    1: {name: 'PESO', value: 1, text: 'Controlar peso'},
    2: {name: 'BOCAL', value: 2, text: 'Verificar bocal da embalagem'},
};

export default {
    ...values,
    properties,
};