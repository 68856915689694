import React, { Component } from 'react';
import api from '../../services/api';

import '../../assets/scss/producao.scss';
import Loader from '../../components/loader';
import Of from '../../components/of/of';
import ofStatusEnum from '../../util/enum/ofStatusEnum';

export default class ProducaoBaixar extends Component {
    state = {
        ofs: [],
        searching: false,
    }

    loadOf = async() => {
        try {
            this.setState({ ofs: [], searching: true })

            const ofs = (await api.get(`/of?status=${ofStatusEnum.ENCERRADA}&itens=all&order_by=3`)).data;
    
            this.setState({ ofs, searching: false })
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {
        this.loadOf();
    }

    renderOfs = () => {
        const { ofs, searching } = this.state;
        if (ofs.length > 0) {
            return ofs.map(of => {
                const { numero, maquina: { nome: maquinaNome } } = of;
                return (
                    <div key={`of-${numero}`} className="producao-of">
                        <div className="producao-of-container">
                            <div className="producao-of-maquina">{maquinaNome}</div>
                            <hr className="producao-of-hr" />
                            <Of of={of} loadOf={this.loadOf} />
                        </div>
                    </div>
                )
            });
        }
        if (searching) {
            return (
                <div className="producao-loader">
                    <Loader />
                </div>
            )
        }
    }

    render() {
        const { ofs, searching, confirmVoltar, confirmBaixar } = this.state;
        const length = ofs.length;

        return (<>
            <div className="producao-baixar-title">
                {length > 0
                    ? `${length} ${length > 1 ? 'ordens' : 'ordem'} para baixar`
                    : !searching && `Sem OFs para baixar`}
            </div>
            <div className="producao-ofs">
                {this.renderOfs()}
            </div>
        </>);
    }
}