import React, { Component } from 'react';

import '../../assets/scss/components/of.scss';
import colors from '../../assets/colors';
import IconButton from '../util/IconButton';
import Modal from 'react-bootstrap/Modal';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';
import Loader from '../loader';
import utilNumber from '../../util/utilNumber';
import { ResponsiveBar } from '@nivo/bar';
import OfModal from './of_modal';
import ofQualidadeTipoEnum from '../../util/enum/ofQualidadeTipoEnum';
import { ResponsiveLine } from '@nivo/line';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class OfPesoModal extends Component {
    state = {
        itens: [],
        info: {},
        searching: true,
    }

    loadItens = async() => {
        const { of } = this.props;
        
        const result = (await api.get(`/of_item_qualidade?of=${of.numero}&tipo=${ofQualidadeTipoEnum.PESO}&info=true`)).data;
        
        const { itens, info } = result;
        this.setState({ itens, info, searching: false });
    }
    
    renderItens = () => {
        const { itens, info, searching } = this.state;
        if (!searching) {
            if (itens.length > 0) {
                let { of: { controlarPesoOptions: { pesoMinimo, pesoMaximo } } } = this.props;
                let { media, menor, maior } = info;

                pesoMinimo = pesoMinimo ? parseFloat(pesoMinimo) : null;
                pesoMaximo = pesoMaximo ? parseFloat(pesoMaximo) : null;
                media = media ? parseFloat(media) : null;
                menor = menor ? parseFloat(menor) : null;
                maior = maior ? parseFloat(maior) : null;

                const diferenca = pesoMaximo ? pesoMaximo - pesoMinimo : pesoMinimo;
                const yellowMenor = pesoMinimo ? (pesoMinimo + (diferenca / 4)) : null;
                const yellowMaior = pesoMaximo ? (pesoMaximo - (diferenca / 4)) : null;

                const redMenor = pesoMinimo;
                const redMaior = pesoMaximo;

                const chartData = [];

                const doubleItens = {};
                const itensRender = itens.map((item, index) => {
                    const { data, usuario, valor } = item;
                    let color = '';
                    if ((redMenor && valor < redMenor) || (redMaior && valor > redMaior)) {
                        color = 'red';
                    } else if ((yellowMenor && valor < yellowMenor) || (yellowMaior && valor > yellowMaior)) {
                        color = 'yellow';
                    }
                    color = color ? `-${color}` : '';
                    
                    const valorFormat = utilNumber.formatNumber(valor, 1);
                    
                    chartData.push({ x: index, y: valor });
                    
                    let exists = true;
                    if (!doubleItens[item.itemId]) {
                        exists = false;
                        doubleItens[item.itemId] = 0;
                    }
                    
                    return (
                        <div key={index} className={index > 0 && !exists ? 'mt-2' : ''}>
                            {!exists && (
                                <Row>
                                    <Col>{new Date(data).toLocaleString()} ({usuario}):</Col>
                                </Row>
                            )}
                            <Row className="justify-content-end">
                                <Col md={11}>
                                    <span>Embalagem {++doubleItens[item.itemId]}:</span>
                                    <span className={`of-itens-tempo-minutos${color}`}>{valorFormat}g</span>
                                </Col>
                            </Row>
                        </div>
                    )
                });

                const markers = [{
                    axis: 'y',
                    value: media,
                    lineStyle: { stroke: colors.principal, strokeWidth: 1 },
                    legend: 'Média',
                }];
                if (pesoMinimo) {
                    markers.push({
                        axis: 'y',
                        value: pesoMinimo,
                        lineStyle: { stroke: colors.danger, strokeWidth: 1 },
                    });
                }
                if (pesoMaximo) {
                    markers.push({
                        axis: 'y',
                        value: pesoMaximo,
                        lineStyle: { stroke: colors.danger, strokeWidth: 1 },
                    });
                }


                return (
                    <div className="row">
                        <div className="col-md">
                            {itensRender}
                        </div>
                        <div className="col-md">
                            <Row className="justify-content-end">
                                <Col md={4}>
                                    <div className="of-quantidade-title">Mínimo permitido</div>
                                    <div className="of-quantidade-value">{pesoMinimo ? `${utilNumber.formatNumber(pesoMinimo, 1)}g` : '-'}</div>
                                </Col>
                                <Col md={4}>
                                    <div className="of-quantidade-title">Máximo permitido</div>
                                    <div className="of-quantidade-value">{pesoMaximo ? `${utilNumber.formatNumber(pesoMaximo, 1)}g` : '-'}</div>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md={4}>
                                    <div className="of-quantidade-title">Média</div>
                                    <div className="of-quantidade-value">{media ? `${utilNumber.formatNumber(media, 1)}g` : '-'}</div>
                                </Col>
                                <Col md={4}>
                                    <div className="of-quantidade-title">Mínimo informado</div>
                                    <div className="of-quantidade-value">{menor ? `${utilNumber.formatNumber(menor, 1)}g` : '-'}</div>
                                </Col>
                                <Col md={4}>
                                    <div className="of-quantidade-title">Máximo informado</div>
                                    <div className="of-quantidade-value">{maior ? `${utilNumber.formatNumber(maior, 1)}g` : '-'}</div>
                                </Col>
                            </Row>
                            <div className="row">
                                <div className="col-md of-itens-chart">
                                    <ResponsiveLine
                                        data={[{
                                            id: 'peso',
                                            color: colors.principal,
                                            data: chartData,
                                        }]}
                                        markers={markers}
                                        colors={colors.principal}
                                        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                                        xScale={{ type: 'point' }}
                                        yScale={{
                                            type: 'linear',
                                            min: (menor < pesoMinimo ? menor : pesoMinimo) - 1,
                                            max: (maior > pesoMaximo ? maior : pesoMaximo) + 1,
                                            reverse: false,
                                        }}
                                        yFormat=" >-.2f"
                                        curve="natural"
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={null}
                                        axisLeft={{
                                            orient: 'left',
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Peso',
                                            legendOffset: -40,
                                            legendPosition: 'middle'
                                        }}
                                        pointSize={10}
                                        pointColor={{ theme: 'background' }}
                                        pointBorderWidth={2}
                                        pointBorderColor={{ from: 'serieColor' }}
                                        pointLabelYOffset={-12}
                                        enableGridX={false}
                                        crosshairType="y"
                                        useMesh={true}
                                        tooltip={({ point: { data: { y } } }) => (
                                            <div className="of-itens-chart-tooltip">
                                                {y}g
                                            </div>
                                        )}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            return <div>Nenhum item</div>
        }
        return (
            <div className="of-itens-loader">
                <Loader />
            </div>
        )
    }

    render() {
        const { of, show, onHide } = this.props;

        return (<>
            <Modal
                show={show}
                dialogClassName="modal-xl"
                onShow={this.loadItens}
                onHide={onHide} >
                <Modal.Header>
                    <Modal.Title>{`Controle de peso da OF ${of.numero}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderItens()}
                </Modal.Body>
                <Modal.Footer>
                    <IconButton text="Voltar" type="dark" icon={faArrowLeft} onClick={onHide} />
                </Modal.Footer>
            </Modal>
        </>)
    }
}