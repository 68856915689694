import utilEnum from './enum';

const values = {
    DIARIO: 1,
    SEMANAL: 2,
    QUINZENAL: 3,
    MENSAL: 4,
    TRIMESTRAL: 5,
    SEMESTRAL: 6,
    ANUAL: 7,
    PARES: 8,
    IMPARES: 9,
    TURNO: 10,
};

const properties = {
    1: {name: 'TURNO', value: 10, text: 'Todos os turnos'},
    2: {name: 'DIARIO', value: 1, text: 'Diário'},
    3: {name: 'PARES', value: 8, text: 'Segunda, quarta e sexta'},
    4: {name: 'IMPARES', value: 9, text: 'Terça e quinta'},
    5: {name: 'SEMANAL', value: 2, text: 'Semanal'},
    6: {name: 'QUINZENAL', value: 3, text: 'Quinzenal'},
    7: {name: 'MENSAL', value: 4, text: 'Mensal'},
    8: {name: 'TRIMESTRAL', value: 5, text: 'Trimestral'},
    9: {name: 'SEMESTRAL', value: 6, text: 'Semestral'},
    10: {name: 'ANUAL', value: 7, text: 'Anual'},
};

export default {
    ...values,
    properties,
    getTextByValue: value => {
        return (utilEnum.getItemByProp(value, 'value', properties, true) || {}).text;
    },
    getSelectOptions: () => {
        return utilEnum.getSelectOptions(properties);
    },
};