import utilEnum from './enum';

const values = {
    PRODUTO_FINAL: 1,
    MATERIA_PRIMA: 2,
    APARA: 3,
    APARA_PROCESSO: 4,
};

const properties = {
    1: {name: 'PRODUTO_FINAL', value: 1, text: 'Produto final'},
    2: {name: 'MATERIA_PRIMA', value: 2, text: 'Matéria prima'},
    3: {name: 'APARA', value: 3, text: 'Apara'},
    4: {name: 'APARA_PROCESSO', value: 4, text: 'Apara de processo (rebarba)'},
};

export default {
    ...values,
    properties,
    getSelectOptions: () => {
        return utilEnum.getSelectOptions(properties);
    }
}