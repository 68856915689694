import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

import CustomInput from '../../components/util/CustomInput';
import '../../assets/scss/tarefa.scss';
import styles from '../../assets/scss/overflow.lazy.scss';
import IconButton from '../../components/util/IconButton';
import TarefaItem from '../../components/tarefa/tarefa';
import { faChevronLeft, faChevronRight, faPlus, faSleigh } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import api from '../../services/api';
import util from '../../util/util';
import Loader from '../../components/loader';
import TarefaModal from '../../components/tarefa/tarefa_modal';

export default class Tarefa extends Component {
    constructor(props) {
        super(props);
        util.bindFunctions(this, [
            'refresh',
            'load',
            'generate',
            'changeCalendar',
            'renderTarefas',
            'renderTarefaItem',
        ])
    }

    state = {
        calendar: [],
        years: [],
        months: [],
        year: null,
        month: null,
        selected: null,
        subtarefas: [],
        tarefas: null,
        searching: false,
        usuarios: null,
        tarefasLista: null,
        adicionar: false,
    }
    timeout = null;

    async refresh(selectedForce = null) {
        const scroll = window.scrollY;
        if (!selectedForce) {
            ({ selected: selectedForce } = this.state);
        }

        await this.generate(false);

        const { calendar } = this.state;
        let tarefas = null;
        calendar.some(week => week.some(({ day, tarefas: tarefasDay }) => {
            if (day === selectedForce) {
                tarefas = tarefasDay;
                return true;
            }
            return false;
        }))
        if (!tarefas) {
            selectedForce = null;
        }
        this.setState({ selected: selectedForce, tarefas });
        window.scrollTo(0, scroll);
    }

    async load(calendar) {
        const { month, year } = this.state;
        let resultado = (await api.get(`/tarefa_item?itens=all_month&month=${(month + 1).toString().padStart(2, '0')}&year=${year}`)).data;
        if (!(typeof resultado === 'object' && resultado.length > 0)) {
            resultado = [];
        }
        const days = {};
        resultado.forEach(item => {
            const { dtPrevisao, atrasado } = item;
            const day = moment(dtPrevisao).date();
            if (!days[day]) {
                days[day] = { count: 0, atrasado: 0, tarefas: [] };
            }
            days[day][atrasado ? 'atrasado' : 'count']++;
            days[day].tarefas.push(item);
        })
        calendar = calendar.map(week => {
            return week.map(({ day }) => {
                if (day) {
                    return { day, ...(days[day] || { count: 0, atrasado: 0, tarefas: [] }) };
                }
                return {};
            })
        })
        return calendar;
    }

    async generate(reset = true) {
        let { year, month } = this.state;

        const calendar = [];
        const momentNow = moment(`${year}-${(parseInt(month) + 1).toString().padStart(2, '0')}-01`);
        year = momentNow.format('YYYY');
        month = momentNow.format('MM');
        const daysMonth = momentNow.daysInMonth();
        let dow = 0;
        let days = 1;
        let week = 0;
        do {
            let value = {};
            if (days <= daysMonth) {
                const dayDow = moment(`${year}-${month}-${days.toString().padStart(2, '0')}`).format('d');
                if (dayDow == dow) {
                    value.day = days;
                    days++;
                }
            }
            if (!calendar[week]) {
                calendar[week] = [];
            }
            calendar[week][dow] = value;
            dow++;
            if (dow === 7) {
                dow = 0;
                week++;
            }
        } while (days <= daysMonth || dow > 0);
        
        await this.setState({ searching: true, ...(reset ? { selected: null, tarefas: null } : {}) })
        await this.setState({ searching: false, calendar: await this.load(calendar) })
    }

    async changeCalendar({ month, year }) {
        month = month !== undefined ? month : this.state.month;
        year = year !== undefined ? year : this.state.year;
        if (month > 11) {
            month = 0;
            year++;
        }
        if (month < 0) {
            month = 11;
            year--;
        }

        await this.setState({ month, year })
        if (this.timeout !== null) {
            clearTimeout(this.timeout)
            this.timeout = null;
        } else {
            await this.setState({ searching: true, selected: null, tarefas: null })
        }
        this.timeout = setTimeout(() => {
            this.generate()
            this.timeout = null;
        }, 1000);
    }

    async componentDidMount() {
        styles.use();

        const momentNow = moment();
        const year = momentNow.year();
        const month = momentNow.month();
        const years = Array.from({ length: momentNow.year() - 2020 + 3 }, (_, i) => 2020 + i + 1).map(year => ({ value: year, label: year.toString() }))
        const months = moment.months().map((month, index) => ({ value: index, label: month.firstToUpperCase() }));

        await this.setState({ year, month, years, months })
        this.generate();

        api.get(`/tarefa?itens=all`).then((result) => {
            const tarefasLista = result.data.map(({ codigo: value, nome: label }) => ({ value, label }));
            this.setState({ tarefasLista });
        });
        api.get(`/usuario?itens=all`).then((result) => {
            const usuarios = result.data.map(({ id: value, usuario: label }) => ({ value, label }));
            this.setState({ usuarios });
        });

        window.onkeydown = e => {
            if (e.key == 'F5') {
                e.preventDefault()
                this.refresh();
            }
        }
    }

    componentWillUnmount() {
        styles.unuse();
        window.onkeydown = null;
    }

    renderTarefaItem(item, index) {
        const { subtarefas, tarefasLista, usuarios } = this.state;
        
        return (
            <TarefaItem
                item={item}
                key={index.toString()}
                onSuccess={this.refresh}
                open={subtarefas.includes(item.id)}
                setOpenSubtarefa={open => {
                    let { subtarefas } = this.state;
                    if (open) {
                        subtarefas.push(item.id);
                    } else {
                        subtarefas = subtarefas.filter(sub => sub !== item.id);
                    }
                    this.setState({ subtarefas })
                }}
                tarefas={tarefasLista}
                usuarios={usuarios} />
        )
    }

    renderTarefas() {
        const { selected, tarefas } = this.state;
        let content = null;
        if (selected === null && tarefas === null) {
            content = (<>
                <Row noGutters={true} className="justify-content-end">
                    <Col md="auto">
                        <IconButton icon={faPlus} text="Nova tarefa" onClick={() => this.setState({ adicionar: true })} />
                    </Col>
                </Row>
                <div style={{ marginTop: "0.75rem", textAlign: "center" }}>Selecione um dia no calendário</div>
            </>);
        } else {
            content = (
                <div>
                    <Row noGutters={true} className="tarefas-selectedday align-items-end">
                        <Col>Dia {selected}</Col>
                        <Col md="auto">
                            <IconButton icon={faPlus} text="Nova tarefa" onClick={() => this.setState({ adicionar: true })} />
                        </Col>
                    </Row>
                    {tarefas.map(this.renderTarefaItem)}
                </div>
            )
        }
        return (
            <div className="tarefas-detalhe">
                {content}
            </div>
        )
    }

    render() {
        const { adicionar, tarefasLista, usuarios, searching, selected, calendar, year, month, years, months } = this.state;

        return (<>
            <Row>
                <Col md={7}>
                    <Row className="mb-3">
                        <Col>
                            <Select
                                options={months}
                                value={months.filter(({ value }) => value == month)}
                                onChange={async ({ value: month }) => this.changeCalendar({ month })}
                                menuPlacement="auto"
                                styles={CustomInput.getSelectStyles()} />
                        </Col>
                        <Col>
                            <Select
                                options={years}
                                value={years.filter(({ value }) => value == year)}
                                onChange={async ({ value: year }) => this.changeCalendar({ year })}
                                menuPlacement="auto"
                                styles={CustomInput.getSelectStyles()} />
                        </Col>
                        <Col>
                            <IconButton
                                className="w-100"
                                text="Mês anterior"
                                icon={faChevronLeft}
                                onClick={() => this.changeCalendar({ month: month - 1 })} />
                        </Col>
                        <Col>
                            <IconButton
                                className="w-100"
                                text="Próximo mês"
                                reverse={true}
                                icon={faChevronRight}
                                onClick={() => this.changeCalendar({ month: month + 1 })} />
                        </Col>
                    </Row>
                    <Row noGutters={true} className="tarefas-daysofweek">
                        {moment.weekdaysShort().map((day, index) => (
                            <Col key={index.toString()} className="tarefas-day">
                                {day[0].toUpperCase()}
                            </Col>
                        ))}
                    </Row>
                    <div className="tarefas-calendar">
                        {!searching && calendar.map((week, index) => (
                            <Row noGutters={true} key={index.toString()}>
                                {week.map(({ day, count, atrasado, tarefas }, index) => {
                                    let className = 'tarefas-day w-100 ';
                                    let content = null;
                                    if (day) {
                                        if (index > 0 && index < 6) {
                                            if (count || atrasado) {
                                                className += atrasado ? "tarefas-day-danger" : "tarefas-day-success";
                                            }
                                        } else {
                                            className += "tarefas-day-invalid";
                                        }
                                        
                                        if (tarefas.length > 0) {
                                            content = (
                                                <button
                                                    className={`${className}${selected === day ? ' selected' : ''} tarefas-day-button`}
                                                    onClick={() => {
                                                        if (selected !== day) {
                                                            this.setState({ selected: day, tarefas })
                                                        } else {
                                                            this.setState({ selected: null, tarefas: null })
                                                        }
                                                    }}
                                                >
                                                    {day}
                                                </button>
                                            )
                                        }
                                    }
                                    if (!content) {
                                        content = <div className={className} style={{ cursor: "default" }}>{day}</div>
                                    }
                                    return (
                                        <Col key={index.toString()} className={"d-flex align-items-stretch"}>
                                            {content}
                                        </Col>
                                    )
                                })}
                            </Row>
                        ))}
                        {searching && (
                            <div className="tarefas-calendar-loader"><Loader /></div>
                        )}
                    </div>
                </Col>
                <Col md={5}>
                    {this.renderTarefas()}
                </Col>
            </Row>
            {adicionar !== false && (
                <TarefaModal
                    item={{ dtPrevisao: selected ? `${year}-${(month + 1).toString().padStart(2, '0')}-${selected.toString().padStart(2, '0')}` : null }}
                    show={adicionar === true}
                    tarefas={tarefasLista}
                    usuarios={usuarios}
                    onSuccess={this.refresh}
                    onHide={() => {
                        this.setState({ adicionar: null });
                        setTimeout(() => this.setState({ adicionar: false }), 500);
                    }} />
            )}
        </>);
    }
}