import React, { Component } from "react";
import { Link } from "react-router-dom";
import { load } from 'react-cookies';

import logo from '../assets/images/logo.png';
import "../assets/scss/header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

export default class Header extends Component {
    getLeftMenus = () => {
        const usuario = load('CENTROPLAST_INTRANET_USUARIO');
        return [
            { text: 'Início', link: '' },
            { text: 'Clientes', link: 'cliente', visible: usuario.escritorioVenda },
            { text: 'Pedidos', link: 'pedido', visible: usuario.administrador && usuario.escritorioVenda },
            { text: 'Expedição', link: 'expedicao', visible: usuario.escritorioVenda },
            { text: 'Vendas', link: 'venda', visible: usuario.escritorioVenda },
            { text: 'Produção', link: 'producao' },
            { text: 'Produtos', link: 'produto' },
            { text: 'Estoque', link: 'estoque' },
            { text: 'Tarefas', link: 'tarefa' },
        ];
    }

    getRightMenus = () => {
        const menus = [];
        const usuario = load('CENTROPLAST_INTRANET_USUARIO');
        if (usuario) {
            const { location } = this.props;
            if (!(location === '' && usuario.enviarMensagem)) {
                menus.push({ text: <FontAwesomeIcon icon={faBell} />, link: 'notificacao' });
                if (usuario.administrador) {
                    menus.push({ text: 'Usuários', link: 'usuario' });
                } else {
                    menus.push({ text: 'Meu usuário', link: 'meu_usuario' });
                }
                menus.push({ text: 'Sair', link: 'sair' });
            }
        }

        return menus;
    }

    getMenus = menus => {
        const { location } = this.props;

        return menus.map(menu => {
            const { text, link, visible = true } = menu;
            if (visible) {
                let active = link ? location.startsWith(link) : location === link;
                active = active ? ' active' : '';
    
                return <Link key={link} className={`header-menu${active}`} to={`/${link}`}>{text}</Link>;
            }
            return null;
        });
    }

	render() {
		return (
            <div className="header">
                <Link className="header-menu" to="/">
                    <img className="header-logo" src={logo} />
                </Link>
                <Link className="header-menu" to="/">
                    <h1 className="header-title">Centroplast</h1>
                </Link>
                <div className="header-menus">
                    {this.getMenus(this.getLeftMenus())}
                </div>
                <div className="header-menus">
                    {this.getMenus(this.getRightMenus())}
                </div>
            </div>
		);
	}
}