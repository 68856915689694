import utilEnum from './enum';

const values = {
    PACOTE: 'PC',
    CAIXA: 'CX',
    UNIDADE: 'UN',
    QUILO: 'KG',
};

const properties = {
    1: {name: 'PACOTE', value: 'PC', text: 'Pacote'},
    2: {name: 'CAIXA', value: 'CX', text: 'Caixa'},
    3: {name: 'UNIDADE', value: 'UN', text: 'Unidade'},
    4: {name: 'QUILO', value: 'KG', text: 'Quilo'},
};

export default {
    ...values,
    properties,
    getSelectOptions: () => {
        return utilEnum.getSelectOptions(properties);
    }
};