import { faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import { load, save, remove } from 'react-cookies';
import Modal from 'react-bootstrap/Modal';

import '../../assets/scss/usuario.scss';
import UsuarioModal from '../../components/usuario/usuario_modal';
import api from '../../services/api';
import IconButton from '../../components/util/IconButton';

export default class MeuUsuario extends Component {
    state = {
        show: true,
        trocarSenha: false,
    }

    render() {
        const { trocarSenha, show } = this.state;
        const onHide = (removeCookie = true) => {
            if (removeCookie) {
                remove('CENTROPLAST_INTRANET_USUARIO', { path: '/' });
            }
            window.location.href = '/'
        };
        const { id, nome: usuario, usuarioGrupo } = load('CENTROPLAST_INTRANET_USUARIO');

        return (<>
            <UsuarioModal
                add={false}
                show={show}
                usuario={{ id, usuario, usuarioGrupo }}
                readOnly={true}
                getUsuarioResult={async() => (await api.get(`/login/${id}`)).data}
                onSuccess={async(usuario, trocarSenha) => {
                    if (trocarSenha) {
                        this.setState({ trocarSenha: true, show: false });
                    } else {
                        save('CENTROPLAST_INTRANET_USUARIO', JSON.stringify(usuario), { path: '/' });
                    }
                }}
                onHide={(trocarSenha = false) => {
                    if (!trocarSenha) {
                        onHide(false);
                    }
                }} />
            
            {trocarSenha && (
                <Modal dialogClassName='modal-md' show={trocarSenha} onHide={onHide}>
                    <Modal.Body className="usuario-modal-title">Já que você alterou a sua senha, realize o login novamente</Modal.Body>
                    <Modal.Footer className="usuario-modal-confirm-footer">
                        <IconButton text="Fechar" icon={faTimes} onClick={onHide} />
                    </Modal.Footer>
                </Modal>
            )}
        </>);
    }
}