import React, { Component } from 'react';

import '../../assets/scss/components/of.scss';
import colors from '../../assets/colors';
import IconButton from '../util/IconButton';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';
import Loader from '../loader';
import utilNumber from '../../util/utilNumber';
import { ResponsiveBar } from '@nivo/bar';
import OfModal from './of_modal';
import { ResponsiveLine } from '@nivo/line';

export default class OfItemModal extends Component {
    state = {
        itens: [],
        itensTempo: 0,
        info: {},
        searching: true,
    }

    loadItens = async() => {
        const { of } = this.props;
        const result = (await api.get(`/of_item?of=${of.numero}&itens=all&info=true`)).data;
        
        const { itens, info } = result;
        this.setState({ itens, info, searching: false });
    }

    renderItens = () => {
        const { of: { quantidade, quantidadeProduzida } } = this.props;
        const { itens, itensTempo, info, searching } = this.state;
        if (!searching) {
            if (itens.length > 0) {
                let { media, menor, maior } = info;
                media = media ? parseFloat(media) : 0;
                menor = menor ? parseInt(menor) : 0;
                maior = maior ? parseInt(maior) : 0;

                const yellowMenor = media - ((media - menor) / 3);
                const yellowMaior = maior - ((maior - media) / 3);

                const redMenor = menor + ((media - menor) / 4);
                const redMaior = maior - ((maior - media) / 4);

                const usuarioProducao = {};
                const chartData = [];
                const chartLineData = [];

                let i = 0;
                const itensRender = itens.map((item, index) => {
                    const { data, usuario, tempoMinutos } = item;
                    let color = '';
                    if (tempoMinutos) {
                        if (tempoMinutos < redMenor || tempoMinutos > redMaior) {
                            color = 'red';
                        } else if (tempoMinutos < yellowMenor || tempoMinutos > yellowMaior) {
                            color = 'yellow';
                        }
                        color = color ? `-${color}` : '';

                        chartLineData.push({ x: i++, y: tempoMinutos });
                    }
                    
                    if (!usuarioProducao[usuario.id]) {
                        usuarioProducao[usuario.id] = { usuario, itens: 0 };
                    }
                    usuarioProducao[usuario.id].itens++;
                    
                    return (
                        <div key={index}>
                            <span>Item {(index + 1).toString().padStart(3, '0')} ({usuario.usuario}): {new Date(data).toLocaleString()}</span>
                            {tempoMinutos && <span className={`of-itens-tempo-minutos${color}`}>({tempoMinutos} min.)</span>}
                        </div>
                    )
                });

                for (const key in usuarioProducao) {
                    const value = usuarioProducao[key];
                    chartData.push({ usuario: value.usuario.usuario, itens: value.itens, itensColor: "hsl(16, 70%, 50%)" })
                }

                return (
                    <div className="row">
                        <div className="col-md">
                            {itensRender}
                        </div>
                        <div className="col-md of-itens-sticky">
                            {(media > 0 || menor > 0 || maior > 0) && (
                                <div className="row">
                                    {media > 0 && (
                                        <div className="col-md">
                                            <div className="of-quantidade-title">Média</div>
                                            <div className="of-quantidade-value">{utilNumber.formatNumber(media, 1)} min.</div>
                                        </div>
                                    )}
                                    {menor > 0 && (
                                        <div className="col-md">
                                            <div className="of-quantidade-title">Mínimo</div>
                                            <div className="of-quantidade-value">{menor} min.</div>
                                        </div>
                                    )}
                                    {maior > 0 && (
                                        <div className="col-md">
                                            <div className="of-quantidade-title">Máximo</div>
                                            <div className="of-quantidade-value">{maior} min.</div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {OfModal.renderPrevisaoFinalizar({ media, quantidade, quantidadeProduzida, rowClasses: 'mt-3' })}
                            <div className="row">
                                <div className="col-md of-itens-chart">
                                    <ResponsiveBar
                                        data={chartData}
                                        keys={[ 'itens' ]}
                                        indexBy="usuario"
                                        margin={{ top: 50, right: 25, bottom: 50, left: 60 }}
                                        padding={0.3}
                                        valueScale={{ type: 'linear' }}
                                        indexScale={{ type: 'band', round: true }}
                                        colors={colors.principal}
                                        borderColor={{
                                            from: 'color',
                                            modifiers: [[ 'darker', 1.6 ]]
                                        }}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: '',
                                            legendPosition: 'middle',
                                            legendOffset: 32
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Itens produzidos',
                                            legendPosition: 'middle',
                                            legendOffset: -40,
                                            format: value => {
                                                if (Math.floor(value) === value) {
                                                    return value;
                                                }
                                                return '';
                                            }
                                        }}
                                        tooltip={({ indexValue, value }) => `${indexValue} produziu ${value} ${value > 1 ? 'itens' : 'item'}`}
                                        labelSkipWidth={12}
                                        labelSkipHeight={12}
                                        labelTextColor="#FFF"
                                        animate={true}
                                        motionStiffness={90}
                                        motionDamping={15}
                                        />
                                </div>
                            </div>
                            {Boolean(media) && (<>
                                <div className="row">
                                    <div className="col-md of-itens-chart">
                                        <ResponsiveLine
                                            data={[{
                                                id: 'tempo',
                                                color: colors.principal,
                                                data: chartLineData.slice(itensTempo, itensTempo + 25),
                                            }]}
                                            markers={[{
                                                axis: 'y',
                                                value: media,
                                                lineStyle: { stroke: colors.principal, strokeWidth: 1 },
                                                legend: 'Média',
                                            }]}
                                            colors={colors.principal}
                                            margin={{ top: 50, right: 25, bottom: 50, left: 60 }}
                                            xScale={{ type: 'point' }}
                                            yScale={{
                                                type: 'linear',
                                                min: menor - 1,
                                                max: maior + 1,
                                                reverse: false,
                                            }}
                                            yFormat=" >-.2f"
                                            curve="natural"
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={null}
                                            axisLeft={{
                                                orient: 'left',
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: 'Tempo',
                                                legendOffset: -40,
                                                legendPosition: 'middle'
                                            }}
                                            pointSize={10}
                                            pointColor={{ theme: 'background' }}
                                            pointBorderWidth={2}
                                            pointBorderColor={{ from: 'serieColor' }}
                                            pointLabelYOffset={-12}
                                            enableGridX={false}
                                            crosshairType="y"
                                            useMesh={true}
                                            animate={false}
                                            tooltip={({ point: { data: { y } } }) => (
                                                <div className="of-itens-chart-tooltip">
                                                    {y} min.
                                                </div>
                                            )} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md">
                                        <Form.Control type="range" custom className="of-itens-chart-range" min={0} max={chartLineData.length - 25} defaultValue={itensTempo} onChange={event => {
                                            const { valueAsNumber: itensTempo } = event.target;
                                            this.setState({ itensTempo });
                                        }} />
                                    </div>
                                </div>
                            </>)}
                        </div>
                    </div>
                )
            }
            return <div>Nenhum item</div>
        }
        return (
            <div className="of-itens-loader">
                <Loader />
            </div>
        )
    }

    render() {
        const { of, show, onHide } = this.props;

        return (<>
            <Modal
                show={show}
                dialogClassName="modal-xl"
                onShow={this.loadItens}
                onHide={onHide} >
                <Modal.Header>
                    <Modal.Title>{`Registros de produção da OF ${of.numero}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderItens()}
                </Modal.Body>
                <Modal.Footer>
                    <IconButton text="Voltar" type="dark" icon={faArrowLeft} onClick={onHide} />
                </Modal.Footer>
            </Modal>
        </>)
    }
}