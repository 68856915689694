import React, { Component } from 'react';

import '../../assets/scss/components/venda.scss';
import CustomInput from '../util/CustomInput';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import IconButton from '../util/IconButton';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import vendaTipoEnum from '../../util/enum/vendaTipoEnum';
import vendaStatusEnum from '../../util/enum/vendaStatusEnum';
import api from '../../services/api';

export default class VendaItem extends Component {
    constructor(props) {
        super(props);
        [
            'setLotes',
        ].forEach(funcao => {
            this[funcao] = this[funcao].bind(this);
        });
    }

    state = {
        quantidade: null,
        estoque: [],
        produtoTitle: '',
    }

    async setLotes() {
        let { estoque, quantidade } = this.state;
        let id = 1;
        const lotes = [];
        let estoqueTotal = 0;
        estoque.forEach(item => {
            if (quantidade > 0) {
                let { quantidade: loteQuantidade, reserva, lote, sugeridoQuantidade } = item;
                loteQuantidade -= reserva;
                estoqueTotal += loteQuantidade;
                if (sugeridoQuantidade && loteQuantidade > sugeridoQuantidade) {
                    loteQuantidade = sugeridoQuantidade;
                }
                if (loteQuantidade > 0) {
                    loteQuantidade = quantidade > loteQuantidade ? loteQuantidade : quantidade;
                    quantidade -= loteQuantidade;
                    lotes.push({ id, lote, quantidade: loteQuantidade });
                    id++;
                }
            }
        });

        if (estoqueTotal > lotes.reduce((total, { quantidade: next }) => total + next, 0)) {
            estoque.forEach(item => {
                if (quantidade > 0) {
                    let { quantidade: loteQuantidade, reserva, lote, sugeridoQuantidade } = item;
                    if (sugeridoQuantidade) {
                        loteQuantidade -= reserva + sugeridoQuantidade;
                        console.log(loteQuantidade);

                        if (loteQuantidade > 0) {
                            loteQuantidade = quantidade > loteQuantidade ? loteQuantidade : quantidade;
                            quantidade -= loteQuantidade;
                            lotes[lotes.findIndex(({ lote: item }) => item == lote)].quantidade += loteQuantidade;
                        }
                    }
                }
            });
        }
        
        lotes.push({ id, lote: null, quantidade: null });

        await this.props.setLotes(lotes);
    }

    renderCustomInput({ onChange, ...params }, baseValues = null) {
        const { onChange: onChangeProp, produtos } = this.props;
        params.onChange = (event) => {
            const { target: { name, value } } = event;
            if (name.endsWith('produto')) {
                const produtoTitle = produtos.find(produto => produto.value == value).label;
                this.setState({ produtoTitle });
            }
            (onChange || onChangeProp)(event);
        };

        if (!baseValues) {
            let { venda } = this.props;
            if (!venda) {
                ({ venda } = this.state);
            }
            baseValues = venda;
        }
        let { id, name, type } = params;
        if (!name && id) {
            name = id;
        }

        if (!type) {
            type = 'number';
            params.type = type;
        }
        if (type === 'number') {
            if (!params.min) {
                params.min = 0;
            }
        }

        const defaultValueName = name.split('-')[2];
        if (params.value === undefined && params.defaultValue === undefined) {
            if (defaultValueName && baseValues[defaultValueName]) {
                let value = baseValues[defaultValueName];
                if (typeof value === 'object') {
                    value = value.codigo || value.id || value;
                }
                
                if (type === 'number') {
                    const numeric = (params.step < 1 ? parseFloat : parseInt)(value);
                    value = numeric > 0 ? numeric : '';
                }
                params.defaultValue = value;
            }
        }

        return (
            <CustomInput {...params} />
        )
    }

    renderCustomInputLotes(params, baseValue) {
        const { onChangeLotes } = this.props;
        params.onChange = onChangeLotes;
        return this.renderCustomInput(params, baseValue);
    }

    renderLotes() {
        const { tipo, status, item = {}, readOnly } = this.props;

        const baseId = `item-${item.id}`;
        const { lotes = [] } = item;
        const atendimento = !readOnly && tipo === vendaTipoEnum.ATENDIMENTO;
        const readOnlyLotes = readOnly || vendaTipoEnum.isPedido(tipo);
        const length = lotes.length > 0;

        if (!length && readOnlyLotes) {
            if (status == vendaStatusEnum.PREPARADA) {
                return "Sem lotes";
            }
            return (
                <Alert variant="info" className={`mb-0 ${atendimento ? 'mt-3' : ''}`}>
                    A expedição informará{atendimento ? ' a quantidade e' : ''} os lotes
                </Alert>
            )
        }

        return (<>
            {length && (
                <Row className="justify-content-around mb-1">
                    <Col md={5}>
                        Quantidade
                    </Col>
                    <Col md={5}>
                        Lote
                    </Col>
                </Row>
            )}
            {lotes.map((lote, index) => {
                const loteId = `${baseId}-lote-${lote.id}`;
                return (
                    <Row key={index} className="justify-content-around mb-1">
                        <Col md={5}>
                            {readOnlyLotes && lote.quantidade}
                            {!readOnlyLotes && this.renderCustomInputLotes({
                                className: "text-center",
                                name: `${loteId}-quantidade`,
                                value: lote.quantidade || '',
                                readOnly: readOnlyLotes,
                            }, lote)}
                        </Col>
                        <Col md={5}>
                            {readOnlyLotes && lote.lote}
                            {!readOnlyLotes && this.renderCustomInputLotes({
                                className: "text-center",
                                name: `${loteId}-lote`,
                                value: lote.lote || '',
                                readOnly: readOnlyLotes,
                            }, lote)}
                        </Col>
                    </Row>
                )
            })}
        </>)
    }

    render() {
        const { add, tipo, item = {}, produtos = null, readOnly, onChange: onChangeProp, onRemove } = this.props;
        const { produtoTitle } = this.state;

        const baseId = `item-${item.id}`;
        const { produto = {} } = item;
        const atendimento = !readOnly && tipo === vendaTipoEnum.ATENDIMENTO;
        const readOnlyProduto = readOnly || (atendimento && !add)

        return (
            <Container className="venda-item-container mb-3">
                <Row className={!atendimento || !add ? `mb-3` : ''}>
                    <Col>
                        <div className="venda-item-title-bold">
                            {!readOnlyProduto && (
                                <IconButton icon={faTimes} type="transparent" onClick={onRemove} />
                            )}
                            <span className="ml-2">Produto {typeof produto === 'object' ? produto.codigo : produto}</span>
                        </div>
                        {readOnlyProduto && (<>
                            <div className="venda-item-title">{produto.nome}</div>
                        </>)}
                        {!readOnlyProduto && (
                            <div title={produtoTitle || produto.nome}>
                                {this.renderCustomInput({
                                    formGroup: !atendimento,
                                    name: `${baseId}-produto`,
                                    type: "select",
                                    options: produtos,
                                    item,
                                    onChange: async(event) => {
                                        await onChangeProp(event);

                                        if (add && vendaTipoEnum.isVenda(tipo)) {
                                            const { target: { value } } = event;
                                            const { lista: estoque } = (await api.get(`/estoque?produto=${value}&produto_codigo=true`)).data;
                                            await this.setState({ estoque })
                                            await this.setLotes();
                                        }
                                    }
                                }, item)}
                            </div>
                        )}
                    </Col>
                </Row>
                {Boolean(!atendimento || (!add && item.quantidade)) && (
                    <Row className="justify-content-around align-items-end mb-3">
                        <Col md={6}>
                            <div className="venda-quantidade-title">Quantidade</div>
                            {(readOnly || atendimento) && (<>
                                <div className="venda-quantidade-value">{item.quantidade}</div>
                            </>)}
                            {!readOnly && !atendimento && this.renderCustomInput({
                                className: "text-center",
                                name: `${baseId}-quantidade`,
                                readOnly,
                                onChange: async(event) => {
                                    await onChangeProp(event);

                                    if (add && vendaTipoEnum.isVenda(tipo)) {
                                        const { estoque } = this.state;
                                        if (estoque.length == 0 && produto) {
                                            const { lista: estoque } = (await api.get(`/estoque?produto=${produto.codigo}&produto_codigo=true`)).data;
                                            await this.setState({ estoque })
                                        }
                                        const { target: { value: quantidade } } = event;
                                        await this.setState({ quantidade })
                                        await this.setLotes();
                                    }
                                }
                            }, item)}
                        </Col>
                    </Row>
                )}
                <div className="text-center">
                    {this.renderLotes()}
                </div>
            </Container>
        )
    }
}