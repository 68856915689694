import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../assets/scss/components/icon-button.scss';
import { Link } from 'react-router-dom';
import Loader from '../loader';

export default class IconButton extends Component {
    state = {
        loader: false,
    }

    render() {
        const { loader } = this.state;
        let { reverse = false, text, type, icon, smallIcon = false, tooltip, link, onClick: onClickProp, column = false, className = '', customRef, ...props } = this.props;
        
        if (customRef !== undefined) {
            props.ref = customRef;
        }
        if (tooltip) {
            props['data-tip'] = tooltip;
        }
        const onlyIcon = !text ? ' cpl-btn-onlyicon' : '';
        if (column) {
            className += ' cpl-btn-column';
        }

        icon = icon ? <FontAwesomeIcon className={`cpl-btn-icon${smallIcon ? ' cpl-btn-iconsmall' : ''}`} icon={icon} /> : null;
        const content = loader ? <Loader type="white-small" /> : (<>
            {!reverse && icon}
            {text && <span className={`cpl-btn-text${reverse ? ' cpl-btn-text-reverse' : ''}`}>{text}</span>}
            {reverse && icon}
        </>);
        
        const onClick = async(event) => {
            this.setState({ loader: true });
            await onClickProp(event);
            this.setState({ loader: false });
        };

        if (link) {
            if (!props.disabled) {
                return (
                    <Link to={link} className={`cpl-btn-${type || 'primary'}${onlyIcon} ${className}`} {...props}>
                        {content}
                    </Link>
                )
            }
            return (
                <div className={`cpl-btn-primary${onlyIcon} ${className}`} {...props}>
                    {content}
                </div>
            );
        }
        return (
            <button className={`cpl-btn-${type || 'primary'}${onlyIcon}${loader ? ' cpl-btn-loader' : ''} ${className}`} {...props} onClick={onClick} >
                {content}
            </button>
        );
    };
}