import React, { Component } from 'react';

import '../../assets/scss/components/usuario_grupo.scss';
import { faCheck, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import Modal from 'react-bootstrap/Modal';
import CustomInput from '../util/CustomInput';
import Alert from '../util/Alert';
import api from '../../services/api';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class UsuarioGrupoModal extends Component {
    constructor(props) {
        super(props);
        [
            'hideSuccess',
            'delete',
            'save',
            'saveState',
            'renderCustomInput',
            'renderCheckboxes',
        ].forEach(funcao => {
            this[funcao] = this[funcao].bind(this);
        });
    }

    state = {
        error: null,
        show: true,
        confirmarExcluir: false,
        usuarioGrupo: {
            id: null,
            codigo: null,
            nome: null,
        },
    }

    static renderModal({ show, size, text, footer, onHide, onSuccess }) {
        return (
            <Modal dialogClassName={size || 'modal-md'} show={show} onHide={onHide}>
                <Modal.Body className="usuariogrupo-modal-title">
                    {text}
                </Modal.Body>
                <Modal.Footer className="usuariogrupo-modal-confirm-footer">
                    {footer || (<>
                        <IconButton text="Sim" icon={faCheck} onClick={onSuccess} />
                        <IconButton text="Não" icon={faTimes} onClick={onHide} />
                    </>)}
                </Modal.Footer>
            </Modal>
        );
    }

    async hideSuccess(usuarioGrupo) {
        const { onHide, onSuccess } = this.props;
        await onHide();
        await onSuccess(usuarioGrupo);
    }

    async delete() {
        const { confirmarExcluir } = this.state;
        if (!confirmarExcluir) {
            this.setState({ confirmarExcluir: true });
            return;
        }
        this.setState({ confirmarExcluir: false, show: false });
        
        const { codigo } = this.props.usuarioGrupo;

        try {
            const result = (await api.delete(`/usuario_grupo/${codigo}`)).data;
            console.log(result);

            const { error } = result;

            switch (error) {
                case 0:
                    this.hideSuccess(codigo);
                    break;

                case 3:
                    this.setState({ error: "Não foi possível excluir pois algum usuário utiliza esse grupo" })
                    break;
            
                default:
                    this.setState({ error: "Aconteceu um erro" })
            }
        } catch (error) {
            console.log(error);
        }
    }

    async save() {
        let { add = true, getUsuarioGrupoResult } = this.props;
        const { usuarioGrupo } = this.state;

        try {
            const result = (await (add ? api.post : api.put)('/usuario_grupo', usuarioGrupo)).data;
            console.log(result);

            const { error, usuarioGrupo: usuarioGrupoResult } = result;

            if (error === 0) {
                getUsuarioGrupoResult = getUsuarioGrupoResult || (async() => (await api.get(`/usuario_grupo/${usuarioGrupo.codigo}`)).data);
                setTimeout(async() => this.hideSuccess(usuarioGrupoResult || (await getUsuarioGrupoResult())), 250);
            } else {
                if (add) {
                    switch (error) {
                        default:
                            this.setState({ error: "Aconteceu um erro" })
                            break;
                    }
                } else {
                    switch (error) {
                        default:
                            this.setState({ error: "Aconteceu um erro" })
                            break;
                    }
                }
                this.setState({ show: true });
            }
        } catch (error) {
            console.log(error);
        }
    }

    saveState(event) {
        const { id, name, value, checked } = event.target;
        const checkbox = !name && id && value == 'on' && checked !== undefined;

        const { usuarioGrupo } = this.state;
        usuarioGrupo[checkbox ? id : name] = checkbox ? (checked ? '1' : '0') : value;

        this.setState({ usuarioGrupo });
    }

    componentDidMount() {
        const { usuarioGrupo: prop } = this.props;
        if (prop) {
            let { id, codigo, nome } = prop;
            
            this.setState({
                usuarioGrupo: { id, codigo, nome },
            });
        }
    }

    renderCustomInput({ defaultValueName, ...params }, baseValues = null) {
        if (!baseValues) {
            let { usuarioGrupo } = this.props;
            if (!usuarioGrupo) {
                ({ usuarioGrupo } = this.state);
            }
            baseValues = usuarioGrupo;
        }
        let { id, name } = params;
        if (!name && id) {
            name = id;
        }

        if (!params.onChange) {
            params.onChange = this.saveState;
        }

        defaultValueName = defaultValueName || name;
        if (params.value === undefined && defaultValueName && baseValues[defaultValueName]) {
            let value = baseValues[defaultValueName];
            if (typeof value === 'object') {
                value = value.codigo || value.id || value;
            }
            
            params.defaultValue = value;
        }

        return (
            <CustomInput {...params} />
        )
    }

    renderCheckboxes(checkboxes) {
        return checkboxes.map((item, index) => (
            <Row key={index.toString()}>
                <Col>
                    {this.renderCustomInput({ ...item, type: "checkbox" })}
                </Col>
            </Row>
        ))
    }

    render() {
        const {
            add = true,
            usuarioGrupo = {},
            show,
            onHide,
            readOnly
        } = this.props;

        const {
            confirmarExcluir,
            error,
            show: stateShow,
        } = this.state;

        return (<>
            <Modal
                show={show && stateShow && !confirmarExcluir}
                dialogClassName="modal-md"
                onShow={() => this.nomeRef.focus()}
                onHide={() => onHide()} >
                <Modal.Header>
                    <Modal.Title>
                        {add
                            ? 'Adicionar grupo de usuário'
                            : `Grupo de usuário ${usuarioGrupo.codigo}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            {this.renderCustomInput({
                                label: "Nome",
                                name: "nome",
                                customRef: nomeRef => this.nomeRef = nomeRef,
                            })}
                        </Col>
                    </Row>
                    {this.renderCheckboxes([
                        { label: "Pode enviar mensagem?", id: "enviarMensagem" },
                        { label: "É suporte?", id: "eSuporte" },
                        { label: "É administrador?", id: "eAdministrador" },
                        { label: "É do setor de vendas no escritório?", id: "eEscritorioVenda" },
                        { label: "É do setor de expedição no escritório?", id: "eEscritorioExpedicao" },
                        { label: "É do setor de produção no escritório?", id: "eEscritorioProducao" },
                        { label: "É do setor de expedição na fábrica?", id: "eExpedicao" },
                        { label: "É do setor de produção na fábrica?", id: "eProducao" },
                        { label: "É responsável pela limpeza e manutenção?", id: "eLimpeza" },
                    ])}
                </Modal.Body>
                <Modal.Footer>
                    <IconButton text="Salvar" icon={faSave} onClick={this.save} />
                    {!add && !readOnly && <IconButton type="danger" text="Excluir" icon={faTrash} onClick={this.delete} />}
                    <IconButton text="Fechar" type="dark" icon={faTimes} onClick={() => onHide()} />
                </Modal.Footer>
            </Modal>

            {UsuarioGrupoModal.renderModal({
                text: `Deseja realmente excluir o grupo ${usuarioGrupo.nome}?`,
                show: confirmarExcluir !== false,
                onSuccess: this.delete,
                onHide: () => this.setState({ confirmarExcluir: false }),
            })}

            {error && (
                <Alert message={error} onDismiss={() => this.setState({ error: null })} />
            )}
        </>)
    }
}