import utilEnum from './enum';

const values = {
    BOLETO: 1,
    DEPOSITO: 2,
    CARTAO: 3,
    ENCERRADA: 4,
    BAIXADA: 5,
};

const properties = {
    1: {name: 'BOLETO', value: 1, text: 'Boleto'},
    2: {name: 'DEPOSITO', value: 2, text: 'Depósito bancário'},
    3: {name: 'CARTAO', value: 3, text: 'Cartão / PagSeguro'},
};

export default {
    ...values,
    properties,
    getSelectOptions: () => {
        return utilEnum.getSelectOptions(properties);
    }
};