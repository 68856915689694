import React, { Component } from 'react';

import '../../assets/scss/components/pedido.scss';
import { faCheck, faChevronLeft, faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import Modal from 'react-bootstrap/Modal';
import CustomInput from '../util/CustomInput';
import api from '../../services/api';
import Loader from '../loader';
import ClienteModal from './cliente_modal';

export default class ClienteSearchModal extends Component {
    state = {
        show: true,
        search: null,
        result: [],
        searching: false,
        edit: false,
    }

    hideSuccess = async(item) => {
        const { onHide, onSuccess } = this.props;
        await onHide();
        await onSuccess(item);
    }

    saveState = (event) => {
        const { name, value } = event.target;

        this.setState({ [name]: value });
    }

    renderCustomInput = (params) => {
        if (!params.onChange) {
            params.onChange = this.saveState;
        }
        return (
            <CustomInput {...params} />
        )
    }

    searchClientes = async() => {
        const { search } = this.state;
        this.setState({ searching: true });

        const result = (await api.get(`/cliente?search=${search}`)).data;

        this.setState({ result, searching: false });
    }

    renderResult = () => {
        const { result, searching } = this.state;
        if (!searching) {
            if (result.length > 0) {
                return (<>
                    <div className="row align-items-center my-2">
                        <div className="col-md text-right">Código</div>
                        <div className="col-md-5">Nome</div>
                        <div className="col-md-4">Cidade</div>
                    </div>
                    {result.map((item, index) => {
                        return (
                            <div key={index} className="row align-items-center my-2">
                                <div className="col-md-auto d-flex flex-column pl-3 pr-1">
                                    <IconButton type="transparent" icon={faCheck} onClick={() => this.hideSuccess(item)} />
                                </div>
                                <div className="col-md-auto d-flex flex-column pl-1">
                                    <IconButton type="transparent" icon={faEdit} onClick={() => this.setState({ show: false, edit: item })} />
                                </div>
                                <div className="col-md text-right">
                                    {item.codigo}
                                </div>
                                <div className="col-md-5">
                                    {item.nome}
                                </div>
                                <div className="col-md-4">
                                    {item.cidade.nome} / {item.cidade.uf.sigla}
                                </div>
                            </div>
                        );
                    })}
                </>);
            }
            return (
                <div>
                    Sem resultados
                </div>
            )
        }
        return (
            <div className="row justify-content-center text-center">
                <div className="col-md-auto">
                    <Loader width={30} heigth={30} />
                </div>
            </div>
        )
    }

    render() {
        const { show, onHide } = this.props;
        const { show: stateShow, search, edit } = this.state;

        return (<>
            <Modal
                show={show && stateShow}
                dialogClassName="modal-lg"
                onShow={async() => {
                    let { defaultSearch } = this.props;
                    if (defaultSearch && typeof defaultSearch === 'object') {
                        defaultSearch = defaultSearch.nome || defaultSearch.codigo || '';
                    }
                    if (defaultSearch) {
                        await this.setState({ search: defaultSearch });
                        await this.searchClientes();
                    }
                }}
                onHide={onHide} >
                <Modal.Header>
                    <Modal.Title>Buscar cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md">
                            <div className="row">
                                <div className="col-md">
                                    <CustomInput
                                        label="Busque por nome ou código"
                                        onChange={event => {
                                            if (this.timeout) {
                                                clearTimeout(this.timeout);
                                            }
                                            this.setState({ searching: true });
                                            this.timeout = setTimeout(() => {
                                                const { value: search } = event.target;
                                                this.setState({ search });
                                                this.searchClientes();
                                            }, 1000)
                                        }}
                                        defaultValue={search} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    {this.renderResult()}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton text="Voltar" type="dark" icon={faChevronLeft} onClick={onHide} />
                </Modal.Footer>
            </Modal>
            {edit !== false && (
                <ClienteModal
                    show={edit !== null}
                    cliente={edit}
                    onHide={() => {
                        this.setState({ edit: null });
                        setTimeout(this.setState({ edit: false, show: true }), 500);
                    }}
                    onSuccess={() => this.searchClientes()} />
            )}
        </>)
    }
}