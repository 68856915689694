import { Component } from 'react';
import cookie from 'react-cookies';
import global from '../util/global';

export default class Sair extends Component {
    componentDidMount() {
        cookie.remove('CENTROPLAST_INTRANET_USUARIO');
        window.location.href = global.BASE_URL;
    }

    render() {
        return null;
    }
}