const values = {
    CONFIRMADA: 1,
    PREPARADA: 3,
    ENTREGUE: 2,
};

const properties = {
    1: {name: 'CONFIRMADA', value: 1, text: 'Confirmado'},
    3: {name: 'PREPARADA', value: 3, text: 'Preparado'},
    2: {name: 'ENTREGUE', value: 2, text: 'Entregue / Vendido'},
};

export default {
    ...values,
    properties,
};