import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import IconButton from "../util/IconButton";
import CustomInput from "../util/CustomInput";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default class OfQualidade extends Component {
    static renderHeader = (headerText, value) => {
        return (
            <Accordion.Toggle as={Card.Header} eventKey="1">
                <Row className={value !== '1' ? "mb-1" : ''}>
                    <Col md="auto" className="pr-0">
                        <CustomInput formGroup={false} type="checkbox" value={value || '0'} />
                    </Col>
                    <Col className="pl-0">{headerText}</Col>
                </Row>
            </Accordion.Toggle>
        );
    }

    static renderBody = (body) => {
        return (
            <Accordion.Collapse eventKey="1">
                <Card.Body>
                    <Row className="justify-content-center">
                        <Col md={10}>
                            {body}
                        </Col>
                    </Row>
                </Card.Body>
            </Accordion.Collapse>
        );
    }

    static renderButton = ({ btnText, btnOnClick, of, add }) => {
        const { quantidadeProduzida } = of;
        
        return (<>
            {!add && quantidadeProduzida > 0 && (
                <Row className="mb-2">
                    <Col>
                        <IconButton className="w-100" text={btnText} icon={faSearch} onClick={btnOnClick} />
                    </Col>
                </Row>
            )}
        </>);
    }

    static render = ({ value, onSelect, header, body, bodyInputs, renderInput, btnText, btnOnClick, of, add }) => {
        if (bodyInputs !== undefined && renderInput !== undefined) {
            bodyInputs = bodyInputs.map((params, index) => (
                <Row key={index}>
                    <Col>
                        {renderInput(params)}
                    </Col>
                </Row>
            ));
        }
        return (
            <Accordion activeKey={value || '0'} onSelect={onSelect}>
                <Card>
                    {OfQualidade.renderHeader(header, value)}
                    {OfQualidade.renderBody(<>
                        {body}
                        {bodyInputs}
                        {OfQualidade.renderButton({ btnText, btnOnClick, of, add })}
                    </>)}
                </Card>
            </Accordion>
        );
    }
}