const values = {
    BB: 1,
    SICREDI: 2,
    CEF: 3,
};

const properties = {
    1: {name: 'BB', value: 1, text: 'Banco do Brasil'},
    2: {name: 'SICREDI', value: 2, text: 'Sicredi'},
    3: {name: 'CEF', value: 3, text: 'Caixa Econômica Federal'},
};

const getSelectOptions = () => {
    const options = [];
    for (const value in properties) {
        const prop = properties[value];
        const { name, text: label } = prop;

        if (name !== 'CEF') {
            options.push({ value, label });
        }
    }
    return options;
}

export default {
    ...values,
    properties,
    getSelectOptions,
};