import Inicio from './inicio';
import Login from './login';
import Sair from './sair';
import Block from './block';

import MeuUsuario from './usuario/meu_usuario';
import Notificacao from './usuario/notificacao';
import Usuario from './usuario/usuario';
import UsuarioGrupo from './usuario/usuario_grupo';
import UsuarioMensagem from './usuario/usuario_mensagem';

import Cliente from './cliente/cliente';

import Estoque from './estoque/estoque';
import EstoqueDeposito from './estoque/estoque_deposito';
import EstoqueInventario from './estoque/estoque_inventario';

import Expedicao from './expedicao/expedicao';
import ExpedicaoEmbarque from './expedicao/expedicao_embarque';
import ExpedicaoEnviado from './expedicao/expedicao_enviado';
import ExpedicaoArquivo from './expedicao/expedicao_arquivo';

import Pedido from './pedido/pedido';

import Producao from './producao/producao';
import ProducaoArquivo from './producao/producao_arquivo';
import ProducaoBaixar from './producao/producao_baixar';

import Produto from './produto/produto';

import Tarefa from './tarefa/tarefa';

import Venda from './venda/venda';
import VendaPreparada from './venda/venda_preparada';
import VendaArquivo from './venda/venda_arquivo';

export default {
    usuario: {
        title: 'Usuários',
        back: '/',
        sub: {
            grupo: {
                title: 'Grupos de usuário',
                back: '/usuario',
            },
            mensagem: {
                title: '',
            }
        },
    },
    notificacao: {
        title: 'Notificações',
        back: '/',
    },
    cliente: {
        title: 'Cliente',
        back: '/',
    },
    estoque: {
        title: 'Estoque',
        back: '/',
        refresh: true,
        sub: {
            deposito: {
                title: 'Mapa do depósito',
                back: '/estoque',
                refresh: true,
                subtitle: true,
            },
            inventario: {
                title: 'Inventário de estoque',
                back: '/estoque',
            },
        },
    },
    pedido: {
        title: 'Pedidos pendentes',
        back: '/',
    },
    tarefa: {
        title: 'Tarefas',
        back: '/',
        refresh: true,
    },
    expedicao: {
        title: 'Expedição',
        back: '/',
        count: true,
        sub: {
            enviados: {
                title: 'Enviados à expedição',
                text: 'Pedidos enviados à expedição',
                back: '/expedicao',
            },
            embarque: {
                title: 'NFs para emitir',
                text: 'Notas fiscais para emitir',
                back: '/expedicao',
            },
            arquivo: {
                title: 'Pedidos já emitidos',
                back: '/expedicao',
            },
        },
    },
    producao: {
        title: 'Produção',
        back: '/',
        refresh: true,
        sub: {
            baixar: {
                title: 'Baixar ordens de fabricação',
                text: 'Baixar ordens',
                back: '/producao',
            },
            arquivo: {
                title: 'Arquivo de ordens de fabricação',
                back: '/producao',
            },
        },
    },
    produto: {
        title: 'Produto',
        back: '/',
    },
    venda: {
        title: 'Vendas',
        back: '/',
        sub: {
            preparados: {
                title: 'Vendas preparadas',
                back: '/venda',
            },
            arquivo: {
                title: 'Vendas finalizadas',
                back: '/venda',
            },
        },
    },
};

export {
    Inicio,
    Login,
    Sair,
    Block,

    MeuUsuario,
    Usuario,
    UsuarioGrupo,
    UsuarioMensagem,
    Notificacao,

    Cliente,

    Estoque,
    EstoqueDeposito,
    EstoqueInventario,

    Expedicao,
    ExpedicaoEmbarque,
    ExpedicaoEnviado,
    ExpedicaoArquivo,

    Pedido,
    
    Producao,
    ProducaoArquivo,
    ProducaoBaixar,

    Produto,

    Tarefa,

    Venda,
    VendaPreparada,
    VendaArquivo,
};