import React, { Component } from 'react';

import '../../assets/scss/components/venda.scss';
import { faCheck, faChevronLeft, faFileInvoiceDollar, faPlus, faQrcode, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import Modal from 'react-bootstrap/Modal';
import CustomInput from '../util/CustomInput';
import Alert from '../util/Alert';
import api from '../../services/api';
import vendaStatusEnum from '../../util/enum/vendaStatusEnum';
import vendaTipoEnum from '../../util/enum/vendaTipoEnum';
import pagamentoFormaEnum from '../../util/enum/pagamentoFormaEnum';
import bancoEnum from '../../util/enum/bancoEnum';
import ClienteAddModal from '../cliente/cliente_add_modal';
import CidadeChooseModal from '../cidade/cidade_choose_modal';
import ClienteSearchModal from '../cliente/cliente_search_modal';
import VendaItem from './venda_item';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ReactTooltip from 'react-tooltip';
import { load } from 'react-cookies';

export default class VendaModal extends Component {
    state = {
        error: null,
        show: true,
        addCliente: false,
        escolherCidades: null,
        confirmarFinalizar: false,
        confirmarExcluir: false,
        confirmarExcluirLotes: null,
        confirmarVoltar: false,
        newNumero: false,
        info: {},
        venda: {
            numero: null,
            cliente: null,
            dtRetira: null,
            itens: [],
        },
    }

    static renderModal = ({ show, size, text, footer, onHide, onCancel, onSuccess }) => {
        return (
            <Modal dialogClassName={size || 'modal-md'} show={show} onHide={onHide}>
                <Modal.Body className="venda-modal-title">
                    {text}
                </Modal.Body>
                <Modal.Footer className="venda-modal-confirm-footer">
                    {footer || (<>
                        <IconButton text="Sim" icon={faCheck} onClick={onSuccess} />
                        <IconButton text="Não" icon={faTimes} onClick={onCancel || onHide} />
                    </>)}
                </Modal.Footer>
            </Modal>
        );
    }

    hideSuccess = async venda => {
        const { onHide, onSuccess } = this.props;
        await onHide();
        await onSuccess(venda);
    }

    finalizar = async() => {
        const { venda: { numero, tipo, newNumero, newTipo, newStatus } } = this.state;

        if (!(newNumero && newTipo && newStatus)) {
            this.setState({ confirmarFinalizar: true });
            return;
        }
        
        try {
            const result = (await api.put(`/venda`, {
                numero,
                newNumero,
                tipo,
                newTipo,
                newStatus,
                finalizar: true,
                react: true,
            })).data;
            console.log(result);
            
            if (result.error === 0) {
                this.setState({ confirmarFinalizar: false });
                this.hideSuccess(this.props.venda);
            }
        } catch (error) {
            console.log(error);
        }
    }

    changeStatus = async(status) => {
        const { venda: { numero, tipo } } = this.state;

        try {
            const result = (await api.put(`/venda`, {
                numero,
                tipo,
                [status]: true,
                react: true,
            })).data;
            console.log(result);
            
            if (result.error === 0) {
                this.hideSuccess(numero);
            }
        } catch (error) {
            console.log(error);
        }
    }

    entregar = async() => {
        this.changeStatus('entregar');
    }

    preparar = async() => {
        this.changeStatus('preparar');
    }

    delete = async() => {
        const { numero, tipo } = this.props.venda;
        const { confirmarExcluir, confirmarExcluirLotes } = this.state;
        if (!confirmarExcluir) {
            this.setState({ confirmarExcluir: true });
            return;
        }
        this.setState({ confirmarExcluir: false });

        if (vendaTipoEnum.isVenda(tipo)) {
            if (confirmarExcluirLotes === null) {
                this.setState({ confirmarExcluirLotes: true });
                return;
            }
            this.setState({ confirmarExcluirLotes: null, show: false });
        }

        try {
            const result = (await api.delete(`/venda/${numero}?tipo=${tipo}${!confirmarExcluirLotes ? '&manter_lotes=true' : ''}`)).data;
            console.log(result);

            if (result.error === 0) {
                this.hideSuccess(numero);
            }
        } catch (error) {
            console.log(error);
        }
    }

    save = async() => {
        const { add = true, nexts } = this.props;
        const { venda } = this.state;
        const { itens } = venda;

        if (!venda.numero) {
            venda.numero = this.getNextNumero();
        }

        if (vendaTipoEnum.isVenda(venda.tipo)) {
            const quantidadesIguais = itens.filter(item => {
                const { quantidade, lotes } = item;

                let temLotes = false;
                const quantidadeLotes = lotes.reduce((total, lote) => {
                    if (lote.lote && lote.quantidade) {
                        temLotes = true;
                    }
                    return total + (parseInt(lote.quantidade || 0));
                }, 0);

                return quantidade != quantidadeLotes && temLotes;
            });
            if (quantidadesIguais.length > 0) {
                const plural = quantidadesIguais.length > 1;
                let quantidadesIguaisTexto = quantidadesIguais.map(item => (item.produto.codigo || item.produto)).join(', ').replace(/, ([^, ]*)$/, ' e $1');
                this.setState({ error: `${plural ? 'As quantidades dos itens' : 'A quantidade do item'} ${quantidadesIguaisTexto} não ${plural ? 'coincidem' : 'coincide'} com a soma dos lotes` })
                return;
            }
        }

        try {
            venda.react = true;
            const result = (await (add ? api.post : api.put)('/venda', venda)).data;
            console.log(result);

            const { error, warning, venda: vendaResult } = result;

            if (error === 0) {
                await this.setState({ show: false });
                setTimeout(async() => this.hideSuccess(vendaResult || (await api.get(`/venda/${venda.numero}?tipo=${venda.tipo}`)).data), 250);
            } else {
                let show = true;
                switch (warning) {
                    case 101:
                        if (result.cliente !== undefined) {
                            show = false;
                            this.setState({ addCliente: true });
                        }
                        break;
                        
                    case 102:
                        const { cidade: escolherCidades } = result;
                        if (escolherCidades !== undefined) {
                            show = false;
                            this.setState({ escolherCidades });
                        }
                        break;

                    default:
                        switch (error) {
                            case 103:
                                show = false;
                                this.setState({ error: "A cidade não existe", addCliente: true })
                                break;
                        
                            default:
                                const { lote, produto, quantidade } = result;

                                switch (error) {
                                    case 2:
                                        if (lote !== undefined) {
                                            this.setState({ error: `O lote ${lote} não corresponde a este produto` })
                                            break;
                                        }

                                    case 3:
                                    case 4:
                                        let estoqueTexto = null;
                                        if (produto !== undefined) {
                                            estoqueTexto = `produto ${produto}`;
                                        } else if (lote !== undefined) {
                                            estoqueTexto = `lote ${lote}`;
                                        }

                                        if (estoqueTexto) {
                                            if (quantidade !== undefined) {
                                                if (quantidade > 0) {
                                                    this.setState({ error: `Há apenas${error === 4 ? ' mais' : ''} ${quantidade} em estoque do ${estoqueTexto}` })
                                                } else {
                                                    this.setState({ error: `Não há${error === 4 ? ' mais' : ''} estoque do ${estoqueTexto}` })
                                                }
                                            } else {
                                                this.setState({ error: `Não há estoque suficiente para o ${estoqueTexto}` })
                                            }
                                            break;
                                        }

                                    default:
                                        this.setState({ error: "Aconteceu um erro" })
                                        break;
                                }
                        }
                }
                this.setState({ show });
            }
        } catch (error) {
            console.log(error);
        }
    }

    saveState = (event) => {
        const { id, name, value, checked } = event.target;
        const checkbox = !name && id && value == 'on' && checked !== undefined;

        const { venda } = this.state;
        venda[checkbox ? id : name] = checkbox ? (checked ? '1' : '0') : value;

        this.setState({ venda });
    }

    saveStateItens = (event) => {
        let { name, value } = event.target;
        const { venda } = this.state;
        const { itens } = venda;

        const nameSplit = name.split('-');
        name = nameSplit[2];
        const id = nameSplit[1];
        const index = itens.findIndex(item => item.id == id);

        itens[index][name] = value;

        this.setState({ venda });
    }

    saveStateItensLotes = async(event) => {
        let { name, value } = event.target;
        const { venda } = this.state;
        const { itens } = venda;

        const nameSplit = name.split('-');
        name = nameSplit[4];
        const id = nameSplit[1];
        const loteId = nameSplit[3];
        const index = itens.findIndex(item => item.id == id);
        const loteIndex = itens[index].lotes.findIndex(lote => lote.id == loteId);

        itens[index].lotes[loteIndex][name] = value;

        await this.setState({ venda });
        this.verificarLoteNovo(index);
    }

    getItemNextId = () => {
        const { venda: { itens } } = this.state;
        let ids = itens.map(item => parseInt(item.id));
        ids.sort((a, b) => a - b);
        ids.reverse();
        return (ids[0] || 0) + 1;
    }

    getLoteNextId = () => {
        const { venda: { itens } } = this.state;
        let ids = [];
        itens.forEach(item => {
            ids = [...ids, ...item.lotes.map(item => parseInt(item.id))]
        });
        ids.sort((a, b) => a - b);
        ids.reverse();
        return parseInt(ids[0] || 0) + 1;
    }

    verificarLoteNovo = (itemIndex) => {
        const { venda } = this.state;
        const { itens, tipo } = venda;
        if (![vendaTipoEnum.PEDIDO, vendaTipoEnum.ATENDIMENTO].includes(tipo)) {
            let novo = [];
            itens[itemIndex].lotes.forEach(item => {
                if (!item.quantidade && !item.lote) {
                    novo.push(item.id);
                }
            })
    
            if (novo.length === 0) {
                this.addLote(itens[itemIndex]);
            } else if (novo.length > 1) {
                const novoRemove = novo.pop();
                const remove = itens[itemIndex].lotes.findIndex(lote => lote.id == novoRemove);
                if (remove !== -1) {
                    itens[itemIndex].lotes.splice(remove, 1);
                }
                this.setState({ venda });
            }
        }
    }

    addLote = (item) => {
        const { venda } = this.state;
        venda.itens = venda.itens.map(itemParam => {
            if (item.id == itemParam.id) {
                itemParam.lotes.push({
                    id: this.getLoteNextId(),
                    lote: null,
                    quantidade: null,
                });
            }
            return itemParam;
        });
        this.setState({ venda })
    }

    addItem = () => {
        const { venda } = this.state;
        const { tipo } = venda;
        const lotes = [];
        if (!vendaTipoEnum.isPedido(tipo)) {
            lotes.push({
                id: this.getLoteNextId(),
                lote: null,
                quantidade: null,
            });
        }

        venda.itens.push({
            id: this.getItemNextId(),
            produto: {},
            quantidade: null,
            lotes: lotes,
        });
        this.setState({ venda });
    }

    getNextNumero = (tipo = null) => {
        const { nexts = {} } = this.props;
        if (tipo === null) {
            tipo = this.state.venda.tipo;
        }
        const enumName = vendaTipoEnum.properties[tipo].name;
        return nexts[`next${enumName.toLowerCase().firstToUpperCase()}`];
    }

    async componentDidMount() {
        const { venda: prop } = this.props;
        if (prop) {
            let {
                numero,
                tipo,
                status,
                cliente,
                dtRetira,
                itens = [],
            } = prop;

            const venda = {
                numero,
                tipo,
                status,
                cliente,
                dtRetira,
                itens,
            };
            await this.setState({
                venda,
            });

            itens.forEach((item, index) => this.verificarLoteNovo(index));
        }
        const usuario = load('CENTROPLAST_INTRANET_USUARIO');
        this.setState({ usuario });
    }

    renderCustomInput = ({ defaultValueName, ...params }, baseValues = null) => {
        if (!baseValues) {
            let { venda } = this.props;
            if (!venda) {
                ({ venda } = this.state);
            }
            baseValues = venda;
        }
        let { id, name, type } = params;
        if (!name && id) {
            name = id;
        }

        if (!type) {
            type = 'number';
            params.type = type;
        }
        if (type === 'number') {
            if (!params.min) {
                params.min = 0;
            }
        }
        if (!params.onChange) {
            params.onChange = this.saveState;
        }

        defaultValueName = defaultValueName || name;
        if (params.value === undefined && defaultValueName && baseValues[defaultValueName]) {
            let value = baseValues[defaultValueName];
            if (typeof value === 'object') {
                value = value.codigo || value.id || value;
            }
            
            if (type === 'datetime-local') {
                value = value.replace(' ', 'T');
            }
            if (type === 'number') {
                const numeric = (params.step < 1 ? parseFloat : parseInt)(value);
                value = numeric > 0 ? numeric : '';
            }
            params.defaultValue = value;
        }

        return (
            <CustomInput {...params} />
        )
    }

    renderItensCustomInput = ({ item, ...params }) => {
        params.defaultValueName = params.name.split('-')[2];
        params.onChange = this.saveStateItens;
        return this.renderCustomInput(params, item);
    }

    renderSelectReadonly = ({ label, name, value, options, enumValues, renderFunction, ...params }, condition = false) => {
        const { add = true } = this.props;
        if (options === undefined) {
            options = enumValues ? enumValues.getSelectOptions() : [];
        }
        if (enumValues && parseInt(value)) {
            const property = enumValues.properties[parseInt(value)];
            if (property) {
                value = property.text;
            }
        }

        renderFunction = renderFunction || this.renderCustomInput;

        if (add || condition) {
            return renderFunction({
                ...params,
                label,
                name,
                type: "select",
                options,
            });
        }
        if (value) {
            return renderFunction({
                ...params,
                className: "w-100",
                label,
                name,
                value,
                type: "text",
                readOnly: true,
            });
        }
        return null;
    }

    render() {
        const {
            add = true,
            venda = {},
            produtos,
            show,
            onHide,
        } = this.props;

        const {
            searchCliente,
            addCliente,
            escolherCidades,
            confirmarFinalizar,
            confirmarExcluir,
            confirmarExcluirLotes,
            confirmarVoltar,
            error,
            usuario = {},
            show: stateShow,
            venda: { numero, tipo, cliente, itens = [], newNumero, newTipo, newStatus },
        } = this.state;

        const { status } = venda;

        let tipos = { ...vendaTipoEnum.properties };
        if (confirmarFinalizar || !usuario.administrador) {
            delete tipos[vendaTipoEnum.PEDIDO];
        }
        
        let footerTipo = <IconButton text="Fechar" type="dark" icon={faTimes} onClick={onHide} />;
        if (confirmarFinalizar) {
            footerTipo = <IconButton text="Voltar" type="dark" icon={faChevronLeft} onClick={() => this.setState({ confirmarFinalizar: false })} />;
            delete tipos[vendaTipoEnum.ATENDIMENTO];
        }
        const nextNewNumero = newTipo ? this.getNextNumero(newTipo) : '';

        const readOnly = [vendaStatusEnum.ENTREGUE].includes(status);
        const atendimento = !readOnly && tipo === vendaTipoEnum.ATENDIMENTO;
        const readOnlyProduto = readOnly || (atendimento && !add)

        return (<>
            {((add && !tipo) || (confirmarFinalizar && !newTipo)) && (
                <Modal
                    show={show && (!tipo || (confirmarFinalizar && !newTipo))}
                    dialogClassName="modal-xl"
                    onHide={onHide} >
                    <Modal.Header>
                        <Modal.Title>Escolha o tipo da venda</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="justify-content-center">
                            <Col md={8}>
                                <Row className="my-3 justify-content-center">
                                    <ReactTooltip type="dark" effect="solid" multiline={true} />
                                    {Object.values(tipos).map((item, index) => {
                                        let tip = item.description;
                                        if (confirmarFinalizar) {
                                            tip = tip.split('<br />').shift();
                                        }
                                        return (
                                            <Col key={index} md={3}>
                                                <IconButton
                                                    text={item.text}
                                                    icon={item.icon}
                                                    className="venda-tipo-btn"
                                                    column={true}
                                                    data-tip={tip}
                                                    onClick={() => {
                                                        const { venda } = this.state;
                                                        venda[confirmarFinalizar ? 'newTipo' : 'tipo'] = item.value;
                                                        this.setState({ venda })
                                                    }} />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {footerTipo}
                    </Modal.Footer>
                </Modal>
            )}
            {tipo && (
                <Modal
                    show={show && stateShow && !confirmarFinalizar && !confirmarExcluir && confirmarExcluirLotes === null && !confirmarVoltar}
                    dialogClassName="modal-xl"
                    onShow={() => {
                        if (this.clienteRef) {
                            this.clienteRef.focus();
                        }
                    }}
                    onHide={onHide} >
                    <Modal.Header>
                        <Modal.Title>
                            {add
                                ? `Adicionar ${vendaTipoEnum.getText(tipo)}`
                                : `${tipo ? vendaTipoEnum.getText(tipo) : 'Venda'} ${venda.numero}${cliente && cliente.nome ? ` | ${cliente.nome}` : ''}`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-4 pr-md-4">
                                {add && (
                                    <Row>
                                        <Col>
                                            {this.renderCustomInput({
                                                label: "Número",
                                                name: "numero",
                                                defaultValue: this.getNextNumero(),
                                            })}
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col>
                                        {(() => {
                                            const params = {
                                                label: "Código do cliente",
                                                name: "cliente",
                                                type: "search",
                                                value: '',
                                                readOnly,
                                                customRef: clienteRef => this.clienteRef = clienteRef,
                                                onChange: (event) => {
                                                    const { target: { value } } = event;
                                                    const { venda } = this.state;
                                                    if (!venda.cliente) {
                                                        venda.cliente = {};
                                                    }
                                                    venda.cliente = value;
                                                    this.setState({ venda });
                                                },
                                                searchAction: () => this.setState({ show: false, searchCliente: true })
                                            };
                                            if (cliente) {
                                                params.value = cliente.codigo !== undefined ? cliente.codigo : cliente;
                                            }
                                            return this.renderCustomInput(params)
                                        })()}
                                    </Col>
                                </Row>
                                {tipo == vendaTipoEnum.PEDIDO && (<>
                                    <Row>
                                        <Col>
                                            {this.renderCustomInput({
                                                label: "Data para retirar",
                                                name: "dtRetira",
                                                type: "datetime-local",
                                                readOnly,
                                            })}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {this.renderCustomInput({
                                                label: "Observações",
                                                name: "observacoes",
                                                type: "textarea",
                                                readOnly,
                                            })}
                                        </Col>
                                    </Row>
                                </>)}
                            </div>
                            <div className="col-md-8 pl-md-4">
                                {false && (
                                    <div className="row mb-1 text-center">
                                        <div className="col-md text-left">Itens</div>
                                        {itens.length > 0 && (<>
                                            <div className="col-md-2">Quant.</div>
                                            <div className="col-md-2">Vol.</div>
                                        </>)}
                                    </div>
                                )}
                                <Container>
                                    <Row>
                                        {itens.map((item, index) => {
                                            return (
                                                <Col key={index} md={6} className="px-2">
                                                    <VendaItem {...{
                                                        add, tipo, status, item, produtos, readOnly,
                                                        onChange: this.saveStateItens,
                                                        onChangeLotes: this.saveStateItensLotes,
                                                        setLotes: (lotes) => {
                                                            const { venda } = this.state;
                                                            venda.itens[index].lotes = lotes;
                                                            this.setState({ venda });
                                                        },
                                                        onRemove: () => {
                                                            const { venda } = this.state;
                                                            venda.itens.splice(index, 1);
                                                            this.setState({ venda });
                                                        }
                                                    }} />
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                    {!readOnlyProduto && (
                                        <Row>
                                            <Col className="px-2">
                                                {!readOnly && <IconButton text="Adicionar item" icon={faPlus} onClick={this.addItem} />}
                                            </Col>
                                        </Row>
                                    )}
                                </Container>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {status == vendaStatusEnum.PREPARADA && <IconButton text="Realizar venda" icon={faCheck} onClick={this.finalizar} />}
                        {/* {status == vendaStatusEnum.CONFIRMADA && vendaTipoEnum.isVenda(tipo) && <IconButton text="Venda entregue" icon={faCheck} onClick={this.entregar} />} */}
                        {status == vendaStatusEnum.CONFIRMADA && vendaTipoEnum.isPedido(tipo) && <IconButton text="Venda preparada" icon={faCheck} onClick={this.preparar} />}
                        {status != vendaStatusEnum.ENTREGUE && <IconButton text="Salvar" icon={faSave} onClick={this.save} />}
                        {!add && <IconButton type="danger" text="Excluir" icon={faTrash} onClick={this.delete} />}
                        <IconButton text="Fechar" type="dark" icon={faTimes} onClick={onHide} />
                    </Modal.Footer>
                </Modal>
            )}

            {VendaModal.renderModal({
                text: "O cliente já retirou a mercadoria na expedição?",
                show: confirmarFinalizar && newTipo && !newStatus,
                onSuccess: () => {
                    const { venda } = this.state;
                    venda.newStatus = 2;
                    this.setState({ venda });
                },
                onHide: () => {
                    const { venda } = this.state;
                    venda.newStatus = 1;
                    this.setState({ venda });
                },
            })}

            {VendaModal.renderModal({
                text: (
                    <CustomInput
                        className="text-center"
                        formGroup={false}
                        label="Informe o número da venda"
                        type="number"
                        defaultValue={nextNewNumero}
                        onChange={event => this.setState({ newNumero: event.target.value })} />
                ),
                footer: (<>
                    <IconButton text="Finalizar" icon={faCheck} onClick={async() => {
                        const { venda, newNumero } = this.state;
                        venda.newNumero = newNumero || nextNewNumero;
                        await this.setState({ venda });
                        this.finalizar();
                    }} />
                    <IconButton text="Voltar" type="dark" icon={faChevronLeft} onClick={() => () => {
                        const { venda } = this.state;
                        venda.newNumero = null;
                        venda.newTipo = null;
                        this.setState({ venda });
                    }} />
                </>),
                show: confirmarFinalizar && newTipo && newStatus && !newNumero
            })}

            {VendaModal.renderModal({
                text: `Deseja realmente excluir ${tipo ? vendaTipoEnum.getTextArtigo(tipo) : 'a venda'} ${venda.numero || numero}?`,
                show: confirmarExcluir !== false,
                onSuccess: this.delete,
                onHide: () => this.setState({ confirmarExcluir: false }),
            })}

            {VendaModal.renderModal({
                text: `Deseja voltar os produtos ao estoque?`,
                show: confirmarExcluirLotes !== null,
                onSuccess: async() => {
                    await this.setState({ confirmarExcluir: true, confirmarExcluirLotes: true });
                    this.delete()
                },
                onCancel: async() => {
                    await this.setState({ confirmarExcluir: true, confirmarExcluirLotes: false })
                    this.delete();
                },
                onHide: () => this.setState({ confirmarExcluirLotes: null }),
            })}

            <ClienteSearchModal
                defaultSearch={cliente}
                show={searchCliente}
                onHide={() => this.setState({ show: true, searchCliente: false })}
                onSuccess={newCliente => {
                    const { venda } = this.state;
                    venda.cliente = newCliente;
                    this.setState({ venda });
                }} />

            <ClienteAddModal
                cliente={cliente}
                show={addCliente}
                onHide={(success = false) => {
                    const state = { addCliente: false };
                    if (!success) {
                        state.show = true;
                    }
                    this.setState(state)
                }}
                onSuccess={newCliente => {
                    const { venda } = this.state;
                    const { cliente } = venda;
                    const codigo = typeof cliente === 'object' ? cliente.codigo : cliente;

                    venda.cliente = { ...newCliente, codigo };
                    this.setState({ venda });
                    this.save();
                }} />

            <CidadeChooseModal
                cidades={escolherCidades}
                show={escolherCidades !== null}
                onHide={async(success = true) => {
                    const state = { show: true, escolherCidades: null };
                    if (!success) {
                        state.show = false;
                        state.addCliente = true;
                    }
                    this.setState(state)
                }}
                onSuccess={async(cidade) => {
                    const { venda } = this.state;
                    venda.cliente.cidade = cidade.codigo;
                    this.setState({ venda });
                }} />

            {error && (
                <Alert message={error} onDismiss={() => this.setState({ error: null })} />
            )}
        </>)
    }
}