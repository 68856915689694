import React, { Component } from 'react';

import '../../assets/scss/components/usuario.scss';
import { faCheck, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import Modal from 'react-bootstrap/Modal';
import CustomInput from '../util/CustomInput';
import Alert from '../util/Alert';
import api from '../../services/api';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import estoqueMovimentacaoTipoEnum from '../../util/enum/estoqueMovimentacaoTipoEnum';

export default class EstoqueModal extends Component {
    state = {
        error: null,
        estoque: [],
        produto: null,
        tipo: estoqueMovimentacaoTipoEnum.ENTRADA_MANUAL.toString(),
        lote: null,
        atual: 0,
        quantidade: 0,
        novo: 0,
    }

    hideSuccess = async(boxes) => {
        const { onHide, onSuccess } = this.props;
        await onHide();
        await onSuccess(boxes);
    }

    save = async() => {
        try {
            const { produto, tipo, lote, quantidade, novo } = this.state;

            if (novo < 0) {
                this.setState({ error: "O novo estoque não pode ser negativo!" });
                return null;
            }

            const result = (await api.post('/estoque', { tipo, produto, lote, quantidade })).data;
            const { error, produto: produtoExiste } = result;
            switch (error) {
                case 0:
                    const { produtos } = this.props;
                    this.hideSuccess({
                        produto: produtos.find(({ value }) => value == produto).codigo,
                        lote,
                        quantidade: novo,
                    });
                    break;

                case 102:
                    if (produtoExiste !== undefined) {
                        this.setState({ error: `O lote ${lote} pertence ao produto ${produtoExiste}` });
                        break;
                    }

                default:
                    console.log(result);
                    this.setState({ error: "Aconteceu um erro" })
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }

    saveState = async(event) => {
        const { name, value } = event.target;
        await this.setState({ [name]: value });
    }

    setNovo = () => {
        let { tipo, atual, quantidade } = this.state;
        atual = parseInt(atual || 0);
        quantidade = parseInt(quantidade || 0);

        if (tipo == estoqueMovimentacaoTipoEnum.SAIDA_MANUAL) {
            quantidade *= -1;
        }

        const novo = atual + quantidade;
        this.setState({ novo })
    }

    searchEstoque = async() => {
        let { produto } = this.state;
        if (!produto) {
            ({ produto } = this.props);
            if (produto) {
                await this.setState({ produto });
            }
        }
        
        ({ produto } = this.state);
        if (produto) {
            const estoque = (await api.get(`/estoque?produto=${produto}&sem_saldo=true`)).data.lista;
            this.setState({ estoque });
        }
    }

    renderCustomInput = ({ defaultValueName, ...params }, baseValues = null) => {
        if (!baseValues) {
            baseValues = this.state;
        }
        let { id, name } = params;
        if (!name && id) {
            name = id;
        }

        if (!params.onChange) {
            params.onChange = this.saveState;
        }

        defaultValueName = defaultValueName || name;
        if (params.value === undefined && defaultValueName && baseValues[defaultValueName]) {
            let value = baseValues[defaultValueName];
            if (typeof value === 'object') {
                value = value.codigo || value.id || value;
            }
            
            params.defaultValue = value;
        }

        return (
            <CustomInput {...params} />
        )
    }

    render() {
        const { show, produtos, onHide } = this.props;
        const { produto, estoque, tipo, atual, novo, error } = this.state;
        const produtoObject = produtos.find(item => item.value == produto) || {};

        return (<>
            <Modal
                show={show}
                dialogClassName="modal-md"
                onShow={this.searchEstoque}
                onHide={() => onHide()} >
                <Modal.Header>
                    <Modal.Title>Movimentar estoque{produtoObject.codigo ? ` do produto ${produtoObject.codigo}` : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            {this.renderCustomInput({
                                label: "Produto",
                                name: "produto",
                                type: "select",
                                defaultValue: produto,
                                options: produtos,
                                onChange: async(event) => {
                                    await this.saveState(event);
                                    this.setState({ lote: null });
                                    this.searchEstoque();
                                    this.setNovo();
                                },
                            })}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.renderCustomInput({
                                label: "Tipo de movimentação",
                                name: "tipo",
                                type: "select",
                                defaultValue: tipo,
                                options: estoqueMovimentacaoTipoEnum.getSelectOptions(estoqueMovimentacaoTipoEnum.getPropsManual()),
                                onChange: async(event) => {
                                    await this.saveState(event);
                                    this.setNovo();
                                },
                            })}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.renderCustomInput({
                                label: "Lote",
                                name: "lote",
                                type: "creatable_select",
                                createLabel: 'lote',
                                onChange: async(event) => {
                                    const { target } = event;
                                    await this.saveState(event);

                                    const { estoque } = this.state;
                                    if (target.options[target.options.selectedIndex].getAttribute('data-create')) {
                                        estoque.push({ quantidade: 0, reserva: 0, lote: parseInt(target.value) });
                                        
                                        this.setState({ estoque })
                                    }
                                    const { quantidade: atual } = estoque.find(item => item.lote == target.value);

                                    await this.setState({ atual })
                                    this.setNovo();
                                },
                                options: estoque.map(item => ({ value: item.lote, label: `Lote ${item.lote}` })),
                            })}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            {this.renderCustomInput({
                                label: "Atual",
                                readOnly: true,
                                value: atual,
                            })}
                        </Col>
                        <Col md={4}>
                            {this.renderCustomInput({
                                label: "Quantidade",
                                name: "quantidade",
                                onChange: async(event) => {
                                    await this.saveState(event);
                                    this.setNovo();
                                },
                            })}
                        </Col>
                        <Col md={4}>
                            {this.renderCustomInput({
                                label: "Novo",
                                readOnly: true,
                                value: novo,
                            })}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton text="Salvar" icon={faSave} onClick={this.save} />
                    <IconButton text="Fechar" type="dark" icon={faTimes} onClick={() => onHide()} />
                </Modal.Footer>
            </Modal>

            {error && (
                <Alert message={error} onDismiss={() => this.setState({ error: null })} />
            )}
        </>)
    }
}