import React, { Component } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import '../../assets/scss/cliente.scss';
import ClienteItem from '../../components/cliente/cliente';
import IconButton from '../../components/util/IconButton';
import CustomInput from '../../components/util/CustomInput';
import { Col, Row } from 'react-bootstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ReactPaginate from 'react-paginate';
import api from '../../services/api';
import Loader from '../../components/loader';
import ClienteModal from '../../components/cliente/cliente_modal';
import ClienteAddModal from '../../components/cliente/cliente_add_modal';
import CidadeChooseModal from '../../components/cidade/cidade_choose_modal';
import Alert from '../../components/util/Alert';

export default class Cliente extends Component {
    state = {
        clientes: [],
        escolherCidades: null,
        info: {},
        pagina: 1,
        searching: false,
        adicionar: false,
        error: null,
        clienteAdicionar: {},
    }
    timeout = null;

    load = async() => {
        try {
            let { pagina, nome } = this.state;
            this.setState({ clientes: [], searching: true })

            nome = nome ? `&search=${nome}` : ''
            const result = (await api.get(`/cliente?pagina=${pagina}&info=true${nome}`)).data;
            const { lista: clientes, ...info } = result;

            this.setState({ clientes, info, searching: false })
        } catch (error) {
            console.log(error);
        }
    }

    reativar = async codigo => {
        try {
            const result = (await api.put(`/cliente`, { codigo, ativo: 1 })).data;

            if (result.error === 0) {
                return true;
            } else {
                this.setState({ error: "Aconteceu um erro" })
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    }
    
    delete = async codigo => {
        try {
            const result = (await api.delete(`/cliente/${codigo}`)).data;

            if (result.error === 0) {
                return true;
            } else {
                this.setState({ error: "Aconteceu um erro" })
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    }
    
    saveCliente = async cliente => {
        try {
            if (!cliente.codigo) {
                this.setState({ error: 'Informe um código' })
                return;
            }
            cliente.react = true;
            const result = (await api.post('/cliente', cliente)).data;
            const { error, warning, cidade } = result;
            console.log(result);

            if (error === 0) {
                this.setState({ adicionar: null, clienteAdicionar: {} });
                setTimeout(() => this.setState({ adicionar: false }), 500);
                this.load();
                return true;
            } else {
                switch (warning) {
                    case 102:
                        this.setState({ adicionar: null, clienteAdicionar: cliente, escolherCidades: cidade });
                        break;
                
                    default:
                        switch (error) {
                            default:
                                this.setState({ error: "Aconteceu um erro" })
                                break;
                        }
                        break;
                }
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    }

    async componentDidMount() {
        this.load();
    }

    render() {
        const { error, adicionar, escolherCidades, pagina, clientes, info: { count, nextCodigo }, searching } = this.state;

        const paginationProps = {};
        ['page', 'break', 'previous', 'next'].forEach(item => {
            paginationProps[`${item}ClassName`] = "pagination-item";
            paginationProps[`${item}LinkClassName`] = "pagination-item-link";
        })

        return (<>
            <Row className="align-items-end">
                <Col md="auto" className="cliente-header">
                    <IconButton className="cliente-header-btn" icon={faPlus} text="Adicionar" onClick={() => this.setState({ adicionar: true })} />
                </Col>
                <Col>
                    <CustomInput
                        formGroup={false}
                        label="Filtre os clientes"
                        onChange={(e) => {
                            if (this.timeout !== null) {
                                clearTimeout(this.timeout);
                                this.timeout = null
                            } else {
                                this.setState({ clientes: [], searching: true, pagina: 1 })
                            }

                            this.timeout = setTimeout(async() => {
                                const { value: nome } = e.target;
                                await this.setState({ nome });
                                await this.load();
                                this.timeout = null;
                            }, 1500);
                        }} />
                </Col>
            </Row>
            
            {!searching && (<>
                {clientes.length > 0 && (
                    <TransitionGroup className="cliente-clientes">
                        {clientes.map((cliente, index) => {
                            return (
                                <CSSTransition
                                    key={index.toString()}
                                    classNames="cliente-clientes-transition"
                                    timeout={500}
                                >
                                    <div className={`cliente-clientes-item ${!cliente.ativo ? 'cliente-clientes-inativo' : ''}`}>
                                        <ClienteItem
                                            cliente={cliente}
                                            nextCodigo={nextCodigo}
                                            reativar={!cliente.ativo ? this.reativar : undefined}
                                            excluir={cliente.ativo ? this.delete : undefined}
                                            load={this.load} />
                                    </div>
                                </CSSTransition>
                            )
                        })}
                    </TransitionGroup>
                )}
                {clientes.length === 0 && (
                    <Row>
                        <Col className="cliente-clientes-empty">Sem registros</Col>
                    </Row>
                )}
            </>)}
            {searching && (
                <div className="mt-4">
                    <Loader />
                </div>
            )}

            {count > 0 && (
                <ReactPaginate
                    pageCount={Math.ceil(count / 10)}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={3}
                    forcePage={pagina - 1}
                    onPageChange={async({ selected: pagina }) => {
                        pagina++;
                        await this.setState({ pagina })
                        await this.load();
                    }}
                    onPageActive={() => this.load()}
                    previousLabel="Anterior"
                    nextLabel="Próxima"
                    containerClassName={`pagination justify-content-center${searching ? ' d-none' : ''}`}
                    activeClassName="active"
                    { ...paginationProps } />
            )}

            {adicionar !== false && (
                <ClienteAddModal
                    show={adicionar === true}
                    nextCodigo={nextCodigo}
                    onSuccess={cliente => this.saveCliente(cliente)}
                    onHide={() => {
                        this.setState({ adicionar: null });
                        setTimeout(() => this.setState({ adicionar: false }), 500);
                    }} />
            )}

            <CidadeChooseModal
                cidades={escolherCidades}
                show={escolherCidades !== null}
                onHide={() => {
                    const state = {};
                    state.adicionar = true;
                    state.escolherCidades = null;
                    this.setState(state);
                }}
                onSuccess={async(cidade) => {
                    const { clienteAdicionar } = this.state;
                    clienteAdicionar.cidade = cidade.codigo;
                    this.saveCliente(clienteAdicionar);
                }} />

            {error && (
                <Alert message={error} onDismiss={() => this.setState({ error: null })} />
            )}
        </>);
    }
}