const white = "#FFF";
const black = "#000";
const cinza = "#DDD";
const cinzaEscuro = "#999";

const colors = {
    white,
    whiteText: black,
    black,
    cinza,
    cinzaEscuro,
    
    principal: "#0f2487",
    principalDarken: "#0a1859",
    success: "#28a745",
    danger: "#dc3545",
};

export default colors;