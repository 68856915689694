import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';

import '../../assets/scss/components/venda.scss';
import { faChevronLeft, faDolly, faSearch } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import api from '../../services/api';
import VendaModal from './venda_modal';
import vendaStatusEnum from '../../util/enum/vendaStatusEnum';
import Alert from '../util/Alert';
import vendaTipoEnum from '../../util/enum/vendaTipoEnum';

export default class Venda extends Component {
    state = {
        visualizar: false,
    };

    alterStatus = async(params) => {
        try {
            const { venda: { numero, tipo } } = this.props;
            
            const result = (await api.put(`/venda`, { numero, tipo, react: true, ...params })).data;
            const { error } = result;
            
            switch (error) {
                case 0:
                    this.props.loadVendas(numero);
                    break;
            
                default:
                    this.setState({ error: "Aconteceu um erro" })
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    voltarExpedicao = async() => {
        this.alterStatus({ voltarExpedicao: true });
    }
    
    renderText = () => {
        const { venda } = this.props;
        const { numero, tipo, cliente = {}, dtRetira, observacoes } = venda;
        const { nome: clienteNome } = cliente;
        const text = tipo ? vendaTipoEnum.getText(tipo) : 'Venda';
        let dtRetiraJSX = null;
        if (dtRetira) {
            const momentDtRetira = moment(dtRetira);
            dtRetiraJSX = <div className="venda-text">{momentDtRetira > moment() ? 'Retira' : 'Retirada'} {momentDtRetira.calendar({ sameElse: '[em] L [às] LT' }).toLowerCase()}</div>;
        }
        
        return (<>
            <div className="venda-title">{text} {numero}</div>
            {clienteNome && <div className="venda-text">{clienteNome}</div>}
            {dtRetiraJSX}
            {observacoes && <div className="venda-text">OBS: {observacoes}</div>}
        </>);
    }

    renderBottom = () => {
        const { venda, produtos, nexts, loadVendas } = this.props;
        const { status } = venda;
        const { visualizar } = this.state;

        return (<>
            <IconButton text="Visualizar" icon={faSearch} className="venda-btn" onClick={() => this.setState({ visualizar: true })} />
            {status === vendaStatusEnum.PREPARADA && <IconButton text="Voltar à expedição" icon={faChevronLeft} className="venda-btn" onClick={this.voltarExpedicao} />}
            {visualizar !== false && (
                <VendaModal
                    add={false}
                    show={visualizar === true}
                    venda={venda}
                    produtos={produtos}
                    nexts={nexts}
                    onSuccess={loadVendas}
                    onHide={() => {
                        this.setState({ visualizar: null });
                        setTimeout(() => this.setState({ visualizar: false }), 250);
                    }} />
            )}
        </>);
    }

    render() {
        const { error } = this.state;

        return (
            <div>
                {this.renderText(false)}
                {this.renderBottom()}
                {error && (
                    <Alert message={error} onDismiss={() => this.setState({ error: null })} />
                )}
            </div>
        )
    }
}