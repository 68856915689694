import React, { Component } from 'react';
import cookie from 'react-cookies';
import api from '../services/api';
import global from '../util/global';
import logo from '../assets/images/logo.png';
import '../assets/scss/block.scss';

export default class Block extends Component {
    async componentDidMount() {
        const { usuario } = this.props;

        if (usuario.suporte) {
            const block = cookie.load('CENTROPLAST_INTRANET_BLOCK') === '1';
            const result = (await (block ? api.delete : api.post)('/block')).data;
            if (result.error === 0) {
                if (block) {
                    cookie.remove('CENTROPLAST_INTRANET_BLOCK', { path: '/' });
                } else {
                    cookie.save('CENTROPLAST_INTRANET_BLOCK', '1', { path: '/' });
                }
            }
            window.location.href = global.BASE_URL;
        }
    }

    render() {
        const { usuario } = this.props;

        if (usuario.suporte) {
            return null;
        }
        return (
            <div className="block-section">
                <div className="block-area">
                    <img className="block-logo" src={logo} />
                    <h1 className="block-title">O sistema está bloqueado temporariamente para atualização</h1>
                </div>
            </div>
        )
    }
}