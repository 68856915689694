import React, { Component } from 'react';

import '../../assets/scss/components/usuario.scss';
import { faCheck, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import Modal from 'react-bootstrap/Modal';
import CustomInput from '../util/CustomInput';
import Alert from '../util/Alert';
import api from '../../services/api';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class UsuarioModal extends Component {
    state = {
        error: null,
        show: true,
        confirmarExcluir: false,
        usuario: {
            id: null,
            codigo: null,
            usuario: null,
            usuarioGrupo: {},
        },
    }

    static renderModal = ({ show, size, text, footer, onHide, onSuccess }) => {
        return (
            <Modal dialogClassName={size || 'modal-md'} show={show} onHide={onHide}>
                <Modal.Body className="usuario-modal-title">
                    {text}
                </Modal.Body>
                <Modal.Footer className="usuario-modal-confirm-footer">
                    {footer || (<>
                        <IconButton text="Sim" icon={faCheck} onClick={onSuccess} />
                        <IconButton text="Não" icon={faTimes} onClick={onHide} />
                    </>)}
                </Modal.Footer>
            </Modal>
        );
    }

    hideSuccess = async (usuario, trocarSenha) => {
        const { onHide, onSuccess } = this.props;
        await onHide(trocarSenha);
        await onSuccess(usuario, trocarSenha);
    }

    delete = async() => {
        const { confirmarExcluir } = this.state;
        if (!confirmarExcluir) {
            this.setState({ confirmarExcluir: true });
            return;
        }
        this.setState({ confirmarExcluir: false, show: false });
        
        const { codigo } = this.props.usuario;

        try {
            const result = (await api.delete(`/usuario/${codigo}`)).data;
            console.log(result);

            if (result.error === 0) {
                this.hideSuccess(codigo);
            }
        } catch (error) {
            console.log(error);
        }
    }

    save = async() => {
        let { add = true, nextUsuario, getUsuarioResult, readOnly } = this.props;
        const { senhaRepetir, ...usuario } = this.state.usuario;
        const { senhaAtual, senha } = usuario;
        if (!usuario.codigo) {
            usuario.codigo = nextUsuario;
        }

        let trocarSenha = false;
        if (readOnly && senha && !senhaAtual) {
            this.setState({ error: "Você deve informar a sua senha atual" });
            return;
        }
        if (senha) {
            if (senhaRepetir) {
                if (senha !== senhaRepetir) {
                    this.setState({ error: "As senhas devem ser idênticas" });
                    return;
                } else {
                    trocarSenha = true;
                }
            } else {
                this.setState({ error: "Você deve repetir a senha" });
                return;
            }
        }
        if (!trocarSenha) {
            delete usuario.senha;
        }

        try {
            if (!readOnly && !add) {
                usuario.adm = true;
            }
            const result = (await (add ? api.post : api.put)('/usuario', usuario)).data;

            const { error, warning, usuario: usuarioResult } = result;

            if (error === 0) {
                await this.setState({ show: false });
                getUsuarioResult = getUsuarioResult || (async() => (await api.get(`/usuario/${usuario.codigo}`)).data);
                setTimeout(async() => {
                    const param = usuarioResult || (await getUsuarioResult());
                    this.hideSuccess(param, trocarSenha);
                }, 250);
            } else {
                if (add) {
                    switch (error) {
                        case 2:
                            this.setState({ error: "Você deve informar um grupo de usuário" })
                            break;

                        case 3:
                            this.setState({ error: "Você deve informar uma senha" })
                            break;

                        default:
                            this.setState({ error: "Aconteceu um erro" })
                            break;
                    }
                } else {
                    switch (error) {
                        case 3:
                            this.setState({ error: "A senha atual é inválida" })
                            break;

                        default:
                            this.setState({ error: "Aconteceu um erro" })
                            break;
                    }
                }
                this.setState({ show: true });
            }
        } catch (error) {
            console.log(error);
        }
    }

    saveState = (event) => {
        const { name, value } = event.target;

        const { usuario } = this.state;
        usuario[name] = value;

        this.setState({ usuario });
    }

    componentDidMount() {
        const { usuario: prop } = this.props;
        if (prop) {
            let {
                id,
                codigo,
                usuario,
                usuarioGrupo,
            } = prop;
            
            this.setState({
                usuario: {
                    id,
                    codigo,
                    usuario,
                    usuarioGrupo,
                },
            });
        }
    }

    renderCustomInput = ({ defaultValueName, ...params }, baseValues = null) => {
        if (!baseValues) {
            let { usuario } = this.props;
            if (!usuario) {
                ({ usuario } = this.state);
            }
            baseValues = usuario;
        }
        let { id, name, type } = params;
        if (!name && id) {
            name = id;
        }

        if (!params.onChange) {
            params.onChange = this.saveState;
        }

        defaultValueName = defaultValueName || name;
        if (params.value === undefined && defaultValueName && baseValues[defaultValueName]) {
            let value = baseValues[defaultValueName];
            if (typeof value === 'object') {
                value = value.codigo || value.id || value;
            }
            
            params.defaultValue = value;
        }

        return (
            <CustomInput {...params} />
        )
    }

    render() {
        const {
            add = true,
            usuario = {},
            grupos,
            nextUsuario,
            show,
            onHide,
            readOnly
        } = this.props;

        const {
            confirmarExcluir,
            error,
            show: stateShow,
            usuario: { codigo },
        } = this.state;

        const { usuarioGrupo = {} } = usuario;
        const { nome: usuarioGrupoNome } = usuarioGrupo;

        return (<>
            <Modal
                show={show && stateShow && !confirmarExcluir}
                dialogClassName="modal-md"
                onShow={() => this.usuarioRef.focus()}
                onHide={() => onHide()} >
                <Modal.Header>
                    <Modal.Title>
                        {add
                            ? 'Adicionar usuário'
                            : readOnly ? 'Meu usuário' : `Usuário ${usuario.codigo}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            {this.renderCustomInput({
                                label: "Nome",
                                name: "usuario",
                                customRef: usuarioRef => this.usuarioRef = usuarioRef,
                            })}
                        </Col>
                    </Row>
                    {readOnly && (
                        <Row>
                            <Col>
                                {this.renderCustomInput({
                                    label: "Senha atual",
                                    name: "senhaAtual",
                                    type: "password",
                                })}
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col>
                            {this.renderCustomInput({
                                label: add ? "Senha" : "Nova senha",
                                name: "senha",
                                type: "password",
                            })}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.renderCustomInput({
                                label: "Repita a senha",
                                name: "senhaRepetir",
                                type: "password",
                            })}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {readOnly && this.renderCustomInput({
                                label: "Grupo de usuário",
                                readOnly: true,
                                value: usuarioGrupoNome,
                            })}
                            {!readOnly && this.renderCustomInput({
                                label: "Grupo de usuário",
                                name: "usuarioGrupo",
                                type: 'select',
                                options: grupos,
                            })}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton text="Salvar" icon={faSave} onClick={this.save} />
                    {!add && !readOnly && <IconButton type="danger" text="Excluir" icon={faTrash} onClick={this.delete} />}
                    <IconButton text="Fechar" type="dark" icon={faTimes} onClick={() => onHide()} />
                </Modal.Footer>
            </Modal>

            {UsuarioModal.renderModal({
                text: `Deseja realmente excluir ${usuario.usuario}?`,
                show: confirmarExcluir !== false,
                onSuccess: this.delete,
                onHide: () => this.setState({ confirmarExcluir: false }),
            })}

            {error && (
                <Alert message={error} onDismiss={() => this.setState({ error: null })} />
            )}
        </>)
    }
}