import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { load } from 'react-cookies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { cliente, estoque, expedicao, pedido, producao, produto, tarefa, venda } from '../assets/images/menu';
import '../assets/scss/inicio.scss';
import { faArrowRight, faBell, faIdBadge, faLock, faLockOpen, faPaperPlane, faSignOutAlt, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../components/util/IconButton';

class Inicio extends Component {
    renderAtalhos(usuario) {
        const atalhos = [{
            text: 'Clientes',
            link: 'cliente',
            img: cliente,
            visible: usuario.escritorioVenda,
        }, {
            text: 'Pedidos pendentes',
            link: 'pedido',
            img: pedido,
            visible: usuario.administrador && usuario.escritorioVenda,
        }, {
            text: 'Expedição',
            link: 'expedicao',
            img: expedicao,
            visible: usuario.escritorioVenda,
        }, {
            text: 'Vendas',
            link: 'venda',
            img: venda,
            visible: usuario.escritorioVenda,
        }, {
            text: 'Produção',
            link: 'producao',
            img: producao,
        }, {
            text: 'Produtos',
            link: 'produto',
            img: produto,
        }, {
            text: 'Estoque',
            link: 'estoque',
            img: estoque,
        }, {
            text: 'Tarefas',
            link: 'tarefa',
            img: tarefa,
        }];

        return atalhos.map((atalho, index) => {
            const { text, link, img, visible = true } = atalho;

            if (visible) {
                return (
                    <div key={index.toString()} className="inicio-atalho">
                        <Link className="inicio-atalho-link" to={`/${link}`}>
                            <img src={img} className="inicio-atalho-imagem" />
                            <span className="inicio-atalho-title">{text}</span>
                            <FontAwesomeIcon icon={faArrowRight} className="inicio-atalho-icon" />
                        </Link>
                    </div>
                );
            }
            return null;
        });
    }

    renderLateral(usuario) {
        const block = load('CENTROPLAST_INTRANET_BLOCK') === '1';

        const atalhos = [{
            text: 'Meu usuário',
            link: 'meu_usuario',
            icon: faUser,
        }, {
            text: 'Usuários',
            link: 'usuario',
            icon: faUsers,
            adm: true,
        }, {
            text: 'Grupos de usuários',
            link: 'usuario/grupo',
            icon: faIdBadge,
            adm: true,
        }, {
            text: 'Enviar mensagem',
            link: 'usuario/mensagem',
            icon: faPaperPlane,
        }, {
            text: 'Notificações',
            link: 'notificacao',
            icon: faBell,
        }, {
            marginAuto: true,
        }, {
            text: block ? 'Desbloquear sistema' : 'Bloquear sistema',
            link: 'block',
            icon: block ? faLockOpen : faLock,
            suporte: true,
        }, {
            text: 'Sair',
            link: 'sair',
            icon: faSignOutAlt,
        }];

        let divider = false;
        return atalhos.map((atalho, index) => {
            if (atalho.adm && !usuario.administrador) {
                return null;
            }
            if (atalho.suporte && !usuario.suporte) {
                return null;
            }

            let { text, link, icon, marginAuto } = atalho;
            if (marginAuto === true) {
                divider = true;
                return null;
            }
            if (divider) {
                marginAuto = true;
                divider = false;
            }
            
            return (
                <Row key={index} className={marginAuto ? 'mt-auto' : ''}>
                    <Col>
                        <IconButton type="primary-transparent" className="inicio-lateral-link w-100" link={link} icon={icon} text={text} />
                    </Col>
                </Row>
            );
        });
    }

    render() {
        const usuario = load('CENTROPLAST_INTRANET_USUARIO');

        return (
            <Row className="flex-grow-1">
                <Col>
                    <div className="inicio-saudacao">
                        Olá, {usuario.nome}
                    </div>
                    <div className="inicio-atalhos">
                        {this.renderAtalhos(usuario)}
                    </div>
                </Col>
                {(usuario.administrador || usuario.enviarMensagem) && (
                    <Col md="auto" className="inicio-lateral">
                        {this.renderLateral(usuario)}
                    </Col>
                )}
            </Row>
        );
    }
}

export default Inicio;