import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { components } from 'react-select';
import CustomSelect from './CustomSelect.js';

import colors from '../../assets/colors.js'
import '../../assets/scss/components/custom-input.scss';
import { faEye, faEyeSlash, faQuestion, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

export default class CustomInput extends Component {
    state = {
        value: null,
        showPassword: false,
    };
    inputRef = null;

    static getSelectStyles(selectStyle = {}) {
        return {
            menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                ...(selectStyle.menu || {}),
            }),
            control: (provided) => ({
                ...provided,
                border: 0,
                borderBottom: `2px solid ${colors.principal}`,
                '&:hover': {
                    ...provided['&:hover'],
                    borderColor: colors.principalDarken,
                },
                boxShadow: 'none',
                ...(selectStyle.control || {}),
            }),
            option: (provided, state) => ({
                ...provided,
                transition: `background-color 0.1s ease-in-out`,
                backgroundColor: state.isSelected ? `${colors.principal} !important` : 'transparent',
                '&:hover': {
                    ...provided['&:hover'],
                    backgroundColor: "#00000015",
                },
                ...(selectStyle.option || {}),
            }),
            dropdownIndicator: (provided) => ({
                ...provided,
                color: colors.principal,
                ...(selectStyle.dropdownIndicator || {}),
            }),
        }
    }

    toggleShowPassword = () => {
        const { showPassword } = this.state;
        this.setState({ showPassword: !showPassword });
    }

    componentDidMount() {
        let { defaultValue, value } = this.props;
        if (!value) {
            value = defaultValue;
        }
        if (value) {
            this.setState({ value });
        }
    }

    render() {
        const { value } = this.state;
        const {
            customRef,
            label,
            id,
            type = 'text',
            className,
            selectStyle = {},
            options,
            onChange,
            onEnterPress,
            formGroup = true,
            searchAction,
            help,
            createLabel,
            ...props
        } = this.props;

        const onKeyPress = event => {
            if (event.which === 13) {
                if (typeof onEnterPress === 'function') {
                    onEnterPress();
                }
            }
        }

        let showLabel = true;
        let labelClassName = '';
        let input = null;
        switch (type) {
            case 'creatable_select':
            case 'select':
                labelClassName = 'cpl-form-label-select';
                const isLoading = options === null;
                const { isClearable, multiple } = this.props;

                input = (
                    <CustomSelect
                        className={className}
                        createLabel={createLabel}
                        creatable={type === 'creatable_select'}
                        name={props.name}
                        options={!isLoading ? options : []}
                        isMulti={multiple === true}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        onChange={onChange}
                        defaultValue={props.defaultValue}
                        styles={CustomInput.getSelectStyles(selectStyle)} />
                );
                break;

            case 'checkbox':
                showLabel = false;
                const checkProps = {};
                if (props.value !== undefined) {
                    checkProps.checked = props.value == '1';
                    checkProps.readOnly = true;
                } else {
                    checkProps.defaultChecked = props.defaultValue == '1' || props.defaultValue === true;
                }
                input = (
                    <Form.Check 
                        custom
                        type={type}
                        id={id}
                        label={label}
                        onChange={onChange}
                        disabled={props.readOnly === true}
                        onKeyPress={onKeyPress}
                        {...checkProps} />
                );
                break;
                
            default:
                labelClassName = (value || props.value) && ['date', 'datetime-local'].indexOf(type) === -1 ? ' cpl-form-label-withvalue' : '';
                if (props.readOnly === true) {
                    props.readOnly = 'readonly';
                    props.title = value;
                } else {
                    delete props.readOnly;
                }
                if (props.value && props.defaultValue) {
                    delete props.defaultValue;
                }

                const customOnChange = (event) => {
                    let { value, classList } = event.target;
                    const selection = this.inputRef.selectionStart;
                    if (typeof value === 'string' && !classList.contains('input-lowercase')) {
                        value = value.toUpperCase();
                    }
                    this.setState({ value });
                    
                    event.target.value = value;
                    if (onChange) {
                        onChange(event);
                    }
                    
                    if (this.inputRef.selectionStart) {
                        this.inputRef.selectionStart = selection;
                        this.inputRef.selectionEnd = selection;
                    }
                };

                switch (type) {
                    case 'textarea':
                        input = (
                            <textarea
                                ref={ref => this.inputRef = ref}
                                className="cpl-form-input"
                                onChange={customOnChange}
                                onKeyPress={onKeyPress}
                                {...props}
                            >
                                
                            </textarea>
                        )
                        break;

                    default:
                        let inputType = type;
                        const { showPassword } = this.state;
                        if (type === 'search' || (type === 'password' && showPassword) || (props.readOnly && ['date', 'datetime-local'].indexOf(inputType) !== -1)) {
                            inputType = 'text';
                        }
                        input = (
                            <input
                                ref={ref => {
                                    this.inputRef = ref
                                    if (customRef) {
                                        customRef(ref)
                                    }
                                }}
                                type={inputType}
                                className={`cpl-form-input ${className || ''}${type === 'password' ? ' input-lowercase' : ''}`}
                                onChange={customOnChange}
                                onKeyPress={onKeyPress}
                                { ...props } />
                        );

                        if (type === 'search' && !props.readOnly) {
                            input = (
                                <div className="cpl-form-input-search">
                                    {input}
                                    <button tabIndex={-1} className="cpl-form-input-searchbtn" onClick={searchAction}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </div>
                            )
                        }

                        if (type === 'password' && !props.readOnly) {
                            input = (
                                <div className="cpl-form-input-password">
                                    {input}
                                    <button tabIndex={-1} className="cpl-form-input-passwordbtn" onClick={this.toggleShowPassword}>
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                            )
                        }
                }
        }

        let content = input;
        if (!formGroup) {
            labelClassName += ' mb-0'
        }

        if (label && showLabel) {
            content = (
                <label className={`cpl-form-label ${labelClassName}`}>
                    <span>
                        {label}
                        {help && (<>
                            <ReactTooltip className="react-tooltip" effect="solid" multiline={true} />
                            <FontAwesomeIcon className="ml-1" icon={faQuestion} data-tip={help} />
                        </>)}
                    </span>
                    {content}
                </label>
            );
        }
        return (
            <div className={`cpl-form-group ${!formGroup ? 'mb-0' : ''}`}>
                {content}
            </div>
        );
    };
}