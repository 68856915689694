import React, { Component } from 'react';
import api from '../../services/api';

import '../../assets/scss/producao.scss';
import Loader from '../../components/loader';
import Of from '../../components/of/of';
import ofStatusEnum from '../../util/enum/ofStatusEnum';
import CustomInput from '../../components/util/CustomInput';
import produtoGrupoEnum from '../../util/enum/produtoGrupoEnum';

export default class ProducaoArquivo extends Component {
    state = {
        ofs: [],
        page: 1,
        searching: false,
        maquina: null,
        produto: null,
        maquinas: null,
        produtos: null,
    }

    filterOf = async state => {
        await this.setState({
            ...state,
            page: 1,
            ofs: [],
            searching: true,
        })
        await this.loadOf();
    }

    loadOf = async() => {
        try {
            let { page, numero, lote, maquina, produto, ofs } = this.state;
            this.setState({ searching: true })
            
            numero = numero ? `&numero=${numero}` : '';
            lote = lote ? `&lote=${lote}` : '';
            maquina = maquina ? `&maquina=${maquina}` : '';
            produto = produto ? `&produto=${produto}` : '';
            const result = (await api.get(`/of?status=${ofStatusEnum.BAIXADA}&itens=12&pagina=${page}&order_by=2${maquina}${produto}${numero}${lote}`)).data;
            if (result && result.length > 0) {
                ofs = [...ofs, ...result];
                
                document.addEventListener('scroll', this.trackScrolling);
            }
            this.setState({ ofs, searching: false })
        } catch (error) {
            console.log(error);
        }
    }
    
    trackScrolling = () => {
        const element = document.getElementsByClassName('producao-ofs')[0];
        if (element.getBoundingClientRect().bottom <= window.innerHeight) {
            this.setState({ page: this.state.page + 1 });
            this.loadOf();
            document.removeEventListener('scroll', this.trackScrolling);
        }
    }

    componentDidMount() {
        this.loadOf();
        api.get(`/maquina?itens=all`).then((result) => {
            const maquinas = result.data.map(maquina => ({ value: maquina.numero, label: maquina.nome }));
            this.setState({ maquinas });
        });
        api.get(`/produto?itens=all&grupo=${produtoGrupoEnum.PRODUTO_FINAL}`).then((result) => {
            const produtos = result.data.map(produto => ({ value: produto.codigo, label: produto.nome }));
            this.setState({ produtos });
        });
        document.addEventListener('scroll', this.trackScrolling);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }

    renderOfs = () => {
        const { ofs, searching, maquina } = this.state;
        if (ofs.length > 0) {
            return ofs.map(of => {
                const { numero, maquina: { nome: maquinaNome } } = of;
                return (
                    <div key={`of-${numero}`} className="producao-of">
                        <div className="producao-of-container">
                            {!maquina && (<>
                                <div className="producao-of-maquina">{maquinaNome}</div>
                                <hr className="producao-of-hr" />
                            </>)}
                            <Of of={of} loadOf={this.loadOf} />
                        </div>
                    </div>
                )
            });
        }
        if (!searching) {
            return (
                <div className="producao-baixar-title">Nenhuma ordem arquivada</div>
            );
        }
        return null;
    }

    render() {
        const { searching, maquina, maquinas, produtos } = this.state;

        return (<>
            <div className="producao-filtro">
                <div className="row">
                    <div className="col-md-3">
                        <CustomInput
                            label="Filtre por número"
                            type="number"
                            onChange={async event => {
                                const { target: { value: numero } } = event;
                                if (this.numeroTimeout) {
                                    clearTimeout(this.numeroTimeout);
                                }
                                this.setState({ ofs: [], searching: true })
                                this.numeroTimeout = setTimeout(() => {
                                    this.filterOf({ numero })
                                }, 1500);
                            }}
                            />
                    </div>
                    <div className="col-md-3">
                        <CustomInput
                            label="Filtre por lote"
                            type="number"
                            onChange={async event => {
                                const { target: { value: lote } } = event;
                                if (this.loteTimeout) {
                                    clearTimeout(this.loteTimeout);
                                }
                                this.setState({ ofs: [], searching: true })
                                this.loteTimeout = setTimeout(() => {
                                    this.filterOf({ lote })
                                }, 1500);
                            }}
                            />
                    </div>
                    <div className="col-md-3">
                        <CustomInput
                            label="Filtre por máquina"
                            type="select"
                            options={maquinas}
                            isClearable={true}
                            onChange={async event => {
                                const { target: { value: maquina } } = event;
                                this.filterOf({ maquina });
                            }}
                            />
                    </div>
                    <div className="col-md-3">
                        <CustomInput
                            label="Filtre por produto"
                            type="select"
                            options={produtos}
                            isClearable={true}
                            onChange={async event => {
                                const { target: { value: produto } } = event;
                                this.filterOf({ produto });
                            }}
                            />
                    </div>
                </div>
            </div>
            <div className="producao-ofs">
                {this.renderOfs()}
            </div>
            {searching && (
                <div className="producao-loader">
                    <Loader />
                </div>
            )}
        </>);
    }
}