import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import api from '../../services/api';

import '../../assets/scss/producao.scss';
import Loader from '../../components/loader';
import Of from '../../components/of/of';
import IconButton from '../../components/util/IconButton';
import { faArchive, faClipboardCheck, faPlus, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import ofStatusEnum from '../../util/enum/ofStatusEnum';
import produtoGrupoEnum from '../../util/enum/produtoGrupoEnum';
import OfModal from '../../components/of/of_modal';

export default class Producao extends Component {
    state = {
        ofs: [],
        maquinas: null,
        produtos: null,
        nextOf: null,
        nextLote: null,
        baixar: [],
        searching: false,
        adicionar: false,
    }

    refresh = () => {
        this.loadOf();
        this.loadEncerradas();
    }

    loadEncerradas = () => {
        api.get(`/of?status=${ofStatusEnum.ENCERRADA}&itens=all&count=true`).then((result) => {
            const { count: baixar } = result.data;
            this.setState({ baixar });
        });
    }

    loadNextInfo = () => {
        api.get(`/of?info=next`).then((result) => {
            this.setState(result.data);
        });
    }

    loadOf = async(of = null) => {
        try {
            let nextInfo = true;
            
            if (!of) {
                this.setState({ ofs: [], baixar: [], searching: true })
    
                const status = ofStatusEnum.properties;
                delete status[ofStatusEnum.BAIXADA];
                delete status[ofStatusEnum.ENCERRADA];
        
                const ofs = (await api.get(`/of?status=${Object.keys(status).join(',')}&itens=all&order_by=1`)).data;
        
                this.setState({ ofs, searching: false })
            } else {
                const { ofs } = this.state;
                const excluir = typeof of !== 'object';

                let alterou = ofs.findIndex(item => {
                    return (excluir ? of : of.numero) == item.numero
                });
                if (alterou >= 0) {
                    delete ofs[alterou];
                    nextInfo = Boolean(excluir);
                    setTimeout(() => {
                        if (excluir) {
                            ofs.splice(alterou, 1)
                        } else {
                            ofs[alterou] = of;
                        }
                        this.setState({ ofs });
                    }, 450)
                } else {
                    ofs.unshift(of);
                }
                this.setState({ ofs });
            }
            
            if (nextInfo) {
                this.loadEncerradas();
                this.loadNextInfo();
            }
        } catch (error) {
            console.log(error);
        }
    }

    async componentDidMount() {
        this.loadOf();
        this.loadEncerradas();

        api.get(`/maquina?itens=all`).then((result) => {
            const maquinas = result.data.map(maquina => ({ value: maquina.id, label: maquina.nome }));
            this.setState({ maquinas });
        });
        api.get(`/produto?itens=all&grupo=${produtoGrupoEnum.PRODUTO_FINAL}`).then((result) => {
            const produtos = result.data.map(produto => ({ value: produto.id, label: produto.nome, codigo: produto.codigo }));
            this.setState({ produtos });
        });

        window.onkeydown = e => {
            if (e.key == 'F5') {
                e.preventDefault()
                this.refresh();
            }
        }
    }

    componentWillUnmount() {
        window.onkeydown = null;
    }

    renderOfs = () => {
        const { ofs, maquinas, produtos, searching } = this.state;

        if (ofs.length > 0) {
            return (
                <TransitionGroup className="producao-ofs">
                    {ofs.map(of => {
                        return (
                            <CSSTransition
                                key={`of-${of.numero}`}
                                classNames="producao-ofs-transition"
                                timeout={500}>
                                <div className="producao-of">
                                    <div className="producao-of-container">
                                        <div className="producao-of-maquina">{of.maquina.nome}</div>
                                        <hr className="producao-of-hr" />
                                        <Of
                                            quantidadesFlip={true}
                                            maquinas={maquinas}
                                            produtos={produtos}
                                            loadOf={this.loadOf}
                                            of={of} />
                                    </div>
                                </div>
                            </CSSTransition>
                        )
                    })}
                </TransitionGroup>
            );
        }
        if (searching) {
            return (
                <div className="producao-loader">
                    <Loader />
                </div>
            )
        }
        return (
            <div>
                Sem OFs ativas
            </div>
        )
    }

    render() {
        const {
            baixar,
            searching,
            adicionar,
            maquinas,
            produtos,
            nextOf,
            nextLote,
        } = this.state;

        let baixarText = '';
        if (baixar > 0) {
            baixarText = `${baixar} ${baixar > 1 ? 'ordens encerradas' : 'ordem encerrada'}`;
        } else {
            baixarText = 'Sem ordens encerradas';
        }

        return (<>
            <div className="producao-header">
                <IconButton className="producao-header-btn" icon={faPlus} text="Adicionar" onClick={() => this.setState({ adicionar: true })} />
                {!searching && <IconButton className="producao-header-btn" icon={faClipboardCheck} text={baixarText} link="/producao/baixar" disabled={baixar < 1} />}
                <IconButton className="producao-header-btn" icon={faArchive} text="Ordens arquivadas" link="/producao/arquivo" />
            </div>
            {this.renderOfs()}
            {adicionar !== false && (
                <OfModal
                    show={adicionar === true}
                    maquinas={maquinas}
                    produtos={produtos}
                    nextOf={nextOf}
                    nextLote={nextLote}
                    onSuccess={this.loadOf}
                    onHide={() => {
                        this.setState({ adicionar: null });
                        setTimeout(() => this.setState({ adicionar: false }), 500);
                    }} />
            )}
        </>);
    }
}