const values = {
    PREPARANDO: 1,
    ABERTA: 2,
    INICIADA: 3,
    ENCERRADA: 4,
    BAIXADA: 5,
};

const properties = {
    1: {name: 'PREPARANDO', value: 1, text: 'Preparando'},
    2: {name: 'ABERTA', value: 2, text: 'Aberta'},
    3: {name: 'INICIADA', value: 3, text: 'Iniciada'},
    4: {name: 'ENCERRADA', value: 4, text: 'Encerrada'},
    5: {name: 'BAIXADA', value: 5, text: 'Baixada'},
};

const getAbertas = () => {
    const { ...status } = properties;
    delete status[values.ENCERRADA];
    delete status[values.BAIXADA];
    return status;
}

export default {
    ...values,
    properties,
    getAbertas,
};