import React, { Component } from 'react';

import '../../assets/scss/components/pedido.scss';
import { faChevronLeft, faDolly, faSearch } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import api from '../../services/api';
import PedidoModal from './pedido_modal';
import pedidoStatusEnum from '../../util/enum/pedidoStatusEnum';
import Alert from '../util/Alert';

export default class Pedido extends Component {
    state = {
        visualizar: false,
    };

    alterStatus = async(params) => {
        try {
            const { pedido: { numero } } = this.props;
            
            const result = (await api.put(`/pedido`, { numero, react: true, ...params })).data;
            const { error, warning } = result;
            
            switch (warning) {
                case 1:
                    this.setState({ visualizar: true, error: "Não há itens nesse pedido" });
                    break;
            
                default:
                    switch (error) {
                        case 0:
                            this.props.loadPedidos(numero);
                            break;
                    
                        default:
                            this.setState({ error: "Aconteceu um erro" })
                            break;
                    }
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    voltarEscritorio = async() => {
        this.alterStatus({ voltarEscritorio: true });
    }
    
    voltarExpedicao = async() => {
        this.alterStatus({ voltarExpedicao: true });
    }
    
    enviarExpedicao = async() => {
        this.alterStatus({ enviarExpedicao: true });
    }
    
    componentDidMount() {
        const { pedido: { numero } } = this.props;
        if (numero == 14741) {
            // this.setState({ visualizar: true });
        }
    }

    renderText = () => {
        const { pedido } = this.props;
        const { numero, cliente = {}, observacoes } = pedido;
        const { nome: clienteNome } = cliente;

        return (<>
            <div className="pedido-title">Pedido {numero}</div>
            <div className="pedido-text">{clienteNome}</div>
            {observacoes && <div className="pedido-text">OBS: {observacoes}</div>}
        </>);
    }

    renderBottom = () => {
        const { pedido, pendente, nextNotaFiscal, produtos, loadPedidos } = this.props;
        const { status } = pedido;
        const { visualizar } = this.state;

        return (<>
            <IconButton text="Visualizar" icon={faSearch} className="pedido-btn" onClick={() => this.setState({ visualizar: true })} />
            {status == pedidoStatusEnum.APROVADO && <IconButton text="Enviar à expedição" icon={faDolly} className="pedido-btn" onClick={this.enviarExpedicao} />}
            {status == pedidoStatusEnum.ENVIADO && <IconButton text="Voltar da expedição" icon={faChevronLeft} className="pedido-btn" onClick={this.voltarEscritorio} />}
            {status == pedidoStatusEnum.EMBARQUE && <IconButton text="Voltar à expedição" icon={faChevronLeft} className="pedido-btn" onClick={this.voltarExpedicao} />}
            {visualizar !== false && (
                <PedidoModal
                    add={false}
                    show={visualizar === true}
                    pedido={pedido}
                    pendente={pendente}
                    nextNotaFiscal={nextNotaFiscal}
                    produtos={produtos}
                    onSuccess={loadPedidos}
                    onHide={() => {
                        this.setState({ visualizar: null });
                        setTimeout(() => this.setState({ visualizar: false }), 250);
                    }} />
            )}
        </>);
    }

    render() {
        const { error } = this.state;

        return (
            <div>
                {this.renderText(false)}
                {this.renderBottom()}
                {error && (
                    <Alert message={error} onDismiss={() => this.setState({ error: null })} />
                )}
            </div>
        )
    }
}