const getByProp = (value, prop, props, strict = false) => {
    for (const tipo in props) {
        const property = props[tipo];
        if (strict) {
            if (property[prop] === value) {
                return tipo;
            }
        } else if (property[prop] == value) {
            return tipo;
        }
    }
    return null;
}

export default {
    isEnum: (value, values) => {
        return values.indexOf(parseInt(value)) !== -1;
    },
    getSelectOptions: (properties) => {
        return Object.values(properties).map(({ text, value }) => ({ value, label: text }));
    },
    getByProp,
    getItemByProp: (value, prop, props, strict = false) => {
        return props[getByProp(value, prop, props, strict)];
    }
};