import React, { Component } from 'react';

import BSAlert from 'react-bootstrap/Alert'
import { CSSTransition } from 'react-transition-group';

export default class Alert extends Component {
    state = {
        show: true,
        width: 0,
        timeout: null,
    }
    
    dismiss = () => {
        this.setState({ show: false });
    }

    addWidth = () => {
        if (this.interval) {
            clearInterval(this.interval);
        }
        let { width, timeout } = this.state;
        
        this.interval = setInterval(() => {
            width++;
            this.setState({ width })

            if (width === 100) {
                clearInterval(this.interval);
                this.dismiss();
            }
        }, timeout / 100);
    }

    resetWidth = () => {
        let { width, show } = this.state;
        if (width > 0 && show) {
            clearInterval(this.interval);

            this.interval = setInterval(() => {
                width--;
                this.setState({ width })
    
                if (width === 0) {
                    clearInterval(this.interval);
                }
            }, 10);
        }
    }

    componentDidMount() {
        const { timeout = 2500 } = this.props;
        this.setState({ timeout });
        
        setTimeout(() => {
            this.addWidth();
        }, timeout / 10);
    }

    render() {
        const { show, width } = this.state;
        const { message } = this.props;

        return (<>
            <CSSTransition in={show} timeout={500} classNames="cpl-alert-fade" onExited={this.props.onDismiss}>
                <BSAlert variant="primary" className="cpl-alert" onMouseEnter={this.resetWidth} onMouseLeave={this.addWidth} onClose={this.dismiss} dismissible>
                    <div className="alert-content">
                        {message}
                    </div>
                    <div className={`alert-progress alert-progress-${width}`}></div>
                </BSAlert>
            </CSSTransition>
        </>)
    }
}