import React, { Component } from 'react';
import api from '../../services/api';

import '../../assets/scss/notificacao.scss';
import Loader from '../../components/loader';
import { load } from 'react-cookies';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomInput from '../../components/util/CustomInput';
import moment from 'moment';
import 'moment/locale/pt-br';

export default class Notificacao extends Component {
    state = {
        notificacoes: [],
        page: 1,
        searching: false,
        text: null,
    }
    timeout = null;

    load = async() => {
        try {
            let { page, text, notificacoes } = this.state;
            this.setState({ searching: true })
            
            text = text ? `&text=${text}` : '';
            const usuario = load('CENTROPLAST_INTRANET_USUARIO');
            const result = (await api.get(`/notification?usuario=${usuario.id}&pagina=${page}${text}`)).data;
            console.log(result);
            if (result && result.length > 0) {
                notificacoes = [...notificacoes, ...result];
                
                document.addEventListener('scroll', this.trackScrolling);
            }
            this.setState({ notificacoes, searching: false })
        } catch (error) {
            console.log(error);
        }
    }
    
    trackScrolling = () => {
        const element = document.getElementsByClassName('notificacoes')[0];
        if (element.getBoundingClientRect().bottom <= (window.innerHeight + 100)) {
            this.setState({ page: this.state.page + 1 });
            this.load();
            document.removeEventListener('scroll', this.trackScrolling);
        }
    }

    componentDidMount() {
        this.load();
        document.addEventListener('scroll', this.trackScrolling);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }

    renderNotificacoes = () => {
        const { notificacoes, searching, maquina } = this.state;
        if (notificacoes.length > 0) {
            return (<>
                {notificacoes.map((notificacao, index) => {
                    const { titulo, texto, data, lido } = notificacao;
                    return (
                        <div key={index.toString()} className="notificacao">
                            <div className="notificacao-container">
                                <Row className="notificacao-titulo">
                                    <Col>{titulo}</Col>
                                </Row>
                                <Row className="notificacao-texto">
                                    <Col>{texto}</Col>
                                </Row>
                                <Row className="notificacao-data">
                                    <Col>Data: {moment(data).format('L [às] LTS')} - Lido em: {moment(lido).format('L [às] LTS')}</Col>
                                </Row>
                            </div>
                        </div>
                    )
                })}
                {!searching && (
                    <div className="notificacoes-header">
                        As notificações são excluídas automaticamente após 30 dias
                    </div>
                )}
            </>);
        }
        if (!searching) {
            return (
                <div className="notificacao-title">Nenhuma notificação nos últimos 30 dias</div>
            );
        }
        return null;
    }

    render() {
        const { searching } = this.state;

        return (<>
            <Row>
                <Col>
                    <CustomInput
                        label="Filtre as notificações"
                        onChange={({ target: { value } }) => {
                            if (this.timeout !== null) {
                                clearTimeout(this.timeout);
                                this.timeout = null;
                            } else {
                                this.setState({ searching: true, notificacoes: [], page: 1 })
                            }

                            this.timeout = setTimeout(() => {
                                this.setState({ text: value, searching: false })
                                this.load();
                            }, 1500);
                        }} />
                </Col>
            </Row>
            <div className="notificacoes">
                {this.renderNotificacoes()}
            </div>
            {searching && (
                <div className="notificacao-loader">
                    <Loader />
                </div>
            )}
        </>);
    }
}