import utilEnum from './enum';

const values = {
    CLIENTERETIRA:   17,
    BAUER:           18,
    BRASPRESS:       1,
    CENTROSUL:       2,
    CORREIOS:        3,
    FRONTEIRA:       4,
    HBTRANSITO:      5,
    HELIOS:          6,
    JAMEF:           7,
    LEOMAR:          8,
    PLANALTO:        9,
    REUNIDAS:        10,
    RODONAVES:       11,
    RODOVIASUL:      12,
    SANTACRUZ:       13,
    SAOMIGUEL:       14,
    TNT:             15,
    TW:              16,
};

const properties = {
    17: {name: 'CLIENTE_RETIRA', value: 17, text: 'Cliente retira'},
    18: {name: 'BAUER', value: 18, text: 'Bauer Express'},
    1: {name: 'BRASPRESS', value: 1, text: 'Braspress'},
    2: {name: 'CENTROSUL', value: 2, text: 'Centrosul (Maffi)'},
    3: {name: 'CORREIOS', value: 3, text: 'Correios'},
    4: {name: 'FRONTEIRA', value: 4, text: 'Fronteira Sul (LFR)'},
    5: {name: 'HBTRANSITO', value: 5, text: 'HB Trânsito'},
    6: {name: 'HELIOS', value: 6, text: 'Helios'},
    7: {name: 'JAMEF', value: 7, text: 'Jamef'},
    8: {name: 'LEOMAR', value: 8, text: 'Leomar'},
    9: {name: 'PLANALTO', value: 9, text: 'Planalto'},
    10: {name: 'REUNIDAS', value: 10, text: 'Reunidas'},
    11: {name: 'RODONAVES', value: 11, text: 'Rodonaves'},
    12: {name: 'RODOVIASUL', value: 12, text: 'Rodovia Sul'},
    13: {name: 'SANTACRUZ', value: 13, text: 'Santa Cruz'},
    14: {name: 'SAOMIGUEL', value: 14, text: 'São Miguel'},
    15: {name: 'TNT', value: 15, text: 'TNT'},
    16: {name: 'TW', value: 16, text: 'TW'},
};

export default {
    ...values,
    properties,
    getSelectOptions: () => {
        return utilEnum.getSelectOptions(properties);
    }
};