import React, { Component } from 'react';
import api from '../../services/api';

import '../../assets/scss/expedicao.scss';
import Loader from '../../components/loader';
import Pedido from '../../components/pedido/pedido';
import pedidoStatusEnum from '../../util/enum/pedidoStatusEnum';
import { CSSTransition } from 'react-transition-group';
import produtoGrupoEnum from '../../util/enum/produtoGrupoEnum';

export default class ExpedicaoEnviado extends Component {
    state = {
        pedidos: [],
        produtos: null,
        searching: false,
    }

    loadPedidos = async() => {
        try {
            this.setState({ pedidos: [], searching: true })

            const pedidos = (await api.get(`/pedido?status=${pedidoStatusEnum.ENVIADO}&itens=all&order_by=2`)).data;
    
            this.setState({ pedidos, searching: false })
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {
        this.loadPedidos();

        api.get(`/produto?itens=all&grupo=${produtoGrupoEnum.PRODUTO_FINAL}`).then((result) => {
            const produtos = result.data.map(produto => ({ value: produto.codigo, label: produto.nome }));
            this.setState({ produtos });
        });
    }

    renderPedidos = () => {
        const { pedidos, produtos, searching } = this.state;

        if (pedidos.length > 0) {
            return pedidos.map(pedido => {
                const { numero } = pedido;
                return (
                    <CSSTransition
                        key={`pedido-${numero}`}
                        classNames="expedicao-pedidos-transition"
                        timeout={500}>
                        <div className="expedicao-pedido">
                            <div className="expedicao-pedido-container">
                                <Pedido
                                    pedido={pedido}
                                    produtos={produtos}
                                    loadPedidos={this.loadPedidos} />
                            </div>
                        </div>
                    </CSSTransition>
                )
            });
        }
        if (searching) {
            return (
                <div className="expedicao-loader">
                    <Loader />
                </div>
            )
        }
    }

    render() {
        const { pedidos, searching } = this.state;
        const length = pedidos.length;

        return (<>
            <div className="expedicao-title">
                {length > 0
                    ? `${length} ${length > 1 ? 'pedidos' : 'pedido'} na expedição`
                    : !searching && `Nenhum pedido na expedição`}
            </div>
            <div className="expedicao-pedidos">
                {this.renderPedidos()}
            </div>
        </>);
    }
}