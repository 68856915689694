import React, { Component } from 'react';

import '../../assets/scss/components/usuario.scss';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import Modal from 'react-bootstrap/Modal';
import Alert from '../util/Alert';
import api from '../../services/api';
import util from '../../util/util';
import { Col, Row, Alert as BSAlert } from 'react-bootstrap';
import CustomInput from '../util/CustomInput';

export default class EstoqueBoxModal extends Component {
    constructor(props) {
        super(props);
        util.bindFunctions(this, [
            'hideSuccess',
            'save',
            'searchBoxes',
            'saveState',
            'renderItem',
        ])
    }

    state = {
        error: null,
        boxes: [],
    }

    async hideSuccess(boxes) {
        const { onHide, onSuccess } = this.props;
        await onHide();
        await onSuccess(boxes);
    }

    async save() {
        try {
            const { lote } = this.props;
            const { boxes } = this.state;
            
            const result = (await api.put('/lote', { lote, boxes })).data;
            console.log(result);
            const { error, boxes: newBoxes } = result;
            switch (error) {
                case 0:
                    this.hideSuccess(newBoxes);
                    break;

                default:
                    console.log(result);
                    this.setState({ error: "Aconteceu um erro" })
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async searchBoxes() {
        let { lote, boxes } = this.props;
        if (boxes === undefined) {
            boxes = (await api.get(`/lote/${lote}?boxes=true`)).data.map(({ quantidade, produto_estoque_box: { numero: box } }) => ({ box, quantidade }));
        }
        boxes.push({ box: '', quantidade: 0 });
        this.setState({ boxes });
    }

    saveState(type, value, index) {
        const { boxes } = this.state;
        boxes[index][type] = value;

        if (!(boxes[index].box || boxes[index].quantidade)) {
            boxes.splice(index, 1);
        }
        if (boxes[boxes.length - 1].box || boxes[boxes.length - 1].quantidade) {
            boxes.push({ box: '', quantidade: 0 });
        }

        this.setState({ boxes });
    }

    renderItem({ box, quantidade }, index) {
        return (
            <Row key={index.toString()} className="mb-2">
                <Col>
                    <CustomInput
                        formGroup={false}
                        min={0}
                        type="number"
                        className="text-center"
                        value={box}
                        onChange={({ target: { value } }) => this.saveState('box', value, index)} />
                </Col>
                <Col>
                    <CustomInput
                        formGroup={false}
                        min={0}
                        type="number"
                        className="text-center"
                        value={quantidade}
                        onChange={({ target: { value } }) => this.saveState('quantidade', value, index)} />
                </Col>
            </Row>
        )
    }

    render() {
        const { show, produto, lote, quantidade } = this.props;
        const { boxes, error } = this.state;
        const total = boxes.reduce((total, next) => total + parseInt(next.quantidade || 0), 0);

        return (<>
            <Modal
                show={show}
                dialogClassName="modal-md"
                onShow={this.searchBoxes}
                onHide={() => {}} >
                <Modal.Header>
                    <Modal.Title>Boxes do lote {lote} do produto {produto}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center mb-2">
                        <Col md={9}>
                            <Row className="text-center mb-2">
                                <Col>Há {quantidade} disponível deste lote</Col>
                            </Row>
                            <Row className="text-center mb-2">
                                <Col>Box</Col>
                                <Col>Quantidade</Col>
                            </Row>
                            {boxes.map(this.renderItem)}
                            <Row className="mt-2">
                                <Col className="text-right">Total:</Col>
                                <Col className="text-center">{total}</Col>
                            </Row>
                        </Col>
                    </Row>
                    {quantidade && total != quantidade && (
                        <BSAlert variant="danger" className="mb-2 text-center">
                            O total acima é diferente da quantidade disponível.
                        </BSAlert>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <IconButton text="Salvar" icon={faSave} onClick={this.save} disabled={total != quantidade} />
                </Modal.Footer>
            </Modal>

            {error && (
                <Alert message={error} onDismiss={() => this.setState({ error: null })} />
            )}
        </>)
    }
}