import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import api from '../../services/api';

import '../../assets/scss/pedidos.scss';
import Loader from '../../components/loader';
import PedidoItem from '../../components/pedido/pedido';
import produtoGrupoEnum from '../../util/enum/produtoGrupoEnum';
import { load } from 'react-cookies';

export default class Pedido extends Component {
    constructor(props) {
        super(props);
        [
            'loadPedidos',
        ].forEach(funcao => {
            this[funcao] = this[funcao].bind(this);
        });
    }

    state = {
        pedidos: [],
        produtos: null,
        searching: false,
    }

    async loadPedidos(pedido = null) {
        try {
            if (!pedido) {
                this.setState({ pedidos: [], baixar: [], searching: true })
                
                const pedidos = (await api.get(`/pedido_pendente?itens=all`)).data;
                
                this.setState({ pedidos, searching: false })
            } else {
                const { pedidos } = this.state;
                const excluir = typeof pedido !== 'object';

                let alterou = pedidos.findIndex(item => {
                    if (item) {
                        const numero = excluir ? pedido : pedido.numero;
                        return numero == item.numero;
                    }
                });
                if (alterou >= 0) {
                    delete pedidos[alterou];
                    setTimeout(() => {
                        if (excluir) {
                            pedidos.splice(alterou, 1)
                        } else {
                            pedidos[alterou] = pedido;
                        }
                        this.setState({ pedidos });
                    }, 450)
                } else {
                    pedidos.unshift(pedido);
                }
                this.setState({ pedidos });
            }
        } catch (error) {
            console.log(error);
        }
    }

    async componentDidMount() {
        const usuario = load('CENTROPLAST_INTRANET_USUARIO');

        if (usuario.administrador && usuario.escritorioVenda) {
            this.loadPedidos();
    
            api.get(`/produto?itens=all&grupo=${produtoGrupoEnum.PRODUTO_FINAL}`).then((result) => {
                const produtos = result.data.map(produto => ({ value: produto.codigo, label: produto.nome }));
                this.setState({ produtos });
            });
        } else {
            window.location.href = '/';
        }
    }

    render() {
        const { pedidos, produtos, searching } = this.state;

        if (pedidos.length > 0) {
            return (
                <TransitionGroup className="pedidos-pedidos">
                    {pedidos.map(pedido => {
                        return (
                            <CSSTransition
                                key={`pedido-${pedido.numero}`}
                                classNames="pedidos-pedidos-transition"
                                timeout={500}>
                                <div className="pedidos-pedido">
                                    <div className="pedidos-pedido-container">
                                        <PedidoItem
                                            pedido={pedido}
                                            pendente={true}
                                            produtos={produtos}
                                            loadPedidos={this.loadPedidos} />
                                    </div>
                                </div>
                            </CSSTransition>
                        )
                    })}
                </TransitionGroup>
            );
        }
        if (searching) {
            return (
                <div className="pedidos-loader">
                    <Loader />
                </div>
            )
        }
        return (
            <div className="pedidos-title mt-2">
                Sem pedidos pendentes
            </div>
        )
    }
}