import React, { Component } from 'react';
import api from '../../services/api';

import '../../assets/scss/expedicao.scss';
import Loader from '../../components/loader';
import Pedido from '../../components/pedido/pedido';
import pedidoStatusEnum from '../../util/enum/pedidoStatusEnum';
import { CSSTransition } from 'react-transition-group';

export default class ExpedicaoEmbarque extends Component {
    state = {
        pedidos: [],
        nextNotaFiscal: null,
        searching: false,
    }

    loadPedidos = async() => {
        try {
            this.setState({ pedidos: [], searching: true })

            const pedidos = (await api.get(`/pedido?status=${pedidoStatusEnum.EMBARQUE}&itens=all&order_by=2`)).data;
            
            this.setState({ pedidos, searching: false })

            const nextNotaFiscal = (await api.get(`/pedido?info=next_nf`)).data;
            this.setState({ nextNotaFiscal });
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {
        this.loadPedidos();
    }

    renderPedidos = () => {
        const { pedidos, nextNotaFiscal, searching } = this.state;

        if (pedidos.length > 0) {
            return pedidos.map(pedido => {
                const { numero } = pedido;
                return (
                    <CSSTransition
                        key={`pedido-${numero}`}
                        classNames="expedicao-pedidos-transition"
                        timeout={500}>
                        <div className="expedicao-pedido">
                            <div className="expedicao-pedido-container">
                                <Pedido
                                    pedido={pedido}
                                    nextNotaFiscal={nextNotaFiscal}
                                    loadPedidos={this.loadPedidos} />
                            </div>
                        </div>
                    </CSSTransition>
                )
            });
        }
        if (searching) {
            return (
                <div className="expedicao-loader">
                    <Loader />
                </div>
            )
        }
    }

    render() {
        const { pedidos, searching } = this.state;
        const length = pedidos.length;

        return (<>
            <div className="expedicao-title">
                {length > 0
                    ? `${length} ${length > 1 ? 'notas fiscais' : 'nota fiscal'} para emitir`
                    : !searching && `Nenhuma nota fiscal para emitir`}
            </div>
            <div className="expedicao-pedidos">
                {this.renderPedidos()}
            </div>
        </>);
    }
}