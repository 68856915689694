import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import api from '../../services/api';

import '../../assets/scss/estoque.scss';
import Loader from '../../components/loader';
import produtoGrupoEnum from '../../util/enum/produtoGrupoEnum';
import CustomInput from '../../components/util/CustomInput';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { faClipboardList, faMapMarked, faMapMarkerAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../components/util/IconButton';
import EstoqueModal from '../../components/estoque/estoque_modal';
import EstoqueBoxModal from '../../components/estoque/estoque_box_modal';
import util from '../../util/util';

export default class Estoque extends Component {
    constructor(props) {
        super(props);
        util.bindFunctions(this, [
            'filterEstoque',
            'refresh',
            'loadEstoque',
            'trackScrolling',
            'renderItem',
            'renderEstoque',
        ])
    }

    state = {
        adicionar: false,
        lotes: [],
        valores: {},
        numero: null,
        produto: null,
        produtoInfo: {},
        semSaldo: null,
        page: 1,
        produtos: [],
        searching: false,
        boxes: null,
    }

    async filterEstoque(state) {
        await this.setState({
            ...state,
            page: 1,
            lotes: [],
            searching: true,
        })
        await this.loadEstoque();
    }

    async refresh() {
        await this.setState({ page: 1, lotes: [], searching: true });
        this.loadEstoque();
    }

    async loadEstoque(lote = null) {
        try {
            if (!lote) {
                let { page, lotes, numero, produto, semSaldo } = this.state;
                let produtoInfo = {};

                this.setState({ lotes: [], searching: true })
                
                let loteExiste = true;
                let valores = {};
                if (numero || produto) {
                    numero = numero ? `&lote=${numero}` : '';
                    produto = produto ? `&produto=${produto}` : '';
                    semSaldo = !numero && semSaldo ? `&sem_saldo=${semSaldo ? 'true' : 'false'}` : '';
                    const result = (await api.get(`/estoque?produto_info=true&order_by=1&pagina=${page}${produto}${numero}${semSaldo}`)).data;
                    console.log(result);
                    
                    if (result && result.lista !== undefined) {
                        let lista = [];
                        ({ lista, produto: produtoInfo, loteExiste, ...valores } = result);
                        lotes = [...lotes, ...lista];
                        
                        document.addEventListener('scroll', this.trackScrolling);
                    }
                } else {
                    lotes = [];
                }

                this.setState({ lotes, valores, produtoInfo, loteExiste, searching: false })
            } else {
                const { lotes } = this.state;
                const excluir = typeof lote !== 'object';

                let alterou = lotes.findIndex(item => {
                    return (excluir ? lote : lote.lote) == item.lote
                });
                if (alterou >= 0) {
                    delete lotes[alterou];
                    setTimeout(() => {
                        if (excluir) {
                            lotes.splice(alterou, 1)
                        } else {
                            lotes[alterou] = lote;
                        }
                        this.setState({ lotes });
                    }, 450)
                } else {
                    lotes.unshift(lote);
                }
                this.setState({ lotes });
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    trackScrolling() {
        const element = document.getElementsByClassName('estoque-lotes')[0];
        if (element.getBoundingClientRect().bottom <= window.innerHeight) {
            this.setState({ page: this.state.page + 1 });
            this.loadEstoque();
            document.removeEventListener('scroll', this.trackScrolling);
        }
    }

    async componentDidMount() {
        api.get(`/produto?itens=all&grupo=${produtoGrupoEnum.PRODUTO_FINAL}`).then((result) => {
            const produtos = result.data.map(produto => ({ value: produto.id, label: produto.nome, codigo: produto.codigo }));
            this.setState({ produtos });
        });

        document.addEventListener('scroll', this.trackScrolling);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }

    renderItem(item, index) {
        const { produtoInfo: { codigo: produto } } = this.state;
        const { lote, quantidade, sugerido, reserva } = item;
        return (
            <CSSTransition
                key={index}
                classNames="estoque-lotes-transition"
                timeout={500}>
                <div className="estoque-lote">
                    <div className="estoque-lote-container">
                        <div className="lote-title">Lote {lote}</div>
                        <div className="lote-quantidade-value">{quantidade}</div>
                        {Boolean(reserva) && <div className="lote-quantidade-value lote-quantidade-value-negative text-danger">- {reserva}</div>}
                        {Boolean(reserva) && <div className="lote-quantidade-value text-success">{quantidade - reserva}</div>}
                        {Boolean(sugerido) && <div className="lote-quantidade-title">Sugerido pela expedição</div>}
                        <IconButton className="w-100 mt-2" icon={faMapMarkerAlt} text="Boxes" onClick={() => this.setState({ boxes: { lote, produto, quantidade: quantidade - reserva } })} />
                    </div>
                </div>
            </CSSTransition>
        )
    }

    renderEstoque() {
        const { lotes, valores, loteExiste, numero, produto, searching } = this.state;
        const { estoque, reserva } = valores;

        if (lotes.length > 0) {
            return (<>
                {Boolean(estoque) && (
                    <Row>
                        <Col className="estoque-title">
                            {estoque} em estoque
                        </Col>
                    </Row>
                )}
                {Boolean(reserva) && (
                    <Row>
                        <Col className="estoque-title text-danger">
                            {reserva} reservado
                        </Col>
                    </Row>
                )}
                {Boolean(reserva) && (
                    <Row>
                        <Col className="estoque-title text-success">
                            {estoque - reserva} disponível
                        </Col>
                    </Row>
                )}
                {!numero && (
                    <TransitionGroup className="estoque-lotes">
                        {lotes.map(this.renderItem)}
                    </TransitionGroup>
                )}
            </>);
        }
        if (searching) {
            return (
                <div className="estoque-loader">
                    <Loader />
                </div>
            )
        }
        return (
            <div className="estoque-title">
                {numero || produto ? (loteExiste ? 'Sem estoque' : 'Lote não encontrado') : 'Use o filtro acima'}
            </div>
        )
    }

    render() {
        const { searching, adicionar, boxes, numero, produto, produtoInfo: { codigo, nome }, semSaldo, produtos } = this.state;

        return (<>
            <div className="estoque-header">
                <IconButton className="estoque-header-btn" icon={faPlus} text="Movimentação manual" onClick={() => this.setState({ adicionar: true })} />
                <IconButton className="estoque-header-btn" icon={faMapMarked} text="Mapa do depósito" link="/estoque/deposito" />
                <IconButton className="estoque-header-btn" icon={faClipboardList} text="Inventário de estoque" link="/estoque/inventario" />
            </div>

            <div className="estoque-filtro">
                <div className="row align-items-end">
                    <div className="col-md-3">
                        <CustomInput
                            label="Filtre por produto"
                            type="select"
                            options={produtos}
                            isClearable={true}
                            onChange={async event => {
                                const { target: { value: produto } } = event;
                                this.filterEstoque({ produto });
                            }}
                            />
                    </div>
                    <div className="col-md-3">
                        <CustomInput
                            label="Filtre por lote"
                            type="number"
                            onChange={async event => {
                                const { target: { value: numero } } = event;
                                if (this.numeroTimeout) {
                                    clearTimeout(this.numeroTimeout);
                                }
                                this.setState({ numero, estoque: null, lotes: [], searching: true })
                                this.numeroTimeout = setTimeout(() => {
                                    this.filterEstoque({ numero })
                                }, 1500);
                            }}
                            />
                    </div>
                    {!numero && produto && (
                        <div className="col-md-3">
                            <CustomInput
                                label="Incluir os lotes sem saldo?"
                                type="checkbox"
                                id="semSaldo"
                                value={semSaldo ? '1' : '0'}
                                onChange={async event => {
                                    const { target: { checked: semSaldo } } = event;
                                    this.filterEstoque({ semSaldo });
                                }}
                                />
                        </div>
                    )}
                </div>
            </div>

            {!searching && Boolean(codigo) && (
                <Row>
                    <Col className="estoque-produtoinfo">
                        {codigo} | {nome}
                    </Col>
                </Row>
            )}
            {this.renderEstoque()}

            {adicionar !== false && (
                <EstoqueModal
                    show={adicionar === true}
                    produto={produto}
                    produtos={produtos}
                    onSuccess={boxes => this.setState({ boxes })}
                    onHide={() => {
                        this.setState({ adicionar: null });
                        setTimeout(() => this.setState({ adicionar: false }), 250);
                    }} />
            )}

            {boxes !== null && (
                <EstoqueBoxModal
                    show={Boolean(boxes)}
                    produto={(boxes || {}).produto}
                    lote={(boxes || {}).lote}
                    quantidade={(boxes || {}).quantidade}
                    onSuccess={this.refresh}
                    onHide={() => {
                        this.setState({ boxes: false });
                        setTimeout(() => this.setState({ boxes: null }), 250);
                    }} />
            )}
        </>);
    }
}