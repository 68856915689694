import utilEnum from './enum';

const values = {
    ENTRADA_PRODUCAO: 1,
    SAIDA_PRODUCAO: 2,
    ENTRADA_VENDA: 3,
    SAIDA_VENDA: 4,
    ENTRADA_MANUAL: 5,
    SAIDA_MANUAL: 6,
    ENTRADA_EXPEDICAO: 7,
    SAIDA_EXPEDICAO: 8,
};

const properties = {
    1: { name: 'ENTRADA_PRODUCAO', value: 1, text: 'Entrada da produção' },
    2: { name: 'SAIDA_PRODUCAO', value: 2, text: 'Saída da produção' },
    3: { name: 'ENTRADA_VENDA', value: 3, text: 'Entrada de venda' },
    4: { name: 'SAIDA_VENDA', value: 4, text: 'Saída de venda' },
    5: { name: 'ENTRADA_MANUAL', value: 5, text: 'Entrada manual' },
    6: { name: 'SAIDA_MANUAL', value: 6, text: 'Saída manual' },
    7: { name: 'ENTRADA_EXPEDICAO', value: 7, text: 'Entrada da expedição' },
    8: { name: 'SAIDA_EXPEDICAO', value: 8, text: 'Saída da expedição' },
};

const getPropsManual = () => {
    return {
        [values.ENTRADA_MANUAL]: properties[values.ENTRADA_MANUAL],
        [values.SAIDA_MANUAL]: properties[values.SAIDA_MANUAL],
    };
}

const getText = tipo => {
    return properties[tipo].text;
}    

const getByProp = (value, prop, strict = false) => {
    for (const tipo in properties) {
        const property = properties[tipo];
        if (strict) {
            if (property[prop] === value) {
                return tipo;
            }
        } else if (property[prop] == value) {
            return tipo;
        }
    }
    return null;
}

export default {
    ...values,
    properties,
    getPropsManual,
    getText,
    getByProp,
    getSelectOptions: (props) => {
        return utilEnum.getSelectOptions(props || properties);
    }
};