import React, { Component } from 'react';
import cookie from 'react-cookies';
import api from '../services/api';
import global from '../util/global';

import IconButton from '../components/util/IconButton';
import CustomInput from '../components/util/CustomInput';
import logo from '../assets/images/logo.png';
import '../assets/scss/login.scss';
import Alert from '../components/util/Alert';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

class Login extends Component {
    state = {
        usuario: null,
        senha: null,
        error: null,
    }

    submit = async() => {
        let { usuario, senha, permanecerConectado } = this.state;
        if (global.isDev() && !(usuario && senha)) {
            const teste = 4;

            switch (teste) {
                case 1:
                    usuario = 'VANESSA';
                    senha = '123456';
                    break;
                case 2:
                    usuario = 'SANDRO';
                    senha = '017454';
                    break;
                case 3:
                    usuario = 'FERNANDO';
                    senha = '123456';
                    break;
                case 4:
                    usuario = 'GUSTAVO';
                    senha = '41824324';
                    break;
            }
        }
            
        if (usuario && senha) {
            try {
                console.log({ usuario, senha, permanecerConectado })
                const response = (await api.post('/login', { usuario, senha, permanecerConectado })).data;
                console.log(response);

                const user = response.user;
                if (response.error === 0 && user) {
                    const options = { path: '/' };
                    if (user.permanecerConectado) {
                        options.expires = new Date(moment().add(1, 'y'));
                    }
                    cookie.save('CENTROPLAST_INTRANET_USUARIO', JSON.stringify(user), options);
                    window.location.reload();
                } else {
                    this.setState({ error: "Credenciais incorretas" })
                }
            } catch (e) {
                this.setState({ error: "Erro ao realizar o login" })
                console.log(e);
            }
        }
    }

    change = async(event) => {
        const { id, name, value, checked } = event.target;
        const checkbox = !name && id && value == 'on' && checked !== undefined;
        
        const { state } = this;
        state[checkbox ? id : name] = checkbox ? (checked ? '1' : '0') : value;
        this.setState(state);
    }

    onEnterPress = () => {
        this.buttonRef.click()
    }

    render() {
        const { error } = this.state;
        const isDev = global.isDev();
        const button = <IconButton customRef={buttonRef => this.buttonRef = buttonRef} text="Login" icon={faSignInAlt} onClick={this.submit} />
        
        return (
            <div className="login-section">
                <div className="login-area">
                    <h1 className="login-title">Intranet Centroplast</h1>
                    <img className="login-logo" src={logo} />
                    <div className="login-form">
                        <CustomInput label="Usuário" name="usuario" onChange={this.change} required={!isDev} />
                        <CustomInput label="Senha" type="password" name="senha" onChange={this.change} onEnterPress={this.onEnterPress} required={!isDev} />
                        <CustomInput label="Permanecer conectado?" type="checkbox" id="permanecerConectado" onChange={this.change} onEnterPress={this.onEnterPress} />
                        {button}
                    </div>
                </div>

                {error && (
                    <Alert message={error} onDismiss={() => this.setState({ error: null })} />
                )}
            </div>
        );
    }
}

export default Login;