import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import api from '../../services/api';

import '../../assets/scss/expedicao.scss';
import Loader from '../../components/loader';
import IconButton from '../../components/util/IconButton';
import { faCheck, faDolly, faFileInvoiceDollar, faPlus } from '@fortawesome/free-solid-svg-icons';
import pedidoStatusEnum from '../../util/enum/pedidoStatusEnum';
import Pedido from '../../components/pedido/pedido';
import PedidoModal from '../../components/pedido/pedido_modal';
import produtoGrupoEnum from '../../util/enum/produtoGrupoEnum';

export default class Expedicao extends Component {
    state = {
        pedidos: [],
        produtos: null,
        nextPedido: null,
        enviados: null,
        embarque: null,
        searching: false,
        adicionar: false,
    }

    getCount = async() => {
        const { count } = (await api.get(`/pedido?status=${pedidoStatusEnum.APROVADO}&itens=all&count=true`)).data;
        if (count > 0) {
            return `${count} ${count > 1 ? 'pedidos aprovados' : 'pedido aprovado'}`;
        }
        return '';
    }

    loadNextInfo = () => {
        api.get(`/pedido?info=next`).then((result) => {
            this.setState(result.data);
        });
    }

    loadStatusInfo = () => {
        api.get(`/pedido?status=${pedidoStatusEnum.ENVIADO},${pedidoStatusEnum.EMBARQUE}&itens=all&count=true`).then((result) => {
            const { enviado: enviados, embarque } = result.data;
            this.setState({ enviados, embarque });
        });
    }

    loadPedidos = async(pedido = null) => {
        try {
            let nextInfo = true;
            
            if (!pedido) {
                this.setState({ pedidos: [], baixar: [], searching: true })
                
                const pedidos = (await api.get(`/pedido?status=${pedidoStatusEnum.APROVADO}&itens=all`)).data;
                
                this.setState({ pedidos, searching: false })
            } else {
                console.log(pedido);
                const { pedidos } = this.state;
                const excluir = typeof pedido !== 'object';

                let alterou = pedidos.findIndex(item => {
                    if (item) {
                        const numero = excluir ? pedido : pedido.numero;
                        return numero == item.numero;
                    }
                });
                if (alterou >= 0) {
                    delete pedidos[alterou];
                    nextInfo = Boolean(excluir);
                    setTimeout(() => {
                        if (excluir) {
                            pedidos.splice(alterou, 1)
                        } else {
                            pedidos[alterou] = pedido;
                        }
                        this.setState({ pedidos });
                    }, 450)
                } else {
                    pedidos.unshift(pedido);
                }
                this.setState({ pedidos });
            }
            
            if (nextInfo) {
                this.loadNextInfo();
            }
            this.loadStatusInfo();
            this.props.onChangeCount(await this.getCount());
        } catch (error) {
            console.log(error);
        }
    }

    async componentDidMount() {
        this.loadPedidos();
        this.loadStatusInfo();

        api.get(`/produto?itens=all&grupo=${produtoGrupoEnum.PRODUTO_FINAL}`).then((result) => {
            const produtos = result.data.map(produto => ({ value: produto.codigo, label: produto.nome }));
            this.setState({ produtos });
        });
    }

    renderPedidos = () => {
        const { pedidos, produtos, searching } = this.state;

        if (pedidos.length > 0) {
            return (
                <TransitionGroup className="expedicao-pedidos">
                    {pedidos.map(pedido => {
                        return (
                            <CSSTransition
                                key={`pedido-${pedido.numero}`}
                                classNames="expedicao-pedidos-transition"
                                timeout={500}>
                                <div className="expedicao-pedido">
                                    <div className="expedicao-pedido-container">
                                        <Pedido
                                            pedido={pedido}
                                            produtos={produtos}
                                            loadPedidos={this.loadPedidos} />
                                    </div>
                                </div>
                            </CSSTransition>
                        )
                    })}
                </TransitionGroup>
            );
        }
        if (searching) {
            return (
                <div className="expedicao-loader">
                    <Loader />
                </div>
            )
        }
        return (
            <div className="expedicao-title mt-2">
                Sem pedidos aprovados
            </div>
        )
    }

    render() {
        const {
            enviados,
            embarque,
            produtos,
            adicionar,
            nextPedido,
            searching
        } = this.state;

        let enviadosText = '';
        if (enviados > 0) {
            enviadosText = `${enviados} ${enviados > 1 ? 'pedidos' : 'pedido'} na expedição`;
        } else {
            enviadosText = 'Nenhum pedido na expedição';
        }

        let embarqueText = '';
        if (embarque > 0) {
            embarqueText = `${embarque} ${embarque > 1 ? 'notas fiscais' : 'nota fiscal'} para emitir`;
        } else {
            embarqueText = 'Nenhuma nota fiscal para emitir';
        }

        return (<>
            <div className="expedicao-header">
                <IconButton className="expedicao-header-btn" icon={faPlus} text="Adicionar" onClick={() => this.setState({ adicionar: true })} />
                {!searching && <IconButton className="producao-header-btn" icon={faDolly} text={enviadosText} link="/expedicao/enviados" disabled={enviados < 1} />}
                {!searching && <IconButton className="producao-header-btn" icon={faFileInvoiceDollar} text={embarqueText} link="/expedicao/embarque" disabled={embarque < 1} />}
                <IconButton className="producao-header-btn" icon={faCheck} text="Pedidos já emitidos" link="/expedicao/arquivo" />
            </div>
            {this.renderPedidos()}
            {adicionar !== false && (
                <PedidoModal
                    show={adicionar === true}
                    produtos={produtos}
                    onSuccess={this.loadPedidos}
                    nextPedido={nextPedido}
                    onHide={() => {
                        this.setState({ adicionar: null });
                        setTimeout(() => this.setState({ adicionar: false }), 250);
                    }} />
            )}
        </>);
    }
}