import React, { Component } from "react";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

export default class CustomSelect extends Component {
	state = {
		selectedOptions: this.props.options.filter(option => option.selected),
	};

	handleChange = (selectedOptions) => {
		if (!Array.isArray(selectedOptions)) {
			selectedOptions = [ selectedOptions ];
		}
		this.setState(
			() => ({ selectedOptions: selectedOptions }),
			() => this.hiddenSelect.dispatchEvent(new Event("change", { bubbles: true }))
		);
	};

	renderSelect = () => {
		const { onChange, className, defaultValue, createLabel = '', ...props } = this.props;
		const { selectedOptions } = this.state;
		let value = selectedOptions;
		if (value.length === 0 && defaultValue) {
			value = this.props.options.filter(option => option.value == defaultValue);
		}

		if (this.props.creatable) {
			return (
				<CreatableSelect
					className={className}
					menuPlacement="auto"
					placeholder="Selecione"
					formatCreateLabel={typeof createLabel !== 'function' ? value => `Criar${createLabel ? ` ${createLabel}` : ''} "${value}"` : createLabel}
					noOptionsMessage={() => "Sem opções"}
					value={value}
					onChange={this.handleChange}
					filterOption={({ value, label, data: { codigo } }, search) => {
						if (search) {
							const string = `${codigo || value} ${label}`;
							const stringSplit = string.toLowerCase().split(' ');
							return search.toLowerCase().split(' ').every(itemSearch => {
								return stringSplit.findIndex(item => item.includes(itemSearch)) !== -1
							});
						}
						return true;
					}}
					{...props} />
			)
		};
		return (
			<Select
				className={className}
				menuPlacement="auto"
				placeholder="Selecione"
				noOptionsMessage={() => "Sem opções"}
				value={value}
				onChange={this.handleChange}
				filterOption={({ value, label, data: { codigo } }, search) => {
					if (search) {
						const string = `${codigo || value} ${label}`;
						const stringSplit = string.toLowerCase().split(' ');
						return search.toLowerCase().split(' ').every(itemSearch => {
							return stringSplit.findIndex(item => item.includes(itemSearch)) !== -1
						});
					}
					return true;
				}}
				{...props} />
		);
	}

	render() {
		const { onChange, defaultValue, ...props } = this.props;
		const { selectedOptions } = this.state;
		let value = selectedOptions;
		if (value.length === 0 && defaultValue) {
			value = this.props.options.filter(option => option.value == defaultValue);
		}

		return (
			<div>
				{this.renderSelect()}
				<select
					multiple
					ref={hiddenSelect => this.hiddenSelect = hiddenSelect}
					name={props.name}
					onChange={onChange}
					value={selectedOptions.map((option) => option ? option.value : null)}
					readOnly
					className="d-none"
				>
					{selectedOptions.map((option, index) => {
						if (option) {
							const props = {};
							if (option.__isNew__) {
								props['data-create'] = true;
							}
							return (
								<option key={index} value={option.value} { ...props }>
									{option.label}
								</option>
							)
						}
						return null;
					})}
				</select>
			</div>
		);
	}
}
