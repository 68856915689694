import React, { Component } from 'react';

import '../../assets/scss/components/of.scss';
import { faCheck, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import Modal from 'react-bootstrap/Modal';
import CustomInput from '../util/CustomInput';
import Alert from '../util/Alert';
import api from '../../services/api';
import { Col, Row } from 'react-bootstrap';
import util from '../../util/util';
import tarefaRepetirEnum from '../../util/enum/tarefaRepetirEnum';
import Subtarefa from './subtarefa';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';
import business from 'moment-business';
import SubtarefaCard from './subtarefa_card';

export default class TarefaModal extends Component {
    constructor(props) {
        super(props);
        util.bindFunctions(this, [
            'hideSuccess',
            'delete',
            'save',
            'saveState',
            'changeSubtarefas',
            'renderCustomInput',
            'renderSelectReadonly',
        ])
    }

    state = {
        error: null,
        show: true,
        confirmarExcluir: false,
        tarefas: [],
        item: {
            dtPrevisaoTurno: 1,
            usuario: null,
            tarefa: null,
            subtarefas: [],
        },
    }

    static renderModal({ show, text, onHide, onSuccess }) {
        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Body className="tarefa-modal-title">
                    {text}
                </Modal.Body>
                <Modal.Footer className="tarefa-modal-confirm-footer">
                    <IconButton text="Sim" icon={faCheck} onClick={onSuccess} />
                    <IconButton text="Não" icon={faTimes} onClick={onHide} />
                </Modal.Footer>
            </Modal>
        );
    }

    async hideSuccess() {
        const { item: { dtPrevisao } } = this.state;
        const { onHide, onSuccess } = this.props;
        await onHide();
        await onSuccess(moment(dtPrevisao).date());
    }

    async delete() {
        console.log('delete')
        // const { confirmarExcluir } = this.state;
        // if (!confirmarExcluir) {
        //     this.setState({ confirmarExcluir: true });
        //     return;
        // }
        // this.setState({ confirmarExcluir: false, show: false });
        
        // const { codigo } = this.props.tarefa;

        // try {
        //     const result = (await api.delete(`/tarefa/${codigo}`)).data;
        //     console.log(result);

        //     if (result.error === 0) {
        //         this.hideSuccess();
        //     } else {
        //         this.setState({ error: "Aconteceu um erro" })
        //     }
        // } catch (error) {
        //     console.log(error);
        // }
    }

    async save() {
        try {
            const { add = true, item: itemProp } = this.props;
            const { item } = this.state;
            const { dtPrevisao } = item;

            if (!business.isWeekDay(moment(dtPrevisao))) {
                this.setState({ error: "Você não pode agendar uma tarefa para o final de semana" });
                return;
            }

            item.id = itemProp.id;
            item.react = true;
            const result = (await (add ? api.post : api.put)('/tarefa_item', item)).data;
            console.log(result)

            if (result.error === 0) {
                this.hideSuccess();
            } else {
                if (add) {
                    switch (result.error) {
                        default:
                            this.setState({ error: "Aconteceu um erro" })
                            break;
                    }
                } else {
                    switch (result.error) {
                        default:
                            this.setState({ error: "Aconteceu um erro" })
                            break;
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    saveState(event, forceValue = null) {
        const { id, name, value, checked } = event.target;
        const checkbox = !name && id && value == 'on' && checked !== undefined;

        const { item } = this.state;
        item[checkbox ? id : name] = checkbox ? (checked ? '1' : '0') : (forceValue !== null ? forceValue : value);
        this.setState({ item });
    }

    changeSubtarefas(index, indexTo = null) {
        const { item } = this.state;

        const $splice = [[ index, 1 ]];
        if (indexTo !== null) {
            $splice.push([ indexTo, 0, item.subtarefas[index] ])
        }
        item.subtarefas = update(item.subtarefas, { $splice });

        this.setState({ item })
    }

    componentDidMount() {
        const { item: itemProp = {}, tarefas } = this.props;
        const state = { tarefas };

        if (itemProp.subtarefas) {
            const { item } = this.state;
            const { dtPrevisao, subtarefas } = itemProp;

            item.dtPrevisao = dtPrevisao;
            item.subtarefas = subtarefas.map(({ tarefa: { codigo: tarefa, nome }, dtConclusao }) => ({ tarefa, nome, done: Boolean(dtConclusao) }));

            state.item = item;
        }

        this.setState(state);
    }

    renderCustomInput(params, baseValues = null) {
        if (!baseValues) {
            ({ item: baseValues = {} } = this.props);
        }
        let { id, name, type } = params;
        if (!name && id) {
            name = id;
        }
        
        if (type === 'number') {
            if (!params.min) {
                params.min = 0;
            }
        }
        if (!params.onChange) {
            params.onChange = this.saveState;
        }

        if (name && baseValues[name] && !params.defaultValue) {
            let value = baseValues[name];
            if (type === 'number') {
                value = (params.step < 1 ? parseFloat : parseInt)(value);
            }
            if (typeof value === 'object') {
                value = value.id || value;
            }
            params.defaultValue = value;
        }

        return (
            <CustomInput {...params} />
        )
    }

    renderSelectReadonly({ label, type = "select", name, value, options, enumValues, onChange, inputStyle, ...props }, condition = false) {
        const { add = true } = this.props;
        if (options === undefined) {
            options = enumValues ? enumValues.getSelectOptions() : [];
        }

        if (add || condition) {
            return this.renderCustomInput({
                label,
                name,
                type,
                value,
                options,
                onChange,
                ...props
            });
        }
        return this.renderCustomInput({
            label,
            name,
            value,
            type: "text",
            readOnly: true,
            style: inputStyle,
        });
    }

    render() {
        const {
            add = true,
            item = {},
            usuarios,
            show,
            onHide,
        } = this.props;

        const {
            confirmarExcluir,
            error,
            show: stateShow,
            item: { tarefa: selectedTarefa, subtarefas },
            tarefas,
        } = this.state;

        const { dtPrevisao, dtConclusao, tarefa = {}, usuario = {}, repetir } = item;

        const readOnly = Boolean(dtConclusao);

        return (<>
            <Modal
                show={show && stateShow && !confirmarExcluir}
                dialogClassName="modal-xl"
                onHide={onHide}
            >
                <Modal.Header>
                    <Modal.Title>{add ? "Adicionar tarefa" : `Tarefa`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={5}>
                            <Row>
                                <Col>
                                    {this.renderSelectReadonly({
                                        label: "A tarefa será realizada por este funcionário",
                                        name: "usuario",
                                        options: usuarios,
                                        value: (usuario || {}).usuario,
                                    }, !readOnly)}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {!readOnly && (
                                        <div className="cpl-form-group">
                                            <label className="cpl-form-label cpl-form-label-select">
                                                <span>Tarefa</span>
                                                <CreatableSelect
                                                    options={tarefas.filter(({ codigo }) => !subtarefas.find(({ tarefa }) => tarefa === codigo))}
                                                    value={tarefas.find(({ value }) => value == (selectedTarefa || tarefa.codigo))}
                                                    formatCreateLabel={value => `Criar tarefa "${value.toUpperCase()}"`}
                                                    placeholder="Selecione"
                                                    onChange={async ({ value, label }) => {
                                                        if (value === label) {
                                                            const { tarefas } = this.state;

                                                            value = Math.max(...tarefas.map(({ value }) => value)) + 1;
                                                            tarefas.push({ value, label: label.toUpperCase() });

                                                            this.setState({ tarefas });
                                                        }

                                                        const { item } = this.state;
                                                        item.tarefa = { tarefa: value, nome: label };
                                                        this.setState({ item });
                                                    }}
                                                    menuPlacement="auto"
                                                    styles={CustomInput.getSelectStyles()} />
                                            </label>
                                        </div>
                                    )}
                                    {readOnly && this.renderCustomInput({
                                        readOnly,
                                        label: "Tarefa",
                                        name: "tarefa",
                                        value: tarefa.nome,
                                    })}
                                </Col>
                            </Row>
                            <Row className="align-items-end">
                                <Col className="pr-0">
                                    {this.renderCustomInput({
                                        label: "Planejado para o dia",
                                        name: "dtPrevisao",
                                        type: "date",
                                        readOnly,
                                        defaultValue: readOnly ? moment(dtPrevisao).format('L') : dtPrevisao ? dtPrevisao.split(' ')[0] : null,
                                        style: {
                                            borderBottomRightRadius: "0",
                                            borderTopRightRadius: "0",
                                        }
                                    })}
                                </Col>
                                <Col className="pl-0">
                                    {this.renderSelectReadonly({
                                        name: "dtPrevisaoTurno",
                                        defaultValue: dtPrevisao ? (moment(dtPrevisao).format('H') < 13 ? 1 : 2) : 1,
                                        value: dtPrevisao ? (moment(dtPrevisao).format('H') < 13 ? "Manhã" : "Tarde") : "Manhã",
                                        options: [{ value: 1, label: 'Manhã' }, { value: 2, label: 'Tarde' }],
                                        selectStyle: {
                                            control: {
                                                borderBottomLeftRadius: "0",
                                            },
                                            // control: {
                                            //     textAlign: "right",
                                            // }
                                        },
                                        inputStyle: {
                                            borderBottomLeftRadius: "0",
                                            borderTopLeftRadius: "0",
                                        }
                                    }, !readOnly)}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.renderSelectReadonly({
                                        label: "Repetir?",
                                        name: "repetir",
                                        enumValues: tarefaRepetirEnum,
                                        value: tarefaRepetirEnum.getTextByValue(repetir),
                                    }, !readOnly)}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={7}>
                            <Row className={`align-items-end${readOnly ? ' mb-1' : ''}`}>
                                <Col md="auto">Atividades</Col>
                                {!readOnly && <Col className="text-right" style={{ fontSize: "small" }}>Você pode arrastar as atividades para ordená-las</Col>}
                            </Row>
                            {(add || !readOnly) && (
                                <Row className="mb-2">
                                    <Col>
                                        <CreatableSelect
                                            options={tarefas.filter(({ value }) => !subtarefas.find(({ tarefa }) => tarefa === value))}
                                            value={null}
                                            formatCreateLabel={value => `Criar tarefa "${value.toUpperCase()}"`}
                                            placeholder="Adicione uma atividade"
                                            onChange={async ({ value, label }) => {
                                                const { item } = this.state;
                                                if (value === label) {
                                                    const { tarefas } = this.state;

                                                    value = Math.max(...tarefas.map(({ value }) => value)) + 1;
                                                    tarefas.push({ value, label: label.toUpperCase() });

                                                    this.setState({ tarefas })
                                                }
                                                item.subtarefas.push({ tarefa: value, nome: label.toUpperCase() })
                                                this.setState({ item })
                                            }}
                                            menuPlacement="auto"
                                            styles={CustomInput.getSelectStyles()} />
                                    </Col>
                                </Row>
                            )}
                            <DndProvider backend={HTML5Backend}>
                                {subtarefas.filter(({ done }) => !done).map((item, index) => <Subtarefa key={item.tarefa} index={index} item={item} change={this.changeSubtarefas}/>)}
                            </DndProvider>
                            {subtarefas.filter(({ done }) => done).map((item, index) => <SubtarefaCard key={item.tarefa} index={index} item={item} change={this.changeSubtarefas}/>)}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton text="Salvar" icon={faSave} onClick={this.save} />
                    {!add && <IconButton type="danger" text="Excluir" icon={faTrash} onClick={this.delete} />}
                    <IconButton text="Fechar" type="dark" icon={faTimes} onClick={onHide} />
                </Modal.Footer>
            </Modal>

            {TarefaModal.renderModal({
                text: `Deseja realmente excluir esta tarefa?`,
                show: confirmarExcluir !== false,
                onSuccess: this.delete,
                onHide: () => this.setState({ confirmarExcluir: false }),
            })}

            {error && (
                <Alert message={error} onDismiss={() => this.setState({ error: null })} />
            )}
        </>)
    }
}