import React, { Component } from 'react';

import '../../assets/scss/components/usuario_grupo.scss';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import UsuarioGrupoModal from './usuario_grupo_modal';
import Alert from '../util/Alert';

export default class UsuarioGrupo extends Component {
    state = {
        visualizar: false,
    };

    renderText = () => {
        const { usuarioGrupo } = this.props;
        const { nome } = usuarioGrupo;

        return (<>
            <div className="usuariogrupo-title">{nome}</div>
        </>);
    }

    renderBottom = () => {
        const { usuarioGrupo, loadUsuarioGrupo } = this.props;
        const { visualizar } = this.state;

        return (<>
            <IconButton text="Visualizar" icon={faSearch} className="usuariogrupo-btn" onClick={() => this.setState({ visualizar: true })} />
            {visualizar !== false && (
                <UsuarioGrupoModal
                    add={false}
                    show={visualizar === true}
                    usuarioGrupo={usuarioGrupo}
                    onSuccess={loadUsuarioGrupo}
                    onHide={() => {
                        this.setState({ visualizar: null });
                        setTimeout(() => this.setState({ visualizar: false }), 500);
                    }} />
            )}
        </>);
    }

    render() {
        const { error } = this.state;

        return (
            <div>
                {this.renderText(false)}
                {this.renderBottom()}
                {error && (
                    <Alert message={error} onDismiss={() => this.setState({ error: null })} />
                )}
            </div>
        )
    }
}