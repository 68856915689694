import React, { Component } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProdutoModal from './produto_modal';

export default class Produto extends Component {
    state = {
        visualizar: false,
    };

    render() {
        const { visualizar } = this.state;
        const { produto, load } = this.props;
        const { codigo, nome } = produto;

        return (
            <Row className="align-items-center">
                <Col md="auto" className="produto-produtos-item-codigo">{codigo}</Col>
                <Col>{nome}</Col>
                <Col md="auto">
                    <IconButton
                        icon={faSearch}
                        onClick={() => this.setState({ visualizar: true })} />
                    {visualizar !== false && (
                        <ProdutoModal
                            add={false}
                            show={visualizar === true}
                            produto={produto}
                            onSuccess={load}
                            onHide={() => {
                                this.setState({ visualizar: null });
                                setTimeout(() => this.setState({ visualizar: false }), 500);
                            }} />
                    )}
                </Col>
            </Row>
        )
    }
}