import React, { Component } from 'react';

import '../../assets/scss/components/usuario.scss';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import UsuarioModal from './usuario_modal';
import Alert from '../util/Alert';

export default class Usuario extends Component {
    state = {
        visualizar: false,
    };

    renderText = () => {
        const { usuario } = this.props;
        const { usuario: usuarioNome, usuarioGrupo } = usuario;
        const { nome: grupoNome } = usuarioGrupo;

        return (<>
            <div className="usuario-title">{usuarioNome}</div>
            <div className="usuario-text">{grupoNome}</div>
        </>);
    }

    renderBottom = () => {
        const { usuario, grupos, loadUsuario } = this.props;
        const { visualizar } = this.state;

        return (<>
            <IconButton text="Visualizar" icon={faSearch} className="usuario-btn" onClick={() => this.setState({ visualizar: true })} />
            {visualizar !== false && (
                <UsuarioModal
                    add={false}
                    show={visualizar === true}
                    usuario={usuario}
                    grupos={grupos}
                    onSuccess={loadUsuario}
                    onHide={() => {
                        this.setState({ visualizar: null });
                        setTimeout(() => this.setState({ visualizar: false }), 500);
                    }} />
            )}
        </>);
    }

    render() {
        const { error } = this.state;

        return (
            <div>
                {this.renderText(false)}
                {this.renderBottom()}
                {error && (
                    <Alert message={error} onDismiss={() => this.setState({ error: null })} />
                )}
            </div>
        )
    }
}