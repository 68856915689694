import React, { Component } from 'react';

import '../../assets/scss/components/of.scss';
import colors from '../../assets/colors';
import IconButton from '../util/IconButton';
import Modal from 'react-bootstrap/Modal';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';
import Loader from '../loader';
import ofQualidadeTipoEnum from '../../util/enum/ofQualidadeTipoEnum';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Color from 'color';
import { ResponsiveBar } from '@nivo/bar';

export default class OfBocalModal extends Component {
    state = {
        itens: [],
        info: {},
        searching: true,
    }

    loadItens = async() => {
        try {
            const { of } = this.props;

            const result = (await api.get(`/of_item_qualidade?of=${of.numero}&tipo=${ofQualidadeTipoEnum.BOCAL}&info=true`)).data;
            
            const { itens = [], info = {} } = result;
            this.setState({ itens, info, searching: false });
        } catch (error) {
            this.setState({ searching: false })
        }
    }
    
    renderItens = () => {
        const { itens, searching } = this.state;
        if (!searching) {
            if (itens.length > 0) {
                let chartData = [{}, {}];
                const chartKeys = [];

                const itensRender = itens.map((item, index) => {
                    const { data, usuario, valor } = item;
                    let color = '';
                    if (!valor) {
                        color = 'red';
                    }
                    color = color ? `-${color}` : '';

                    if (!chartKeys.includes(usuario)) {
                        chartKeys.push(usuario);
                    }
                    const chartKey = valor ? 0 : 1;
                    if (!chartData[chartKey][usuario]) {
                        chartData[chartKey][usuario] = 0;
                    }
                    chartData[chartKey][usuario]++;

                    return (
                        <div key={index}>
                            <span>{new Date(data).toLocaleString()} ({usuario}):</span>
                            <span className={`of-itens-tempo-minutos${color}`}>{valor ? 'OK' : 'NÃO OK'}</span>
                        </div>
                    )
                });
                console.log(chartKeys);
                
                chartData = chartData.map((item, index) => {
                    return {
                        status: index === 0 ? 'OK' : 'Não OK',
                        ...item,
                    };
                });
                
                return (<>
                    <Row>
                        <Col>
                            {itensRender}
                        </Col>
                        <Col className="of-itens-chart">
                            <ResponsiveBar
                                data={chartData}
                                keys={chartKeys}
                                indexBy="status"
                                colors={bar => {
                                    const chartKeysLength = chartKeys.length;
                                    const step = (100 / chartKeysLength) > 25 ? 25 : (100 / chartKeysLength);
                                    const idIndex = chartKeys.indexOf(bar.id);
                                    let chartColor = [ colors.success, colors.danger ][bar.index];
                                    if (idIndex > 0) {
                                        const change = idIndex * step / 100;
                                        return Color(chartColor).lighten(change).hex();
                                    }
                                    return chartColor;
                                }}
                                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                padding={0.3}
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: true }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={null}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Quantidade',
                                    legendPosition: 'middle',
                                    legendOffset: -40
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={(bar) => {
                                    return Color(bar.color).isDark() ? 'white' : 'black';
                                }} />
                        </Col>
                    </Row>
                </>)
            }
            return <div>Nenhum item</div>
        }
        return (
            <div className="of-itens-loader">
                <Loader />
            </div>
        )
    }

    render() {
        const { of, show, onHide } = this.props;

        return (<>
            <Modal
                show={show}
                dialogClassName="modal-xl"
                onShow={this.loadItens}
                onHide={onHide} >
                <Modal.Header>
                    <Modal.Title>{`Verificações do bocal da OF ${of.numero}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderItens()}
                </Modal.Body>
                <Modal.Footer>
                    <IconButton text="Voltar" type="dark" icon={faArrowLeft} onClick={onHide} />
                </Modal.Footer>
            </Modal>
        </>)
    }
}