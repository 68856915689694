import { faCheck, faChevronLeft, faClipboardCheck, faEquals, faMapMarker, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import '../../assets/scss/estoque.scss';
import styles from '../../assets/scss/overflow.lazy.scss';
import colors from '../../assets/colors';
import api from '../../services/api';
import Loader from '../../components/loader';
import EstoqueBoxModal from '../../components/estoque/estoque_box_modal';

export default class EstoqueInventario extends Component {
    state = {
        estoque: [],
        boxes: null,
        clipboard: 0,
        equals: 0,
    };
    clickTimeout = null;

    async componentDidMount() {
        const estoque = (await api.get('/produto?order_by=2&itens=all&inventario=true')).data;
        console.log(estoque);
        if (typeof estoque === 'object' && estoque.length !== undefined) {
            this.setState({ estoque });
        }

        styles.use();
        window.onbeforeunload = function() {
            return true;
        }
    }

    componentWillUnmount() {
        styles.unuse();
        window.onbeforeunload = null;
    }

    render() {
        const { estoque, boxes, clipboard, equals } = this.state;

        if (estoque.length == 0) {
            return <Loader />
        }

        let inventario = estoque.filter(item => {
            let soma = 0;
            let itensChecked = true;
            let itensOk = true;
            item.inventario.forEach(item => {
                let { quantidade, reserva, boxes } = item;
                quantidade = parseInt(quantidade);
                soma += quantidade;
                boxes = boxes.reduce((total, next) => total + parseInt(next.quantidade || 0), 0);

                item.ok = (quantidade - parseInt(reserva)) == boxes;
                if (!item.ok) {
                    itensOk = false;
                }
                if (!item.checked) {
                    itensChecked = false;
                }
            });

            item.ok = soma == item.estoque;
            const ok = item.ok && itensOk;
            const checked = item.checked && itensChecked;

            return (
                (equals === 0 || (equals === 1 && !ok) || (equals === 2 && ok)) &&
                ((clipboard === 0 && (checked === undefined || equals !== 0)) || (clipboard === 1 && checked === false) || (clipboard === 2 && checked === true))
            );
        });

        inventario = inventario.map((item, index) => {
            const { codigo, nome, estoque, inventario, checked, ok } = item;
            const lotes = inventario.map((lote, index) => {
                let { lote: loteNumero, boxes, quantidade, reserva, checked, ok } = lote;
                quantidade = parseInt(quantidade);

                return (
                    <Row key={index.toString()} className="estoque-inventario-lote align-items-end justify-content-end text-center">
                        <Col md="auto" className="text-right">
                            Lote {loteNumero}
                        </Col>
                        <Col md={1}>
                            {quantidade}
                        </Col>
                        <Col md="auto">
                            <button className="estoque-inventario-button" onClick={() => {
                                let check = checked => {
                                    const { estoque } = this.state;
                                    const index2 = estoque.findIndex(item2 => item.id == item2.id);
                                    estoque[index2].inventario[index].checked = checked;
                                    let itemChecked = true;
                                    const all = estoque[index2].inventario.every(item => {
                                        if (item.checked === false) {
                                            itemChecked = false;
                                        }
                                        return item.checked !== undefined;
                                    });
                                    if (all) {
                                        estoque[index2].checked = itemChecked;
                                    }
                                    this.setState({ estoque })
                                };
                                if (this.clickTimeout !== null) {
                                    check(false)
                                    clearTimeout(this.clickTimeout)
                                    this.clickTimeout = null
                                } else {
                                    this.clickTimeout = setTimeout(()=>{
                                        check(true)
                                        clearTimeout(this.clickTimeout)
                                        this.clickTimeout = null
                                    }, 300)
                                }
                            }}>
                                <FontAwesomeIcon icon={checked === undefined ? faQuestion : faCheck} color={checked === true ? colors.success : (checked === false ? colors.danger : "#000")} />
                            </button>
                        </Col>
                        <Col md="auto">
                            <button className="estoque-inventario-button" onClick={() => {
                                this.setState({ boxes: {
                                    boxes: [ ...boxes ],
                                    produto: codigo,
                                    lote: loteNumero,
                                    quantidade: quantidade - reserva,
                                } })
                            }}>
                                <FontAwesomeIcon icon={faCheck} color={ok ? colors.success : colors.danger} />
                            </button>
                        </Col>
                    </Row>
                    
                )
            });

            return (
                <Row key={index.toString()} className="estoque-inventario-item">
                    <Col md={1}>
                        {codigo}
                    </Col>
                    <Col>
                        {nome}
                    </Col>
                    <Col md={1} className="text-center">
                        {estoque}
                    </Col>
                    <Col md="auto" className="text-center">
                        <button className="estoque-inventario-button" onClick={() => {
                                let check = checked => {
                                    const { estoque } = this.state;
                                    const index = estoque.findIndex(item2 => item.id == item2.id);
                                    estoque[index].checked = checked;
                                    estoque[index].inventario.forEach((item, index2) => {
                                        if (item.checked === undefined) {
                                            estoque[index].inventario[index2].checked = checked;
                                        }
                                    });
                                    this.setState({ estoque })
                                };
                                if (this.clickTimeout !== null) {
                                    check(false)
                                    clearTimeout(this.clickTimeout)
                                    this.clickTimeout = null
                                } else {
                                    this.clickTimeout = setTimeout(()=>{
                                        check(true)
                                        clearTimeout(this.clickTimeout)
                                        this.clickTimeout = null
                                    }, 300)
                                }
                            }}>
                            <FontAwesomeIcon icon={checked === undefined ? faQuestion : faCheck} color={checked === true ? colors.success : (checked === false ? colors.danger : "#000")} />
                        </button>
                    </Col>
                    <Col md="auto" className="text-center">
                        <FontAwesomeIcon icon={faCheck} color={ok ? colors.success : colors.danger} />
                    </Col>
                    <Col md={12}>
                        <div className="estoque-inventario-lotes">
                            {lotes}
                        </div>
                    </Col>
                </Row>
            )
        })

        return (<>
            <div className="estoque-inventario">
                <Row className="estoque-inventario-header">
                    <Col md={1}>
                        Código
                    </Col>
                    <Col>
                        Produto
                    </Col>
                    <Col md={1} className="text-center">
                        Estoque
                    </Col>
                    <Col md="auto" className="text-center">
                        <button className="estoque-inventario-button" onClick={() => {
                            let clipboardNew = clipboard + 1;
                            if (clipboardNew > 2) {
                                clipboardNew = 0;
                            }
                            this.setState({ clipboard: clipboardNew });
                        }}>
                            <FontAwesomeIcon icon={faClipboardCheck} color={['#000', colors.danger, colors.success][clipboard]} />
                        </button>
                    </Col>
                    <Col md="auto" className="text-center">
                        <button className="estoque-inventario-button" onClick={() => {
                            let equalsNew = equals + 1;
                            if (equalsNew > 2) {
                                equalsNew = 0;
                            }
                            this.setState({ equals: equalsNew });
                        }}>
                            <FontAwesomeIcon icon={faEquals} color={['#000', colors.danger, colors.success][equals]} />
                        </button>
                    </Col>
                </Row>
                <div className="estoque-inventario-table">
                    {inventario.length > 0 && inventario}
                    {inventario.length == 0 && (
                        <Row className="estoque-inventario-item">
                            <Col className="text-center">Sem registros</Col>
                        </Row>
                    )}
                </div>
            </div>

            {boxes !== null && (
                <EstoqueBoxModal
                    show={Boolean(boxes)}
                    produto={(boxes || {}).produto}
                    lote={(boxes || {}).lote}
                    quantidade={(boxes || {}).quantidade}
                    boxes={(boxes || {}).boxes}
                    onSucess={newBoxes => {
                        const estoque = this.state;
                        estoque.some(({ codigo, inventario }, i1) => {
                            if (codigo == boxes.produto) {
                                inventario.some(({ lote }, i2) => {
                                    if (lote == boxes.lote) {
                                        estoque[i1].inventario[i2].boxes = newBoxes;
                                        return true;
                                    }
                                })
                                return true;
                            }
                        });
                        this.setState({ estoque });
                    }}
                    onHide={() => {
                        this.setState({ boxes: false });
                        setTimeout(() => this.setState({ boxes: null }), 250);
                    }} />
            )}
        </>);
    }
}