import React, { Component } from 'react';

import '../../assets/scss/components/pedido.scss';
import { faCheck, faChevronLeft, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import Modal from 'react-bootstrap/Modal';
import CustomInput from '../util/CustomInput';
import api from '../../services/api';
import CidadeChooseModal from '../cidade/cidade_choose_modal';

export default class ClienteModal extends Component {
    state = {
        show: true,
        escolherCidades: null,
        confirmarExcluir: false,
        cliente: {
            codigo: null,
            nome: null,
            cidade: null,
        },
    }

    static renderModal = ({ show, text, onHide, onSuccess }) => {
        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Body className="cliente-modal-title">
                    {text}
                </Modal.Body>
                <Modal.Footer className="cliente-modal-confirm-footer">
                    <IconButton text="Sim" icon={faCheck} onClick={onSuccess} />
                    <IconButton text="Não" icon={faTimes} onClick={onHide} />
                </Modal.Footer>
            </Modal>
        );
    }

    hideSuccess = async() => {
        const { onHide, onSuccess } = this.props;
        await onHide();
        await onSuccess();
    }

    delete = async() => {
        const { excluir } = this.props;
        const { confirmarExcluir } = this.state;
        if (!confirmarExcluir) {
            this.setState({ confirmarExcluir: true });
            return;
        }
        this.setState({ confirmarExcluir: false, show: false });
        
        if (await excluir(this.props.cliente.codigo)) {
            this.hideSuccess();
        }
    }

    save = async() => {
        const { cliente } = this.state;
        const result = (await api.put('/cliente', cliente)).data;

        const { error, warning } = result;

        if (error === 0) {
            this.hideSuccess();
        } else {
            switch (warning) {
                case 102:
                    const { cidade: escolherCidades } = result;
                    if (escolherCidades !== undefined) {
                        this.setState({ escolherCidades, show: false });
                    }
                    break;

                default:
                    switch (error) {
                        case 103:
                            this.setState({ error: "A cidade não existe", addCliente: true })
                            break;
                    
                        default:
                            this.setState({ error: "Aconteceu um erro" })
                            break;
                    }
            }
        }
        console.log(result);
    }

    saveState = (event) => {
        const { name, value } = event.target;
        const { cliente } = this.state;
        cliente[name] = value;
        this.setState({ cliente });
    }

    renderCustomInput = (params) => {
        if (!params.onChange) {
            params.onChange = this.saveState;
        }
        return (
            <CustomInput {...params} />
        )
    }

    render() {
        const { show, excluir, reativar, onHide } = this.props;
        const { show: stateShow, confirmarExcluir, escolherCidades, cliente: { codigo, nome, cidade } } = this.state;

        return (<>
            <Modal
                show={show && stateShow && !confirmarExcluir}
                dialogClassName="modal-md"
                onShow={() => {
                    const { cliente } = this.state;
                    if (!cliente.codigo && !cliente.nome && !cliente.cidade) {
                        let { cliente: { codigo, nome, cidade } } = this.props;
                        if (typeof cidade === 'object') {
                            cidade = `${cidade.nome} / ${cidade.uf.sigla}`;
                        }
                        this.setState({ cliente: { codigo, nome, cidade } });
                    }
                }}
                onHide={onHide} >
                <Modal.Header>
                    <Modal.Title>Editar cliente código {codigo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md">
                            <div className="row">
                                <div className="col-md">
                                    {this.renderCustomInput({
                                        label: "Nome",
                                        name: "nome",
                                        type: "text",
                                        defaultValue: nome,
                                    })}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    {this.renderCustomInput({
                                        label: "Cidade",
                                        name: "cidade",
                                        type: "text",
                                        defaultValue: typeof cidade !== 'object' ? cidade : null
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton text="Salvar" icon={faSave} onClick={this.save} />
                    {reativar && <IconButton type="success" text="Reativar" icon={faCheck} onClick={async() => {
                        if (await reativar(this.props.cliente.codigo)) {
                            this.hideSuccess();
                        }
                    }} />}
                    {excluir && <IconButton type="danger" text="Excluir" icon={faTrash} onClick={this.delete} />}
                    <IconButton text="Voltar" type="dark" icon={faChevronLeft} onClick={onHide} />
                </Modal.Footer>
            </Modal>

            {ClienteModal.renderModal({
                text: `Deseja realmente excluir o cliente ${this.props.cliente.codigo}?`,
                show: confirmarExcluir !== false,
                onSuccess: this.delete,
                onHide: () => this.setState({ confirmarExcluir: false }),
            })}

            <CidadeChooseModal
                cidades={escolherCidades}
                show={escolherCidades !== null}
                onHide={() => this.setState({ show: true, escolherCidades: null })}
                onSuccess={async(cidade) => {
                    const { cliente } = this.state;
                    cliente.cidade = cidade.codigo;
                    this.setState({ cliente });
                }} />
        </>)
    }
}