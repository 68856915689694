import { faBarcode, faBoxes, faCashRegister, faQrcode } from '@fortawesome/free-solid-svg-icons';
import utilEnum from './enum';

const values = {
    NFC: 1,
    NF: 2,
    ATENDIMENTO: 4,
    PEDIDO: 3,
};

const properties = {
    1: { name: 'NFC', value: 1, text: 'NFC-e', icon: faQrcode, description: 'Venda feita com cupom fiscal<br />Você informa as quantidades e lotes', lowerCase: false },
    2: { name: 'NF', value: 2, text: 'NF-e', icon: faBarcode, description: 'Venda feita com nota fiscal eletrônica<br />Você informa as quantidades e lotes', lowerCase: false },
    4: { name: 'ATENDIMENTO', value: 4, text: 'Atendimento', icon: faCashRegister, description: 'Quando o cliente deseja carregar antes de emitir a nota fiscal<br />Você informa os itens e a expedição informa a quantidade e os lotes, bem como adiciona novos itens' },
    3: { name: 'PEDIDO', value: 3, text: 'Pedido', icon: faBoxes, description: 'Quando o cliente deseja reservar uma encomenda<br />Você informa os itens e a quantidade e a expedição informa os lotes' },
};

const isVenda = tipo => {
    return utilEnum.isEnum(tipo, [values.NFC, values.NF]);
}

const isPedido = tipo => {
    return utilEnum.isEnum(tipo, [values.PEDIDO, values.ATENDIMENTO]);
}

const getText = tipo => {
    return properties[tipo].text;
}

const getTextArtigo = (tipo, lowercase = true, uppercase = false) => {
    let artigo = '';
    switch (tipo) {
        case values.ATENDIMENTO:
        case values.PEDIDO:
            artigo = 'o';
            break;

        case values.NF:
        case values.NFC:
            artigo = 'a';
            break;
    }

    if (uppercase) {
        artigo.toUpperCase();
    }

    let { text, lowerCase } = properties[tipo];
    if (lowercase && lowerCase !== false) {
        text = text.toLowerCase();
    }

    return `${artigo ? `${artigo} ` : ''}${text}`;
}

const getByProp = (value, prop, strict = false) => {
    for (const tipo in properties) {
        const property = properties[tipo];
        if (strict) {
            if (property[prop] === value) {
                return tipo;
            }
        } else if (property[prop] == value) {
            return tipo;
        }
    }
    return null;
}

export default {
    ...values,
    properties,
    getText,
    getTextArtigo,
    getByProp,
    isVenda,
    isPedido,
    getSelectOptions: () => {
        return utilEnum.getSelectOptions(properties);
    }
};