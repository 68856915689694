import React, { Component } from 'react';

import '../../assets/scss/components/pedido.scss';
import { faChevronLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import Modal from 'react-bootstrap/Modal';
import CustomInput from '../util/CustomInput';

export default class ClienteAddModal extends Component {
    state = {
        codigo: null,
        nome: null,
        cidade: null,
    }

    hideSuccess = async() => {
        const { onHide, onSuccess } = this.props;
        const result = await onSuccess(this.state);
        if (result) {
            await onHide(true);
        }
    }

    saveState = (event) => {
        const { name, value } = event.target;

        this.setState({ [name]: value });
    }

    renderCustomInput = (params) => {
        if (!params.onChange) {
            params.onChange = this.saveState;
        }
        return (
            <CustomInput {...params} />
        )
    }

    componentDidMount() {
        const { nextCodigo: codigo } = this.props;
        this.setState({ codigo })
    }

    render() {
        const { show, nextCodigo, onHide } = this.props;
        let cliente = this.state || {};
        if (!cliente.codigo) {
            ({ cliente } = this.props);
            cliente = cliente || {};
            console.log(cliente)
            if (typeof cliente !== 'object') {
                cliente = { codigo: cliente };
            }
        }

        const { codigo, nome, cidade } = cliente;

        return (<>
            <Modal
                show={show}
                dialogClassName="modal-md"
                onHide={() => onHide(false)} >
                <Modal.Header>
                    <Modal.Title>Adicionar cliente{codigo ? ` código ${codigo}` : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md">
                            {!((typeof this.props.cliente === 'object' && (this.props.cliente || {}).codigo) || this.props.cliente) && (
                                <div className="row">
                                    <div className="col-md">
                                        {this.renderCustomInput({
                                            label: "Código",
                                            name: "codigo",
                                            type: "number",
                                            defaultValue: codigo || nextCodigo,
                                        })}
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col-md">
                                    {this.renderCustomInput({
                                        label: "Nome",
                                        name: "nome",
                                        type: "text",
                                        defaultValue: nome,
                                    })}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    {this.renderCustomInput({
                                        label: "Cidade",
                                        name: "cidade",
                                        type: "text",
                                        defaultValue: typeof cidade !== 'object' ? cidade : null
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton text="Salvar" icon={faSave} onClick={this.hideSuccess} />
                    <IconButton text="Voltar" type="dark" icon={faChevronLeft} onClick={() => onHide(false)} />
                </Modal.Footer>
            </Modal>
        </>)
    }
}