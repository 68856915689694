import React, { Component } from 'react';
import api from '../../services/api';

import '../../assets/scss/venda.scss';
import Loader from '../../components/loader';
import Venda from '../../components/venda/venda';
import vendaStatusEnum from '../../util/enum/vendaStatusEnum';
import CustomInput from '../../components/util/CustomInput';
import produtoGrupoEnum from '../../util/enum/produtoGrupoEnum';
import vendaTipoEnum from '../../util/enum/vendaTipoEnum';

export default class VendaArquivo extends Component {
    state = {
        vendas: [],
        page: 1,
        searching: false,
        produtos: null,
        numero: null,
        tipo: null,
        cliente: null,
        produto: null,
    }

    filterVenda = async state => {
        await this.setState({
            ...state,
            page: 1,
            vendas: [],
            searching: true,
        })
        await this.loadVendas();
    }

    loadVendas = async() => {
        try {
            let { page, numero, tipo, cliente, produto, vendas } = this.state;
            this.setState({ searching: true })
            
            numero = numero ? `&numero=${numero}` : '';
            tipo = tipo ? `&tipo=${tipo}` : '';
            cliente = cliente ? `&cliente=${cliente}` : '';
            produto = produto ? `&produto=${produto}` : '';
            const result = (await api.get(`/venda?order_by=2&status=${vendaStatusEnum.ENTREGUE}&itens=12&pagina=${page}&order_by=2${numero}${tipo}${cliente}${produto}`)).data;
            if (result && result.length > 0) {
                vendas = [...vendas, ...result];
                
                document.addEventListener('scroll', this.trackScrolling);
            }
            this.setState({ vendas, searching: false })
        } catch (error) {
            console.log(error);
        }
    }
    
    trackScrolling = () => {
        const element = document.getElementsByClassName('vendamain-vendas')[0];
        if (element.getBoundingClientRect().bottom <= window.innerHeight) {
            this.setState({ page: this.state.page + 1 });
            this.loadVendas();
            document.removeEventListener('scroll', this.trackScrolling);
        }
    }

    componentDidMount() {
        this.loadVendas();
        api.get(`/produto?itens=all&grupo=${produtoGrupoEnum.PRODUTO_FINAL}`).then((result) => {
            const produtos = result.data.map(produto => ({ value: produto.codigo, label: produto.nome }));
            this.setState({ produtos });
        });
        document.addEventListener('scroll', this.trackScrolling);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }

    renderVendas = () => {
        const { vendas, searching } = this.state;
        if (vendas.length > 0) {
            return vendas.map(venda => {
                const { numero } = venda;
                return (
                    <div key={`venda-${numero}`} className="vendamain-venda">
                        <div className="vendamain-venda-container">
                            <Venda venda={venda} loadVendas={this.loadVendas} />
                        </div>
                    </div>
                )
            });
        }
        if (!searching) {
            return (
                <div className="vendamain-baixar-title">Nenhuma venda finalizada</div>
            );
        }
        return null;
    }

    render() {
        const { searching, produtos } = this.state;

        return (<>
            <div className="vendamain-filtro">
                <div className="row">
                    <div className="col-md-3">
                        <CustomInput
                            label="Filtre por número"
                            type="number"
                            onChange={async event => {
                                const { target: { value: numero } } = event;
                                if (this.numeroTimeout) {
                                    clearTimeout(this.numeroTimeout);
                                }
                                this.setState({ vendas: [], searching: true })
                                this.numeroTimeout = setTimeout(() => {
                                    this.filterVenda({ numero })
                                }, 1500);
                            }}
                            />
                    </div>
                    <div className="col-md-3">
                        <CustomInput
                            label="Filtre por tipo"
                            type="select"
                            options={vendaTipoEnum.getSelectOptions()}
                            isClearable={true}
                            onChange={async event => {
                                const { target: { value: tipo } } = event;
                                this.filterVenda({ tipo });
                            }}
                            />
                    </div>
                    <div className="col-md-3">
                        <CustomInput
                            label="Filtre por cliente"
                            onChange={async event => {
                                const { target: { value: cliente } } = event;
                                if (this.clienteTimeout) {
                                    clearTimeout(this.clienteTimeout);
                                }
                                this.setState({ vendas: [], searching: true })
                                this.clienteTimeout = setTimeout(() => {
                                    this.filterVenda({ cliente })
                                }, 1500);
                            }}
                            />
                    </div>
                    <div className="col-md-3">
                        <CustomInput
                            label="Filtre por produto"
                            type="select"
                            options={produtos}
                            isClearable={true}
                            onChange={async event => {
                                const { target: { value: produto } } = event;
                                this.filterVenda({ produto });
                            }}
                            />
                    </div>
                </div>
            </div>
            <div className="vendamain-vendas">
                {this.renderVendas()}
            </div>
            {searching && (
                <div className="vendamain-loader">
                    <Loader />
                </div>
            )}
        </>);
    }
}