import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { load } from 'react-cookies';
import api from '../../services/api';
import Modal from 'react-bootstrap/Modal';

import '../../assets/scss/usuario.scss';
import Loader from '../../components/loader';
import UsuarioItem from '../../components/usuario/usuario';
import IconButton from '../../components/util/IconButton';
import { faIdBadge, faPaperPlane, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import UsuarioModal from '../../components/usuario/usuario_modal';

export default class Usuario extends Component {
    state = {
        administrador: false,
        usuarios: [],
        page: 1,
        searching: false,
        adicionar: false,
        grupos: null,
    }

    loadUsuario = async(usuario = null) => {
        try {
            if (!usuario) {
                this.setState({ usuarios: [], baixar: [], searching: true })
    
                const usuarios = (await api.get(`/usuario?itens=all`)).data;
        
                this.setState({ usuarios, searching: false })
            } else {
                const { usuarios } = this.state;
                const excluir = typeof usuario !== 'object';

                let alterou = usuarios.findIndex(item => {
                    return (excluir ? usuario : usuario.codigo) == item.codigo
                });
                if (alterou >= 0) {
                    delete usuarios[alterou];
                    setTimeout(() => {
                        if (excluir) {
                            usuarios.splice(alterou, 1)
                        } else {
                            usuarios[alterou] = usuario;
                        }
                        this.setState({ usuarios });
                    }, 450)
                } else {
                    usuarios.push(usuario);
                }
                this.setState({ usuarios });
            }
        } catch (error) {
            console.log(error);
        }
    }

    async componentDidMount() {
        const usuario = load('CENTROPLAST_INTRANET_USUARIO');
        if (!usuario.administrador) {
            return;
        }
        this.setState({ administrador: true })

        this.loadUsuario();

        api.get(`/usuario_grupo?itens=all`).then((result) => {
            const grupos = result.data.map(grupo => ({ value: grupo.id, label: grupo.nome }));
            this.setState({ grupos });
        });
    }

    renderUsuarios = () => {
        const { usuarios, grupos, searching } = this.state;

        if (usuarios.length > 0) {
            return (
                <TransitionGroup className="usuariomain-usuarios">
                    {usuarios.map((usuario, index) => {
                        return (
                            <CSSTransition
                                key={index}
                                classNames="usuariomain-usuarios-transition"
                                timeout={500}>
                                <div className="usuariomain-usuario">
                                    <div className="usuariomain-usuario-container">
                                        <UsuarioItem
                                            grupos={grupos}
                                            loadUsuario={this.loadUsuario}
                                            usuario={usuario} />
                                    </div>
                                </div>
                            </CSSTransition>
                        )
                    })}
                </TransitionGroup>
            );
        }
        if (searching) {
            return (
                <div className="usuariomain-loader">
                    <Loader />
                </div>
            )
        }
        return (
            <div>
                Sem usuários
            </div>
        )
    }

    render() {
        const { administrador, adicionar, grupos } = this.state;

        if (administrador) {
            return (<>
                <div className="usuariomain-header">
                    <IconButton className="usuariomain-header-btn" icon={faPlus} text="Adicionar" onClick={() => this.setState({ adicionar: true })} />
                    <IconButton className="usuariomain-header-btn" icon={faIdBadge} text="Grupos de usuários" link="/usuario/grupo" />
                    <IconButton className="usuariomain-header-btn" icon={faPaperPlane} text="Enviar mensagem" link="/usuario/mensagem" />
                </div>
                {this.renderUsuarios()}
                <UsuarioModal
                    show={adicionar}
                    grupos={grupos}
                    onSuccess={this.loadUsuario}
                    onHide={() => this.setState({ adicionar: false })} />
            </>);
        }
        const onHide = () => window.location.href = '/';
        return (
            <Modal dialogClassName='modal-md' show={true} onHide={onHide}>
                <Modal.Body className="usuario-modal-title">Você não tem permissão para acessar essa página</Modal.Body>
                <Modal.Footer className="usuario-modal-confirm-footer">
                    <IconButton text="Fechar" icon={faTimes} onClick={onHide} />
                </Modal.Footer>
            </Modal>
        );
    }
}