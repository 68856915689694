import { faArrowLeft, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link, Route, withRouter } from "react-router-dom";
import Header from "./components/header";
import cookie from 'react-cookies';

import titles, {
    Expedicao,
    ExpedicaoArquivo,
    ExpedicaoEmbarque,
    ExpedicaoEnviado,
    Inicio,
    Producao,
    ProducaoArquivo,
    ProducaoBaixar,
    Sair,
    MeuUsuario,
    Usuario,
    Venda,
    VendaArquivo,
    VendaPreparada,
    UsuarioGrupo,
    UsuarioMensagem,
    Login,
    Estoque,
    Notificacao,
    Block,
    EstoqueDeposito,
    EstoqueInventario,
    Produto,
    Cliente,
    Pedido,
    Tarefa
} from "./pages";
import api from "./services/api";
import IconButton from "./components/util/IconButton";

class Routes extends Component {
    state = {
        login: null,
    }

    pages = {
        
    }

    getLocationTitle = location => {
        if (location) {
            location = location.split('/');
            let title = titles[location[0]];
            if (title) {
                let i = 0;
                while (true) {
                    let newTitle = null;
                    if (title.sub) {
                        newTitle = title.sub[location[++i]];
                    }
                    if (newTitle) {
                        title = newTitle;
                    } else {
                        break;
                    }
                }
                return title;
            }
        }
    }

    getDocumentTitle = location => {
        const title = this.getLocationTitle(location) || {};

        return title.title ? `${title.title} | ` : '';
    }
    
    getPageTitle = location => {
        const title = this.getLocationTitle(location);
        if (title) {
            const page = location.split('/').pop();
            const { count, subtitle, goBack } = this.state;
            let backButton = null;
            if (title.back) {
                if (goBack === false) {
                    backButton = (
                        <button className="page-title-linkback" onClick={() => this.pages[page].goBack()}>
                            <FontAwesomeIcon icon={faArrowLeft} className="page-title-icon" />
                        </button>
                    )
                } else {
                    backButton = (
                        <Link to={title.back} className="page-title-linkback">
                            <FontAwesomeIcon icon={faArrowLeft} className="page-title-icon" />
                        </Link>
                    )
                }
            }

            return (<>
                <div className="page-title">
                    {backButton}
                    <div className="page-title-text">
                        {title.text || title.title}
                        {title.count && (
                            <span>{count ? ` | ${count}` : ''}</span>
                        )}
                        {title.subtitle && (
                            <span>{subtitle ? ` | ${subtitle}` : ''}</span>
                        )}
                    </div>
                    {title.refresh && (
                        <IconButton className="page-title-refresh" icon={faRedoAlt} type="transparent" onClick={() => this.pages[page].refresh()} />
                    )}
                </div>
            </>);
        }
        return null;
    }

    async componentDidMount() {
        let login = cookie.load('CENTROPLAST_INTRANET_USUARIO');
        if (login) {
            const { permanecerConectado } = login;
            if (permanecerConectado) {
                const response = (await api.post('/login', { permanecerConectado })).data;

                login = response.error === 0;
                if (!login) {
                    cookie.remove('CENTROPLAST_INTRANET_USUARIO');
                }
                this.setState({ login });
            }
        } else {
            login = false;
        }
        this.setState({ login });

        this.props.history.listen(() => this.setState({ goBack: null }))
        setTimeout(async() => {
            const location = this.props.location.pathname.substring(1);
            const page = location.split('/').pop();
            const title = this.getLocationTitle(location);
            if (title && title.count && this.pages[page] !== undefined) {
                const count = await this.pages[page].getCount();
                this.setState({ count });
            }
        }, 500);
    }
    
	render() {
        const { login } = this.state;
        const usuario = cookie.load('CENTROPLAST_INTRANET_USUARIO');
        const block = cookie.load('CENTROPLAST_INTRANET_BLOCK') === '1';
        const location = this.props.location.pathname.substring(1);

        if (block && usuario && !usuario.suporte) {
            return <Block usuario={usuario} />;
        }
        if (login === null) {
            return null;
        }
        if (!login) {
            return <Login />;
        }
		return (<>
            <Route path="/sair">
                <Sair />
            </Route>
            <Route path="/block">
                <Block usuario={usuario} />
            </Route>
            <Route path="/">
                <Helmet>
                    <title>{this.getDocumentTitle(location)}Intranet Centroplast</title>
                </Helmet>
                {location !== 'sair' && <Header location={location} />}
                <section className="section">
                    {this.getPageTitle(location)}
                    
                    <Route exact={true} path="/">
                        <Inicio />
                    </Route>

                    <Route exact={true} path="/usuario">
                        <Usuario />
                    </Route>
                    <Route path="/meu_usuario">
                        <MeuUsuario />
                    </Route>
                    <Route path="/notificacao">
                        <Notificacao />
                    </Route>
                    <Route path="/usuario/grupo">
                        <UsuarioGrupo />
                    </Route>
                    <Route path="/usuario/mensagem">
                        <UsuarioMensagem />
                    </Route>

                    <Route exact={true} path="/cliente">
                        <Cliente />
                    </Route>

                    <Route exact={true} path="/estoque">
                        <Estoque ref={ref => this.pages.estoque = ref} />
                    </Route>
                    <Route path="/estoque/deposito">
                        <EstoqueDeposito onChangeGoBack={goBack => this.setState({ goBack })} onChangeSubtitle={subtitle => this.setState({ subtitle })} ref={ref => this.pages.deposito = ref} />
                    </Route>
                    <Route path="/estoque/inventario">
                        <EstoqueInventario />
                    </Route>

                    <Route exact={true} path="/pedido">
                        <Pedido />
                    </Route>

                    <Route exact={true} path="/expedicao">
                        <Expedicao onChangeCount={count => this.setState({ count })} ref={ref => this.pages.expedicao = ref} />
                    </Route>
                    <Route path="/expedicao/enviados">
                        <ExpedicaoEnviado />
                    </Route>
                    <Route path="/expedicao/embarque">
                        <ExpedicaoEmbarque />
                    </Route>
                    <Route path="/expedicao/arquivo">
                        <ExpedicaoArquivo />
                    </Route>

                    <Route exact={true} path="/producao">
                        <Producao ref={ref => this.pages.producao = ref} />
                    </Route>
                    <Route path="/producao/baixar">
                        <ProducaoBaixar />
                    </Route>
                    <Route path="/producao/arquivo">
                        <ProducaoArquivo />
                    </Route>

                    <Route exact={true} path="/produto">
                        <Produto />
                    </Route>

                    <Route exact={true} path="/tarefa">
                        <Tarefa ref={ref => this.pages.tarefa = ref} />
                    </Route>

                    <Route exact={true} path="/venda">
                        <Venda />
                    </Route>
                    <Route path="/venda/preparados">
                        <VendaPreparada />
                    </Route>
                    <Route path="/venda/arquivo">
                        <VendaArquivo />
                    </Route>
                </section>
            </Route>
		</>);
	}
}

export default withRouter(Routes);