import React, { Component } from 'react';

import '../../assets/scss/components/pedido.scss';
import { faCheck, faChevronLeft, faFileInvoiceDollar, faPlus, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../util/IconButton';
import Modal from 'react-bootstrap/Modal';
import CustomInput from '../util/CustomInput';
import Alert from '../util/Alert';
import api from '../../services/api';
import pedidoStatusEnum from '../../util/enum/pedidoStatusEnum';
import pagamentoFormaEnum from '../../util/enum/pagamentoFormaEnum';
import bancoEnum from '../../util/enum/bancoEnum';
import transportadoraEnum from '../../util/enum/transportadoraEnum';
import ClienteAddModal from '../cliente/cliente_add_modal';
import CidadeChooseModal from '../cidade/cidade_choose_modal';
import ClienteSearchModal from '../cliente/cliente_search_modal';
import PedidoItem from './pedido_item';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class PedidoModal extends Component {
    constructor(props) {
        super(props);
        [
            'hideSuccess',
            'emitirNotaFiscal',
            'delete',
            'verificarVolumes',
            'aprovar',
            'saveNotaFiscal',
            'save',
            'saveState',
            'saveStateItens',
            'getItemNextId',
            'addItem',
            'renderCustomInput',
            'renderItensCustomInput',
            'renderSelectReadonly',
        ].forEach(funcao => {
            this[funcao] = this[funcao].bind(this);
        });
    }

    state = {
        error: null,
        errorOnDismiss: null,
        show: true,
        addCliente: false,
        escolherCidades: null,
        confirmarExcluir: false,
        confirmarVoltar: false,
        notaFiscal: false,
        info: {},
        pedido: {
            numero: null,
            cliente: null,
            volumes: null,
            valor: null,
            formaPagamento: null,
            banco: null,
            temAvisoTransportador: '0',
            temCertificado: '0',
            temGuiaIcms: '0',
            observacoes: null,
            itens: [],
        },
    }

    static renderModal({ show, size, text, footer, onHide, onCancel, onSuccess }) {
        return (
            <Modal dialogClassName={size || 'modal-md'} show={show} onHide={onHide}>
                <Modal.Body className="pedido-modal-title">
                    {text}
                </Modal.Body>
                <Modal.Footer className="pedido-modal-confirm-footer">
                    {footer || (<>
                        <IconButton text="Sim" icon={faCheck} onClick={onSuccess} />
                        <IconButton text="Não" icon={faTimes} onClick={onCancel || onHide} />
                    </>)}
                </Modal.Footer>
            </Modal>
        );
    }

    async hideSuccess(pedido) {
        const { onHide, onSuccess } = this.props;
        await onHide();
        await onSuccess(pedido);
    }

    async emitirNotaFiscal() {
        let { notaFiscal } = this.state;
        
        if (notaFiscal) {
            const { pedido: { numero }, nextNotaFiscal } = this.props;

            if (notaFiscal === true) {
                notaFiscal = nextNotaFiscal;
            }

            try {
                const result = (await api.put(`/pedido`, {
                    numero,
                    notaFiscal,
                    emitirNotaFiscal: true,
                    react: true,
                })).data;
                console.log(result);
                
                if (result.error === 0) {
                    this.setState({ confirmVoltar: false });
                    this.hideSuccess(this.props.pedido);
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            this.setState({ notaFiscal: true, show: false })
        }
    }

    async delete() {
        const { confirmarExcluir } = this.state;
        if (!confirmarExcluir) {
            this.setState({ confirmarExcluir: true });
            return;
        }
        this.setState({ confirmarExcluir: false, show: false });
        
        const { pedido: { numero }, pendente } = this.props;

        try {
            const result = (await api.delete(`/${pendente ? 'pedido_pendente' : 'pedido'}/${numero}`)).data;

            if (result.error === 0) {
                this.hideSuccess(numero);
            }
        } catch (error) {
            console.log(error);
        }
    }

    verificarVolumes(itens, volumes) {
        const volumesSoma = itens.reduce((total, item) => total + parseInt(item.volumes || 0), 0);
        let diferenca = parseInt(volumes) - volumesSoma;
        
        if (diferenca != 0) {
            const faltandoTexto = diferenca > 0 ? 'faltando' : 'sobrando';
            diferenca = diferenca < 0 ? diferenca * -1 : diferenca;
            const itensTexto = diferenca > 1 ? `Estão ${faltandoTexto} ${diferenca} itens` : `Está ${faltandoTexto} ${diferenca} item`;

            this.setState({ error: (<>
                <Row>
                    <Col>
                        A soma dos volumes dos itens com os volumes informados no pedido não coincidem
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {itensTexto}
                    </Col>
                </Row>
            </>) });
            return false;
        }
        return true;
    }

    async aprovar(tipo) {
        const { pedido } = this.state;
        const { volumes, itens } = pedido;

        if (!this.verificarVolumes(itens, volumes)) {
            return;
        }

        try {
            const result = (await api.put('/pedido_pendente', { ... pedido, tipo })).data;
            console.log(result);

            const { error, warning } = result;

            if (error === 0) {
                if (warning === 1) {
                    this.setState({ show: false, error: "O pedido já foi aprovado", errorOnDismiss: () => this.hideSuccess(pedido.numero) })
                } else {
                    this.hideSuccess(pedido.numero);
                }
            } else {
                switch (error) {
                    default:
                        this.setState({ error: "Aconteceu um erro" })
                        break;
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    async saveNotaFiscal() {
        const { pedido: { numero, notaFiscal } } = this.state;

        const result = (await api.put('/pedido', { react: true, numero, notaFiscal, changeNotaFiscal: true })).data;
        if (result.error === 0) {
            this.hideSuccess()
        }
    }

    async save() {
        const { add = true, nextPedido } = this.props;
        const { pedido } = this.state;
        const { volumes, itens } = pedido;
        if (!pedido.numero) {
            pedido.numero = nextPedido;
        }

        if (!add && itens.length > 0 && !this.verificarVolumes(itens, volumes)) {
            return;
        }

        try {
            pedido.react = true;
            const result = (await (add ? api.post : api.put)('/pedido', pedido)).data;
            console.log(result);

            const { error, warning, pedido: pedidoResult } = result;

            if (error === 0) {
                await this.setState({ show: true });
                setTimeout(async() => this.hideSuccess(pedidoResult || (await api.get(`/pedido/${pedido.numero}`)).data), 250);
            } else {
                let show = true;
                switch (warning) {
                    case 101:
                        if (result.cliente !== undefined) {
                            show = false;
                            this.setState({ addCliente: true });
                        }
                        break;
                        
                    case 102:
                        const { cidade: escolherCidades } = result;
                        if (escolherCidades !== undefined) {
                            show = false;
                            this.setState({ escolherCidades });
                        }
                        break;

                    default:
                        switch (error) {
                            case 103:
                                show = false;
                                this.setState({ error: "A cidade não existe", addCliente: true })
                                break;
                        
                            default:
                                if (add) {
                                    switch (error) {
                                        case 4:
                                            this.setState({ error: "Este número já existe em outro pedido" })
                                            break;
                
                                        default:
                                            this.setState({ error: "Aconteceu um erro" })
                                            break;
                                    }
                                } else {
                                    switch (error) {
                                        default:
                                            this.setState({ error: "Aconteceu um erro" })
                                            break;
                                    }
                                }
                                break;
                        }
                }
                this.setState({ show });
            }
        } catch (error) {
            console.log(error);
        }
    }

    saveState(event) {
        const { id, name, value, checked } = event.target;
        const checkbox = !name && id && value == 'on' && checked !== undefined;

        const { pedido } = this.state;
        pedido[checkbox ? id : name] = checkbox ? (checked ? '1' : '0') : value;

        this.setState({ pedido });
    }

    saveStateItens(event) {
        let { name, value } = event.target;
        const { pedido } = this.state;
        const { itens } = pedido;

        const nameSplit = name.split('-');
        name = nameSplit[2];
        const id = nameSplit[1];
        const index = itens.findIndex(item => item.id == id);

        itens[index][name] = value;

        this.setState({ pedido });
    }

    getItemNextId() {
        const { pedido: { itens } } = this.state;
        let ids = itens.map(item => parseInt(item.id));
        ids.sort((a, b) => a - b);
        ids.reverse();
        return (ids[0] || 0) + 1;
    }

    addItem() {
        const { pedido } = this.state;
        pedido.itens.push({
            id: this.getItemNextId(),
            produto: {},
            quantidade: null,
            volumes: null,
        });
        this.setState({ pedido });
    }

    componentDidMount() {
        const { pedido: prop } = this.props;
        if (prop) {
            let {
                cliente,
                numero,
                itens = [],
                volumes,
                valor,
                formaPagamento,
                banco,
                transportadora,
                temAvisoTransportador,
                temCertificado,
                temGuiaIcms,
                observacoes,
            } = prop;
            
            if (!temAvisoTransportador) {
                temAvisoTransportador = '0';
            }
            if (!temCertificado) {
                temCertificado = '0';
            }
            if (!temGuiaIcms) {
                temGuiaIcms = '0';
            }
            
            const pedido = {
                cliente,
                numero,
                itens,
                volumes,
                valor,
                formaPagamento,
                banco,
                transportadora,
                temAvisoTransportador,
                temCertificado,
                temGuiaIcms,
                observacoes,
            };
            this.setState({
                pedido,
            });
        }
    }

    renderCustomInput({ defaultValueName, ...params }, baseValues = null) {
        if (!baseValues) {
            let { pedido } = this.props;
            if (!pedido) {
                ({ pedido } = this.state);
            }
            baseValues = pedido;
        }
        let { id, name, type } = params;
        if (!name && id) {
            name = id;
        }

        if (!type) {
            type = 'number';
            params.type = type;
        }
        if (type === 'number') {
            if (!params.min) {
                params.min = 0;
            }
        }
        if (!params.onChange) {
            params.onChange = this.saveState;
        }

        defaultValueName = defaultValueName || name;
        if (params.value === undefined && defaultValueName && baseValues[defaultValueName]) {
            let value = baseValues[defaultValueName];
            if (typeof value === 'object') {
                value = value.codigo || value.id || value;
            }
            
            if (type === 'number') {
                const numeric = (params.step < 1 ? parseFloat : parseInt)(value);
                value = numeric > 0 ? numeric : '';
            }
            params.defaultValue = value;
        }

        return (
            <CustomInput {...params} />
        )
    }

    renderItensCustomInput({ item, ...params }) {
        params.defaultValueName = params.name.split('-')[2];
        params.onChange = this.saveStateItens;
        return this.renderCustomInput(params, item);
    }

    renderSelectReadonly({ label, name, value, options, enumValues, renderFunction, ...params }, condition = false) {
        const { add = true } = this.props;
        if (options === undefined) {
            options = enumValues ? enumValues.getSelectOptions() : [];
        }

        renderFunction = renderFunction || this.renderCustomInput;

        if (add || condition) {
            return renderFunction({
                ...params,
                label,
                name,
                type: "select",
                options,
                defaultValue: value,
            });
        }
        if (value) {
            if (enumValues && parseInt(value)) {
                const property = enumValues.properties[parseInt(value)];
                if (property) {
                    value = property.text;
                }
            }
            return renderFunction({
                ...params,
                className: "w-100",
                label,
                name,
                value,
                type: "text",
                readOnly: true,
            });
        }
        return null;
    }

    render() {
        const {
            add = true,
            pedido = {},
            nextNotaFiscal,
            pendente,
            produtos,
            nextPedido,
            show,
            onHide,
        } = this.props;

        const {
            searchCliente,
            addCliente,
            escolherCidades,
            confirmarExcluir,
            confirmarVoltar,
            notaFiscal,
            error,
            errorOnDismiss,
            show: stateShow,
            pedido: { numero, cliente, formaPagamento, banco, transportadora, itens = [] },
        } = this.state;

        let { status, notaFiscal: notaFiscalValue } = pedido;
        if (!(notaFiscalValue && notaFiscalValue != 0)) {
            notaFiscalValue = null;
        }

        const cols = [];
        if (add) {
            cols.push("col-md");
        } else {
            cols.push("col-md-4 pr-md-4");
            cols.push("col-md-8 pl-md-4");
        }

        const readOnly = status == pedidoStatusEnum.EMBARQUE || status == pedidoStatusEnum.EMITIDO;

        return (<>
            <Modal
                show={show && stateShow && !confirmarExcluir && !confirmarVoltar}
                dialogClassName={add ? "modal-md" : "modal-xl"}
                onShow={() => {
                    if (this.clienteRef) {
                        this.clienteRef.focus()
                    }
                }}
                onHide={onHide} >
                <Modal.Header>
                    <Modal.Title>
                        {add
                            ? 'Adicionar pedido'
                            : `Pedido ${pedido.numero}${cliente && cliente.nome ? ` | ${cliente.nome}` : ''}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className={cols[0]}>
                            <div className="row">
                                {add && (
                                    <div className="col-md-6">
                                        {this.renderCustomInput({
                                            label: "Número",
                                            name: "numero",
                                            defaultValue: nextPedido,
                                        })}
                                    </div>
                                )}
                                {!pendente && (<>
                                    <div className="col-md-6">
                                        {(() => {
                                            const params = {
                                                label: "Código do cliente",
                                                name: "cliente",
                                                type: "search",
                                                value: '',
                                                readOnly,
                                                customRef: clienteRef => this.clienteRef = clienteRef,
                                                onChange: (event) => {
                                                    const { target: { value } } = event;
                                                    const { pedido } = this.state;
                                                    if (!pedido.cliente) {
                                                        pedido.cliente = {};
                                                    }
                                                    pedido.cliente = value;
                                                    this.setState({ pedido });
                                                },
                                                searchAction: () => this.setState({ show: false, searchCliente: true })
                                            };
                                            if (cliente) {
                                                params.value = cliente.codigo !== undefined ? cliente.codigo : cliente;
                                            }
                                            return this.renderCustomInput(params)
                                        })()}
                                    </div>
                                    <div className="col-md-6">
                                        {this.renderCustomInput({
                                            label: "Volumes",
                                            name: "volumes",
                                            readOnly,
                                        })}
                                    </div>
                                </>)}
                                <div className={notaFiscalValue ? "col-md-6" : "col-md"}>
                                    {this.renderCustomInput({
                                        label: "Valor total da NF",
                                        name: "valor",
                                        step: 0.01,
                                        readOnly,
                                    })}
                                </div>
                                {pendente && (
                                    <div className="col-md-6">
                                        {this.renderCustomInput({
                                            label: "Volumes",
                                            name: "volumes",
                                            readOnly,
                                        })}
                                    </div>
                                )}
                                {notaFiscalValue && (
                                    <div className="col-md-6">
                                        {this.renderCustomInput({
                                            label: "Nota Fiscal",
                                            name: "notaFiscal",
                                            readOnly: false,
                                        })}
                                    </div>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    {this.renderSelectReadonly({
                                        label: "Forma de pagamento",
                                        name: "formaPagamento",
                                        enumValues: pagamentoFormaEnum,
                                        value: formaPagamento,
                                    }, !readOnly)}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    {this.renderSelectReadonly({
                                        label: "Banco",
                                        name: "banco",
                                        enumValues: bancoEnum,
                                        value: banco,
                                    }, !readOnly)}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    {this.renderSelectReadonly({
                                        label: "Transportadora",
                                        name: "transportadora",
                                        enumValues: transportadoraEnum,
                                        value: transportadora,
                                    }, !readOnly)}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    {this.renderCustomInput({
                                        label: "Tem aviso ao transportador?",
                                        id: "temAvisoTransportador",
                                        type: "checkbox",
                                        readOnly,
                                    })}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    {this.renderCustomInput({
                                        label: "Tem certificado?",
                                        id: "temCertificado",
                                        type: "checkbox",
                                        readOnly,
                                    })}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    {this.renderCustomInput({
                                        label: "Tem guia ICMS?",
                                        id: "temGuiaIcms",
                                        type: "checkbox",
                                        readOnly,
                                    })}
                                </div>
                            </div>
                            {pendente && (
                                <div className="row">
                                    <div className="col-md">
                                        {this.renderCustomInput({
                                            label: "Data para retirar (apenas vendas)",
                                            name: "dtRetira",
                                            type: "datetime-local",
                                            readOnly,
                                        })}
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col-md">
                                    {this.renderCustomInput({
                                        label: "Observações",
                                        name: "observacoes",
                                        type: "textarea",
                                        readOnly,
                                    })}
                                </div>
                            </div>
                        </div>
                        {!add && (
                            <div className={cols[1]}>
                                {false && (
                                    <div className="row mb-1 text-center">
                                        <div className="col-md text-left">Itens</div>
                                        {itens.length > 0 && (<>
                                            <div className="col-md-2">Quant.</div>
                                            <div className="col-md-2">Vol.</div>
                                        </>)}
                                    </div>
                                )}
                                <Container>
                                    <Row>
                                        {itens.map((item, index) => {
                                            const baseId = `item-${item.id}`;

                                            if (true) {
                                                return (
                                                    <Col key={baseId} md={6} className="px-2">
                                                        <PedidoItem {...{
                                                            item, status, produtos, readOnly,
                                                            onChange: this.saveStateItens,
                                                            onRemove: () => {
                                                                const { pedido } = this.state;
                                                                pedido.itens.splice(index, 1);
                                                                this.setState({ pedido });
                                                            }
                                                        }} />
                                                    </Col>
                                                )
                                            }
                                        })}
                                    </Row>
                                    <Row>
                                        <Col className="px-2">
                                            {!readOnly && <IconButton text="Adicionar item" icon={faPlus} onClick={this.addItem} />}
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {status == pedidoStatusEnum.EMBARQUE && (<>
                        <IconButton text="Emitir nota fiscal" icon={faFileInvoiceDollar} onClick={this.emitirNotaFiscal} />
                    </>)}
                    {pendente && (<>
                        <IconButton text="Aprovar para expedição" icon={faCheck} onClick={() => this.aprovar(1)} />
                        <IconButton text="Aprovar para vendas" icon={faCheck} onClick={() => this.aprovar(2)} />
                    </>)}
                    {status != pedidoStatusEnum.EMBARQUE && !pendente && (
                        <IconButton text="Salvar" icon={faSave} onClick={() => (status != pedidoStatusEnum.EMITIDO ? this.save : this.saveNotaFiscal)()} />
                    )}
                    {!add && <IconButton type="danger" text="Excluir" icon={faTrash} onClick={this.delete} />}
                    <IconButton text="Fechar" type="dark" icon={faTimes} onClick={onHide} />
                </Modal.Footer>
            </Modal>
            {PedidoModal.renderModal({
                text: `Deseja realmente excluir o pedido ${pedido.numero || numero}?`,
                show: confirmarExcluir !== false,
                onSuccess: this.delete,
                onHide: () => this.setState({ confirmarExcluir: false }),
            })}

            {PedidoModal.renderModal({
                text: (
                    <CustomInput
                        className="text-center"
                        formGroup={false}
                        label="Informe o número da nota fiscal"
                        type="number"
                        defaultValue={nextNotaFiscal}
                        onChange={event => this.setState({ notaFiscal: event.target.value })} />
                ),
                footer: (<>
                    <IconButton text="Emitir" icon={faFileInvoiceDollar} onClick={this.emitirNotaFiscal} />
                    <IconButton text="Voltar" type="dark" icon={faChevronLeft} onClick={() => this.setState({ notaFiscal: false, show: true })} />
                </>),
                show: notaFiscal !== false,
                onHide: () => this.setState({ notaFiscal: false, show: true }),
            })}

            <ClienteSearchModal
                defaultSearch={cliente}
                show={searchCliente}
                onHide={() => this.setState({ show: true, searchCliente: false })}
                onSuccess={newCliente => {
                    const { pedido } = this.state;
                    pedido.cliente = newCliente;
                    this.setState({ pedido });
                }} />

            <ClienteAddModal
                cliente={cliente}
                show={addCliente && escolherCidades === null}
                onHide={(success = false) => {
                    const state = { addCliente: false };
                    if (!success) {
                        state.show = true;
                    }
                    this.setState(state)
                }}
                onSuccess={newCliente => {
                    const { pedido } = this.state;
                    const { cliente } = pedido;
                    const codigo = typeof cliente === 'object' ? cliente.codigo : cliente;

                    pedido.cliente = { ...newCliente, codigo };
                    this.setState({ pedido });
                    this.save();
                }} />

            <CidadeChooseModal
                cidades={escolherCidades}
                show={escolherCidades !== null}
                onHide={async(success = true) => {
                    const state = { show: true, addCliente: false, escolherCidades: null };
                    if (!success) {
                        state.show = false;
                        state.addCliente = true;
                    }
                    this.setState(state)
                }}
                onSuccess={async(cidade) => {
                    const { pedido } = this.state;
                    pedido.cliente.cidade = cidade.codigo;
                    this.setState({ pedido });
                }} />

            {error && (
                <Alert message={error} onDismiss={() => {
                    if (errorOnDismiss !== null) {
                        errorOnDismiss();
                    }
                    this.setState({ error: null, errorOnDismiss: null })
                }} />
            )}
        </>)
    }
}