import React, { Component } from 'react';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import '../../assets/scss/produto.scss';
import ProdutoItem from '../../components/produto/produto';
import IconButton from '../../components/util/IconButton';
import CustomInput from '../../components/util/CustomInput';
import { Col, Row } from 'react-bootstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ReactPaginate from 'react-paginate';
import api from '../../services/api';
import Loader from '../../components/loader';
import ProdutoModal from '../../components/produto/produto_modal';
import produtoGrupoEnum from '../../util/enum/produtoGrupoEnum';
import produtoUnidadeEnum from '../../util/enum/produtoUnidadeEnum';

export default class Produto extends Component {
    state = {
        produtos: [],
        info: {},
        pagina: 1,
        searching: false,
        adicionar: false,
    }
    timeout = null;

    load = async() => {
        try {
            let { pagina, nome, grupo, unidade } = this.state;
            this.setState({ produtos: [], searching: true })

            nome = nome ? `&nome=${nome}` : ''
            grupo = grupo ? `&grupo=${grupo}` : ''
            unidade = unidade ? `&unidade=${unidade}` : ''
            const result = (await api.get(`/produto?pagina=${pagina}&info=true${nome}${grupo}${unidade}`)).data;
            const { lista: produtos, ...info } = result;

            this.setState({ produtos, info, searching: false })
        } catch (error) {
            console.log(error);
        }
    }

    async componentDidMount() {
        this.load();
    }

    render() {
        const { adicionar, pagina, produtos, info: { count, nextCodigo }, searching } = this.state;

        const paginationProps = {};
        ['page', 'break', 'previous', 'next'].forEach(item => {
            paginationProps[`${item}ClassName`] = "pagination-item";
            paginationProps[`${item}LinkClassName`] = "pagination-item-link";
        })

        return (<>
            <Row className="align-items-end">
                <Col md="auto" className="produto-header">
                    <IconButton className="produto-header-btn" icon={faPlus} text="Adicionar" onClick={() => this.setState({ adicionar: true })} />
                </Col>
                <Col>
                    <CustomInput
                        formGroup={false}
                        label="Filtre os produtos"
                        onChange={(e) => {
                            if (this.timeout !== null) {
                                clearTimeout(this.timeout);
                                this.timeout = null
                            } else {
                                this.setState({ produtos: [], searching: true, pagina: 1 })
                            }

                            this.timeout = setTimeout(async() => {
                                const { value: nome } = e.target;
                                await this.setState({ nome });
                                await this.load();
                                this.timeout = null;
                            }, 1500);
                        }} />
                </Col>
                <Col>
                    <CustomInput
                        formGroup={false}
                        type="select"
                        label="Filtre por grupo"
                        options={produtoGrupoEnum.getSelectOptions()}
                        onChange={async(e) => {
                            const { value: grupo } = e.target;
                            await this.setState({ grupo, pagina: 1 });
                            await this.load();
                        }} />
                </Col>
                <Col>
                    <CustomInput
                        formGroup={false}
                        type="select"
                        label="Filtre por unidade"
                        options={produtoUnidadeEnum.getSelectOptions()}
                        onChange={async(e) => {
                            const { value: unidade } = e.target;
                            await this.setState({ unidade, pagina: 1 });
                            await this.load();
                        }} />
                </Col>
            </Row>
            
            {!searching && (<>
                {produtos.length > 0 && (
                    <TransitionGroup className="produto-produtos">
                        {produtos.map((produto, index) => {
                            return (
                                <CSSTransition
                                    key={index.toString()}
                                    classNames="produto-produtos-transition"
                                    timeout={500}
                                >
                                    <div className={`produto-produtos-item ${!produto.ativo ? 'produto-produtos-inativo' : ''}`}>
                                        <ProdutoItem
                                            produto={produto}
                                            nextCodigo={nextCodigo}
                                            load={this.load} />
                                    </div>
                                </CSSTransition>
                            )
                        })}
                    </TransitionGroup>
                )}
                {produtos.length === 0 && (
                    <Row>
                        <Col className="produto-produtos-empty">Sem registros</Col>
                    </Row>
                )}
            </>)}
            {searching && (
                <div className="mt-4">
                    <Loader />
                </div>
            )}

            {count > 0 && (
                <ReactPaginate
                    pageCount={Math.ceil(count / 10)}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={3}
                    forcePage={pagina - 1}
                    onPageChange={async({ selected: pagina }) => {
                        pagina++;
                        await this.setState({ pagina })
                        await this.load();
                    }}
                    onPageActive={() => this.load()}
                    previousLabel="Anterior"
                    nextLabel="Próxima"
                    containerClassName={`pagination justify-content-center${searching ? ' d-none' : ''}`}
                    activeClassName="active"
                    { ...paginationProps } />
            )}

            {adicionar !== false && (
                <ProdutoModal
                    show={adicionar === true}
                    nextCodigo={nextCodigo}
                    onSuccess={this.load}
                    onHide={() => {
                        this.setState({ adicionar: null });
                        setTimeout(() => this.setState({ adicionar: false }), 500);
                    }} />
            )}
        </>);
    }
}