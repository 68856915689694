import React, { Component } from 'react';
import { default as LoaderSpinner } from 'react-loader-spinner';

export default class Loader extends Component {
    render() {
        const { type } = this.props;

        return (
            <LoaderSpinner type="Oval" className={`text-center loader${type ? `-${type}` : ''}`} />
        );
    }
}