import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

String.prototype.firstToUpperCase = function() {
    return this.substr(0, 1).toUpperCase() + this.substr(1);
};

ReactDOM.render(<App />, document.getElementById("root"));

if (module.hot) {
    module.hot.accept('./App', () => {
        const App = require('./App').default; 
        ReactDOM.render(<App />, document.getElementById("root"));
    });
}