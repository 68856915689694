import { faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import '../../assets/scss/usuario.scss';
import IconButton from '../../components/util/IconButton';
import CustomInput from '../../components/util/CustomInput';
import api from '../../services/api';
import Alert from '../../components/util/Alert';
import global from '../../util/global';
import { Route } from 'react-router-dom';

export default class UsuarioMensagem extends Component {
    state = {
        grupos: null,
        usuarios: null,
        mensagem: {},
        erro: null,
    }

    send = async() => {
        const { mensagem } = this.state;

        try {
            const result = (await api.post('/usuario_mensagem', mensagem)).data;
            console.log(result);
            const { error } = result;
            switch (error) {
                case 0:
                    window.location.href = '/';
                    break;

                case 2:
                    this.setState({ error: "Informe ao menos o assunto ou o texto da mensagem" })
                    break;
                    
                case 3:
                    this.setState({ error: "Informe ao menos um usuário ou grupo de usuário" })
                    break;

                default:
                    this.setState({ error: "Aconteceu um erro" })
            }
        } catch (error) {
            console.log(error);
        }
    }

    saveState = (event) => {
        let { name, value, selectedOptions } = event.target;
        if (selectedOptions !== undefined) {
            value = [];
            Array.from(selectedOptions).forEach(option => value.push(option.value));
        }

        const { mensagem } = this.state;
        mensagem[name] = value;

        this.setState({ mensagem });
    }

    componentDidMount() {
        api.get(`/usuario_grupo?itens=all`).then((result) => {
            const grupos = result.data.map(grupo => ({ value: grupo.id, label: grupo.nome }));
            this.setState({ grupos });
        });
        api.get(`/usuario?itens=all`).then((result) => {
            const usuarios = result.data.map(usuario => ({ value: usuario.id, label: usuario.usuario }));
            this.setState({ usuarios });
        });
    }

    renderCustomInput = ({ defaultValueName, ...params }, baseValues = null) => {
        if (!baseValues) {
            baseValues = this.state;
        }
        let { id, name } = params;
        if (!name && id) {
            name = id;
        }

        if (!params.onChange) {
            params.onChange = this.saveState;
        }

        defaultValueName = defaultValueName || name;
        if (params.value === undefined && defaultValueName && baseValues[defaultValueName]) {
            let value = baseValues[defaultValueName];
            if (typeof value === 'object') {
                value = value.codigo || value.id || value;
            }
            
            params.defaultValue = value;
        }

        return (
            <CustomInput {...params} />
        )
    }

    render() {
        return (
            <Route render={({ history }) => {
                const { grupos, usuarios, error } = this.state;
                const onHide = () => history.push('/');

                return (<>
                    <Modal
                        show={true}
                        dialogClassName="modal-md"
                        onShow={() => this.tituloRef.focus()}
                        onHide={() => onHide()} >
                        <Modal.Header>
                            <Modal.Title>Enviar mensagem</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    {this.renderCustomInput({
                                        label: "Assunto",
                                        name: "titulo",
                                        customRef: tituloRef => this.tituloRef = tituloRef,
                                    })}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.renderCustomInput({
                                        label: "Mensagem",
                                        name: "texto",
                                    })}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.renderCustomInput({
                                        label: "Enviar notificação para estes grupos de usuários",
                                        name: "usuarioGrupo",
                                        type: 'select',
                                        options: grupos,
                                        multiple: true,
                                    })}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.renderCustomInput({
                                        label: "E para estes usuários específicos",
                                        name: "usuario",
                                        type: 'select',
                                        options: usuarios,
                                        multiple: true,
                                    })}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <IconButton text="Enviar" icon={faPaperPlane} onClick={this.send} />
                            <IconButton text="Fechar" type="dark" icon={faTimes} onClick={() => onHide()} />
                        </Modal.Footer>
                    </Modal>

                    {error && (
                        <Alert message={error} onDismiss={() => this.setState({ error: null })} />
                    )}
                </>)
            }} />
        );
    }
}