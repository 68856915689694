import React, { Component } from 'react';
import api from '../../services/api';

import '../../assets/scss/estoque.scss';
import Loader from '../../components/loader';
import produtoGrupoEnum from '../../util/enum/produtoGrupoEnum';
import CustomInput from '../../components/util/CustomInput';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import IconButton from '../../components/util/IconButton';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import colors from '../../assets/colors';

export default class EstoqueDeposito extends Component {
    state = {
        box: null,
        colorFocus: true,
        estoque: [],
        valores: {},
        numero: null,
        produto: null,
        produtos: [],
        searching: false,
        map: null,
    }

    mapas = [{
        text: "Área da expedição e produção (inferior)",
        value: 1,
    }, {
        text: "Área da expedição e produção (superior)",
        value: 2,
    }, {
        text: "Depósito (inferior)",
        value: 3,
    }, {
        text: "Depósito (superior)",
        value: 4,
    }, {
        text: "Mezanino (inferior)",
        value: 5,
    }, {
        text: "Mezanino (superior)",
        value: 6,
    }]

    refresh = async() => {
        const { box } = this.state;
        await this.search();
        if (box) {
            this.setState({ box });
        }
    }

    goBack = () => {
        this.setState({ map: null, box: null });
        this.props.onChangeSubtitle('');
        this.props.onChangeGoBack(true);
    }

    search = async() => {
        try {
            let { produto, numero } = this.state;
    
            await this.setState({ estoque: [], searching: true });
    
            produto = produto ? `&produto=${produto}` : '';
            numero = numero ? `&lote=${numero}` : '';
            const resultado = (await api.get(`/estoque_box?itens=all${produto}${numero}`)).data;
            const { lista: estoque, ...valores } = resultado;

            await this.setState({ estoque, valores, searching: false });
    
            if (produto || numero) {
                if (!this.existeExpedicao()) {
                    if (!this.existeDeposito()) {
                        this.existeMezanino()
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    setBox = (box) => {
        const { box: selectedBox } = this.state;
        if (box == selectedBox) {
            box = null;
        }
        this.setState({ box });
    }

    changeMap = mapIndex => {
        const map = this.mapas[mapIndex];
        this.setState({ map: map.value, box: null });
        this.props.onChangeSubtitle(map.text);
        this.props.onChangeGoBack(false);
    }

    createArray = (length, start = 1, odd = true, reverse = true) => {
        const array = Array.from({ length }, (_, i) => ((i + (odd ? 0 : 1)) * 2) - (odd ? 0 : 1) + start);
        return reverse ? array.reverse() : array;
    };

    getExpedicaoBoxes = (inferior) => {
        const array = [
            this.createArray(8, 171, inferior, false),
            this.createArray(8, 187, inferior, false),
        ];
        if (inferior) {
            array.push([231]);
        }
        return [[
            this.createArray(3, 153, inferior),
            inferior ? [127] : [],
            this.createArray(3, 159, inferior),
            this.createArray(3, 165, inferior),
        ], [], array];
    }

    getDepositoBoxes = (inferior) => {
        const defaultLength = 8;
        let corredores = 0;
        const getCorredor = () => {
            if (!inferior) {
                return [];
            }
            return [225 + corredores++];
        }
        return [
            this.createArray(6, 1, inferior),
            getCorredor(),
            this.createArray(defaultLength, 13, inferior),
            this.createArray(defaultLength, 29, inferior),
            getCorredor(),
            this.createArray(defaultLength, 45, inferior),
            this.createArray(defaultLength, 61, inferior),
            getCorredor(),
            this.createArray(defaultLength, 77, inferior),
            this.createArray(defaultLength, 93, inferior),
            getCorredor(),
            this.createArray(defaultLength, 109, inferior),
        ];
    }

    getMezaninoBoxes = (inferior) => {
        return [
            [this.createArray(6, 209, inferior, false)],
            inferior ? [[230]] : [[]],
            [
                this.createArray(3, 203, inferior),
                [],
                this.createArray(2, 221, inferior)
            ],
        ];
    }

    getBoxQuantidade = (box, returnObject = false) => {
        const { selectedQuantidade, estoque } = this.state;
        
        const boxObject = typeof box !== 'object' ? estoque[box - 1] || {} : box;
        let quantidade = (boxObject.estoque || []).reduce((total, next) => total + parseInt(next.quantidade || 0), 0);
        const existe = selectedQuantidade ? selectedQuantidade.find(item => (item.box || {}).id == boxObject.id) : undefined;
        if (existe !== undefined && quantidade == existe.quantidade) {
            quantidade = 0;
        }
        
        if (returnObject) {
            return { quantidade, boxObject };
        }
        return quantidade;
    }

    boxExiste = (boxes) => {
        if (Array.isArray(boxes)) {
            if (boxes.length > 0) {
                return boxes.some(item => this.boxExiste(item))
            }
            return false;
        }
        
        return this.getBoxQuantidade(boxes) > 0;
    }

    existeExpedicao = (inferior = null) => {
        let boxes = [231];
        if (!force && !this.boxExiste(boxes)) {
            return false;
        }
        
        this.changeMap(inferior ? 0 : 1);
        return true;
    }

    existeDeposito = (inferior = null) => {
        let boxes = [];
        const getArray = () => {
            return this.getDepositoBoxes(inferior);
        };

        if (inferior === null) {
            const boxExiste = () => {
                const boxesInferior = inferior ? [229] : [];
                return this.boxExiste([ ...boxes, ...boxesInferior ]);
            }
            
            inferior = true;
            boxes = getArray();
            if (!boxExiste()) {
                inferior = false;
                boxes = getArray();
                if (!boxExiste()) {
                    return false;
                }
            }
        } else {
            boxes = getArray();
        }
        
        this.changeMap(inferior ? 2 : 3);
        return true;
    }

    existeMezanino = (inferior = null) => {
        let boxes = [];
        const getArray = () => {
            return this.getMezaninoBoxes(inferior);
        };

        if (inferior === null) {
            const boxExiste = () => this.boxExiste(boxes);
            
            inferior = true;
            boxes = getArray();
            if (!boxExiste()) {
                inferior = false;
                boxes = getArray();
                if (!boxExiste()) {
                    return false;
                }
            }
        } else {
            boxes = getArray();
        }
        
        this.changeMap(inferior ? 4 : 5);
        return true;
    }

    async componentDidMount() {
        this.search();

        this.props.onChangeSubtitle('');
        this.props.onChangeGoBack(true);
        setInterval(() => this.setState({ colorFocus: !this.state.colorFocus }), 1000);

        api.get(`/produto?itens=all&grupo=${produtoGrupoEnum.PRODUTO_FINAL}`).then((result) => {
            const produtos = result.data.map(produto => ({ value: produto.id, label: produto.nome, codigo: produto.codigo }));
            this.setState({ produtos });
        });
    }

    renderBoxDetalheLista = () => {
        const { estoque, box, produto } = this.state;
        const lista = estoque[box - 1].estoque;

        if (lista.length > 0) {
            return estoque[box - 1].estoque.map((item, index) => {
                return (
                    <div className="estoque-deposito-detalhe-container">
                        <Row>
                            <Col className="estoque-deposito-detalhe-codigo">Produto {item.produto.codigo}</Col>
                        </Row>
                        <Row>
                            <Col className="estoque-deposito-detalhe-produto">{item.produto.nome}</Col>
                        </Row>
                        <Row>
                            <Col className="estoque-deposito-detalhe-lote">Lote {item.lote}</Col>
                        </Row>
                        <Row>
                            <Col className="estoque-deposito-detalhe-quantidade">{item.quantidade}</Col>
                        </Row>
                    </div>
                );
            })
        }
        return <div className="estoque-deposito-detalhe-empty mt-3">{produto ? 'Não contém esse produto' : 'Vazio'}</div>;
    }

    renderBoxDetalhe = () => {
        const { map, box } = this.state;

        if (box) {
            return (
                <div className="estoque-deposito-detalhe">
                    <div className="estoque-deposito-detalhe-title">Box {box}</div>
                    {this.renderBoxDetalheLista()}
                </div>
            );
        }
        if (!map) {
            return <div className="estoque-deposito-detalhe-empty">Escolha um mapa para visualizar</div>;
        }
        return <div className="estoque-deposito-detalhe-empty">Clique em um box para ver seus&nbsp;detalhes</div>;
    }

    renderBox = ({ box, boxStyle = {}, className, classNameRow, classNameText, colored = true, text = box, index = 0, length = 1, defaultLength = 8, margin, warningLimit = 0, dangerLimit = 8 }) => {
        const { produto, numero, colorFocus, estoque, box: selectedBox } = this.state;
        
        const lg = length < defaultLength ? '-lg' : '';
        margin = margin || (index < length - 1 && index % 2 !== 0 ? (lg ? ` mb-4` : ` mb-auto`) : '');

        const quantidade = estoque[box - 1].estoque.reduce((total, next) => total + parseInt(next.quantidade || 0), 0);
        let color = '';
        if (produto || numero) {
            color = colorFocus && quantidade > 0 ? 'focuson' : 'focusoff';
        } else {
            color = quantidade > warningLimit ? (quantidade > dangerLimit ? 'danger' : 'warning') : (colored ? 'success' : 'focusoff')
        }
        color = ` estoque-deposito-box-${color}`;
        const active = selectedBox == box ? ' active' : '';
        
        return (
            <Row className={`estoque-deposito-box-col${margin}${classNameRow ? ` ${classNameRow}` : ''}`} key={index.toString()}>
                <Col className="p-0">
                    <button style={boxStyle} className={`estoque-deposito-box${lg}${color}${active}${className ? ` ${className}` : ''}`} onClick={() => this.setBox(box)}>
                        <span className={classNameText || ''}>{text}</span>
                    </button>
                </Col>
            </Row>
        )
    }

    renderMapa = () => {
        const { map } = this.state;

        let inferior = false;
        let boxes = [];
        switch (map) {
            case 1:
                inferior = true;
            case 2:
                boxes = this.getExpedicaoBoxes(inferior);

                return (<>
                    <Col>
                        {boxes.map((item, index) => {
                            if (item.length > 0) {
                                if (index === 0) {
                                    return (
                                        <Row key={index.toString()}>
                                            <Col md={2}></Col>
                                            <Col md={7}>
                                                {item.map((item, index) => {
                                                    if (item.length > 1) {
                                                        return (
                                                            <Row key={index.toString()} style={{
                                                                backgroundColor: colors.cinza,
                                                                padding: 10,
                                                            }}>
                                                                {item.map((box, index) => {
                                                                    return (
                                                                        <Col key={index.toString()} className={`p-0 m-0${(index % 2 !== 0) ? ` mr-3` : ''}`}>
                                                                            {this.renderBox({
                                                                                box,
                                                                                boxStyle: {
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                    height: 50,
                                                                                },
                                                                            })}
                                                                        </Col>
                                                                    )
                                                                })}
                                                            </Row>
                                                        )
                                                    } else if (item.length === 1) {
                                                        return (
                                                            <Row key={index.toString()}>
                                                                <Col className="p-0">
                                                                    {this.renderBox({
                                                                        box: item[0],
                                                                        text: `Corredor`,
                                                                        boxStyle: {
                                                                            flex: 1,
                                                                            height: 65,
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                        },
                                                                        colored: false,
                                                                    })}
                                                                </Col>
                                                            </Row>
                                                        )
                                                    } else {
                                                        return (
                                                            <div key={index.toString()} style={{
                                                                flexDirection: "row",
                                                                flex: 2,
                                                            }}>
                                                                <div style={{
                                                                    flex: 1,
                                                                    height: 65,
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}></div>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </Col>
                                        </Row>
                                    )
                                } else {
                                    return (
                                        <Row key={index.toString()}>
                                            <Col md={2}></Col>
                                            {item.map((item, index) => {
                                                if (item.length > 1) {
                                                    return (
                                                        <Col md={1} key={index.toString()} style={{
                                                            backgroundColor: colors.cinza,
                                                            padding: 10,
                                                        }}>
                                                            {item.map((box, index) => {
                                                                const defaultCustomStyle = {};
                                                                const margin = index < 7 && index % 2 !== 0 ? 10 : 0;
                                                                
                                                                return (
                                                                    <div key={index.toString()} style={{ marginBottom: margin }}>
                                                                        {this.renderBox({
                                                                            box,
                                                                            boxStyle: { ...{
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                height: 50,
                                                                            }, ...defaultCustomStyle },
                                                                        })}
                                                                    </div>
                                                                )
                                                            })}
                                                        </Col>
                                                    )
                                                } else {
                                                    return (
                                                        <Col md={5} key={index.toString()} style={{ padding: 10, paddingTop: 0, paddingRight: 0 }}>
                                                            {this.renderBox({
                                                                box: item[0],
                                                                text: `Área da expedição e produção`,
                                                                boxStyle: {
                                                                    flex: 1,
                                                                    height: 220,
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                },
                                                                successLimit: 50,
                                                                warningLimit: 100,
                                                            })}
                                                        </Col>
                                                    )
                                                }
                                            })}
                                        </Row>
                                    )
                                }
                            } else {
                                return (
                                    <div key={index.toString()} style={{
                                        flexDirection: "row",
                                        flex: 2,
                                    }}>
                                        <div style={{
                                            flex: 1,
                                            height: 65,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: 100,
                                        }}></div>
                                    </div>
                                )
                            }
                        })}
                    </Col>
                </>);

            case 3:
                inferior = true;
            case 4:
                boxes = this.getDepositoBoxes(inferior);

                let corredor = 0;
                return (<>
                    {boxes.map((fileira, index) => {
                        const length = fileira.length;
            
                        if (length > 0) {
                            if (length > 1) {
                                return (
                                    <Col key={index.toString()} className="estoque-deposito-fileira" md={1}>
                                        {fileira.map((box, index) => this.renderBox({ box, index, length }))}
                                    </Col>
                                )
                            }
                            return (
                                <Col key={index.toString()}>
                                    <Row className="h-100">
                                        <Col className="align-items-stretch p-0">
                                            {this.renderBox({
                                                box: fileira[0],
                                                text: `Corredor ${++corredor}`,
                                                className: 'h-100',
                                                classNameRow: 'h-100',
                                                classNameText: 'estoque-deposito-corredor-text',
                                                index,
                                                length,
                                                colored: false,
                                            })}
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        }
                        return <Col key={index.toString()} className="estoque-deposito-corredor" md={1}></Col>;
                    })}
                    {inferior && (
                        <Col className="align-items-stretch p-0">
                            {this.renderBox({
                                box: 229,
                                text: 'Corredor principal',
                                className: 'estoque-deposito-corredor-principal',
                                index: 0,
                                length: 1,
                                colored: false,
                            })}
                        </Col>
                    )}
                </>)

            case 5:
                inferior = true;
            case 6:
                boxes = this.getMezaninoBoxes(inferior);

                return (
                    <Col>
                        {boxes.map((linha, index) => {
                            const linhaLength = linha.length;
                            console.log(linhaLength);

                            return (
                                <Row key={index.toString()}>
                                    {linha.map((fileira, index) => {
                                        const length = fileira.length;
                    
                                        if (length > 1) {
                                            let md = 12;
                                            if (linhaLength === 3) {
                                                switch (index) {
                                                    case 0:
                                                        md = 7
                                                        break;

                                                    case 1:
                                                        md = 2
                                                        break;

                                                    case 2:
                                                        md = 3
                                                        break;
                                                }
                                            }
                                            return (
                                                <Col md={md} key={index.toString()} className="estoque-deposito-fileira">
                                                    <Row noGutters={true}>
                                                        {fileira.map((box, index) => {
                                                            const margin = (length === 3 ? index === 0 : (index < length - 1 && index % 2 !== 0)) ? ' mr-3' : ' ';

                                                            return (
                                                                <Col md={12 / length} className="p-0">
                                                                    {this.renderBox({ box, index, length, margin })}
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </Col>
                                            )
                                        } else if (length === 1) {
                                            return (
                                                <Col key={index.toString()}>
                                                    <Row>
                                                        <Col className="p-0">
                                                            {this.renderBox({
                                                                box: fileira[0],
                                                                text: 'Corredor',
                                                                className: 'estoque-deposito-mezanino-corredor',
                                                                index,
                                                                length,
                                                                colored: false,
                                                            })}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            );
                                        }
                
                                        return <Col key={index.toString()} className="estoque-deposito-mezanino-corredor"></Col>;
                                    })}
                                </Row>
                            )
                        })}
                    </Col>
                )
        }
    }

    renderMapBtn = (mapIndex) => {
        return <IconButton className="estoque-map-btn" text={this.mapas[mapIndex].text} onClick={() => this.changeMap(mapIndex)} />
    }

    render() {
        const { map, searching, produto, produtos, estoque, valores } = this.state;

        if (!searching && estoque.length === 0) {
            return null;
        }

        const { estoque: quantidade, reserva } = valores;

        return (<>
            <div className="estoque-filtro">
                <Row className="align-items-end mb-3">
                    <Col md={3}>
                        <CustomInput
                            formGroup={false}
                            label="Filtre por produto"
                            type="select"
                            options={produtos}
                            defaultValue={produto}
                            isClearable={true}
                            onChange={async event => {
                                const { target: { value: produto } } = event;
                                await this.setState({ produto });
                                this.search();
                            }}
                            />
                    </Col>
                    <Col md={3}>
                        <CustomInput
                            formGroup={false}
                            label="Filtre por lote"
                            type="number"
                            onChange={async event => {
                                const { target: { value: numero } } = event;
                                if (this.numeroTimeout) {
                                    clearTimeout(this.numeroTimeout);
                                } else {
                                    await this.setState({ estoque: [], searching: true })
                                }
                                this.numeroTimeout = setTimeout(() => {
                                    this.search()
                                    this.numeroTimeout = null;
                                }, 1500);
                                
                                await this.setState({ numero })
                            }}
                            />
                    </Col>
                    {map && (<>
                        <Col md={3}>
                            {map > 1 && <IconButton className="w-100" text="Mapa anterior" icon={faChevronLeft} onClick={() => this.changeMap(map - 2)} />}
                        </Col>
                        <Col md={3}>
                            {map < 6 && <IconButton className="w-100" text="Próximo mapa" icon={faChevronRight} onClick={() => this.changeMap(map)} />}
                        </Col>
                    </>)}
                </Row>
            </div>
            {!searching && (<>
                {Boolean(quantidade) && (
                    <Row className="mb-3">
                        <Col>
                            <Row>
                                <Col className="estoque-title">{quantidade} em estoque</Col>
                            </Row>
                            {Boolean(reserva) && (
                                <Row>
                                    <Col className="estoque-title text-danger">{`${reserva} reservado${reserva > 1 ? 's' : ''}`}</Col>
                                </Row>
                            )}
                            {Boolean(reserva) && (
                                <Row>
                                    <Col className="estoque-title text-success">{`${quantidade - reserva} disponíve${quantidade - reserva > 1 ? 'is' : 'l'}`}</Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col md={9}>
                        {map && (
                            <Row className="estoque-deposito">
                                {this.renderMapa()}
                            </Row>
                        )}
                        {!map && (<>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            {this.renderMapBtn(1)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {this.renderMapBtn(0)}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            {this.renderMapBtn(3)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {this.renderMapBtn(2)}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            {this.renderMapBtn(5)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {this.renderMapBtn(4)}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>)}
                    </Col>
                    <Col md={3}>
                        <div className="estoque-deposito-lateral">
                            <Row>
                                <Col className="estoque-deposito-legenda">
                                    {[{
                                        color: 'success',
                                        text: 'Box vazio'
                                    }, {
                                        color: 'warning',
                                        text: 'Box com alguns itens'
                                    }, {
                                        color: 'danger',
                                        text: 'Box cheio'
                                    }].map((item, index) => (
                                        <Row key={index.toString()} className="align-items-center" noGutters={true}>
                                            <Col md={1} className={`estoque-deposito-legenda-${item.color}`}></Col>
                                            <Col className="pl-2">{item.text}</Col>
                                        </Row>
                                    ))}
                                </Col>
                            </Row>
                            <hr />

                            {this.renderBoxDetalhe()}
                        </div>
                    </Col>
                </Row>
            </>)}
            {searching && (
                <Row className="justify-content-center mt-2">
                    <Col md="auto">
                        <Loader />
                    </Col>
                </Row>
            )}
        </>);
    }
}