import React, { Component } from 'react';
import api from '../../services/api';

import '../../assets/scss/expedicao.scss';
import Loader from '../../components/loader';
import Pedido from '../../components/pedido/pedido';
import pedidoStatusEnum from '../../util/enum/pedidoStatusEnum';
import CustomInput from '../../components/util/CustomInput';
import produtoGrupoEnum from '../../util/enum/produtoGrupoEnum';

export default class ExpedicaoArquivo extends Component {
    state = {
        pedidos: [],
        page: 1,
        searching: false,
        produtos: null,
        numeroNF: null,
        cliente: null,
        produto: null,
    }

    filterPedido = async state => {
        await this.setState({
            ...state,
            page: 1,
            pedidos: [],
            searching: true,
        })
        await this.loadPedidos();
    }

    loadPedidos = async() => {
        try {
            let { page, numeroNF, cliente, produto, pedidos } = this.state;
            this.setState({ searching: true })
            
            numeroNF = numeroNF ? `&numeroNF=${numeroNF}` : '';
            cliente = cliente ? `&cliente=${cliente}` : '';
            produto = produto ? `&produto=${produto}` : '';
            const result = (await api.get(`/pedido?status=${pedidoStatusEnum.EMITIDO}&itens=12&pagina=${page}&order_by=2${numeroNF}${cliente}${produto}`)).data;
            if (result && result.length > 0) {
                pedidos = [...pedidos, ...result];
                
                document.addEventListener('scroll', this.trackScrolling);
            }
            this.setState({ pedidos, searching: false })
        } catch (error) {
            console.log(error);
        }
    }
    
    trackScrolling = () => {
        const element = document.getElementsByClassName('expedicao-pedidos')[0];
        if (element.getBoundingClientRect().bottom <= window.innerHeight) {
            this.setState({ page: this.state.page + 1 });
            this.loadPedidos();
            document.removeEventListener('scroll', this.trackScrolling);
        }
    }

    componentDidMount() {
        this.loadPedidos();
        api.get(`/produto?itens=all&grupo=${produtoGrupoEnum.PRODUTO_FINAL}`).then((result) => {
            const produtos = result.data.map(produto => ({ value: produto.codigo, label: produto.nome }));
            this.setState({ produtos });
        });
        document.addEventListener('scroll', this.trackScrolling);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }

    renderPedidos = () => {
        const { pedidos, searching } = this.state;
        if (pedidos.length > 0) {
            return pedidos.map(pedido => {
                const { numero } = pedido;
                return (
                    <div key={`pedido-${numero}`} className="expedicao-pedido">
                        <div className="expedicao-pedido-container">
                            <Pedido pedido={pedido} loadPedidos={() => {
                                this.setState({ pagina: 1, pedidos: [] })
                                this.loadPedidos();
                            }} />
                        </div>
                    </div>
                )
            });
        }
        if (!searching) {
            return (
                <div className="expedicao-baixar-title">Nenhum pedido emitido</div>
            );
        }
        return null;
    }

    render() {
        const { searching, produtos } = this.state;

        return (<>
            <div className="expedicao-filtro">
                <div className="row">
                    <div className="col-md-3">
                        <CustomInput
                            label="Filtre por número ou NF"
                            type="number"
                            onChange={async event => {
                                const { target: { value: numeroNF } } = event;
                                if (this.numeroNFTimeout) {
                                    clearTimeout(this.numeroNFTimeout);
                                }
                                this.setState({ pedidos: [], searching: true })
                                this.numeroNFTimeout = setTimeout(() => {
                                    this.filterPedido({ numeroNF })
                                }, 1500);
                            }}
                            />
                    </div>
                    <div className="col-md-3">
                        <CustomInput
                            label="Filtre por cliente"
                            onChange={async event => {
                                const { target: { value: cliente } } = event;
                                if (this.clienteTimeout) {
                                    clearTimeout(this.clienteTimeout);
                                }
                                this.setState({ pedidos: [], searching: true })
                                this.clienteTimeout = setTimeout(() => {
                                    this.filterPedido({ cliente })
                                }, 1500);
                            }}
                            />
                    </div>
                    <div className="col-md-3">
                        <CustomInput
                            label="Filtre por produto"
                            type="select"
                            options={produtos}
                            isClearable={true}
                            onChange={async event => {
                                const { target: { value: produto } } = event;
                                this.filterPedido({ produto });
                            }}
                            />
                    </div>
                </div>
            </div>
            <div className="expedicao-pedidos">
                {this.renderPedidos()}
            </div>
            {searching && (
                <div className="expedicao-loader">
                    <Loader />
                </div>
            )}
        </>);
    }
}