import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'development'
    ? 'http://localhost/intranet.centroplast.ind.br/api/v1'
    : 'https://intranet.centroplast.ind.br/api/v1';

const api = axios.create({
    baseURL,
    headers: {
        'Token': 't0KZKFAyLko7iSPQCZXGJU2hVku7NLkz',
    },
});

export default api;