const values = {
    APROVADO: 1,
    ENVIADO: 3,
    EMBARQUE: 6,
    EMITIDO: 4,
    INCOMPLETO: 5,
};

const properties = {
    1: {name: 'APROVADO', value: 1, text: 'Aprovado'},
    3: {name: 'ENVIADO', value: 3, text: 'Enviado à expedição'},
    6: {name: 'EMBARQUE', value: 6, text: 'Pronto para embarcar'},
    4: {name: 'EMITIDO', value: 4, text: 'Nota fiscal emitida'},
    5: {name: 'INCOMPLETO', value: 5, text: 'Incompleto'},
};

export default {
    ...values,
    properties,
};